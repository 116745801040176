import React, { useState } from 'react';
import { Building2, Camera, Globe2, MessageSquare, Users2, Home, Share2, PenTool, Target, DollarSign, BarChart, Award, CheckCircle, Star, Code, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  {
    icon: Target,
    title: "Strategy",
    description: "Developing comprehensive marketing strategies tailored to your specific business goals and target audience."
  },
  {
    icon: Building2,
    title: "Custom Ad Funnels",
    description: "Creating optimized conversion funnels designed to turn prospects into paying customers efficiently."
  },
  {
    icon: DollarSign,
    title: "Ad Account Management",
    description: "Expert management of your advertising accounts across multiple platforms for maximum ROI."
  },
  {
    icon: PenTool,
    title: "Content Creation",
    description: "Professional photography, videography, and compelling content that tells your brand's story."
  },
  {
    icon: Share2,
    title: "Social Media Management",
    description: "Strategic social media management to build brand awareness and engage with your target audience."
  },
  {
    icon: Code,
    title: "Web Development",
    description: "Offering full web development solutions for effective online presence and digital growth."
  }
];

const expertise = [
  {
    icon: DollarSign,
    title: "Big Agency Impact",
    description: "You get high-level expertise, premium content, and performance-driven marketing—without the inflated costs of traditional agencies—while benefiting from personalized, one-on-one support that ensures we work closely with you to refine your strategy and maximize results."
  },
  {
    icon: Building2,
    title: "All-in-One Marketing Partner",
    description: "We streamline everything under one roof—social media, paid ads, content creation, SEO, and strategic growth consulting. No need to juggle multiple agencies or freelancers."
  },
  {
    icon: Target,
    title: "Proven Track Record of Success",
    description: "We don't just talk results—we deliver them. Our clients have seen over 100%+ increases in leads, bookings and conversion rates."
  }
];

const results = [
  {
    icon: DollarSign,
    title: "Increased Revenue",
    description: "Our clients see an average 35% increase in bookings and sales within the first 3 months."
  },
  {
    icon: BarChart,
    title: "Measurable Growth",
    description: "We focus on metrics that matter - leads, conversions, and ROI, not just vanity metrics."
  },
  {
    icon: Award,
    title: "Premium Positioning",
    description: "We help position your business as the premium choice in your market segment."
  }
];

export default function About() {
  const [isWhatsAppPopupOpen, setIsWhatsAppPopupOpen] = useState(false);

  const handleContactClick = () => {
    setIsWhatsAppPopupOpen(true);
  };

  const handleWhatsAppChat = () => {
    const message = encodeURIComponent("Hello, I'm interested in your marketing and lead generation services.");
    window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
    setIsWhatsAppPopupOpen(false);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative bg-blue-600 py-24">
        <div className="absolute inset-0 overflow-hidden">
          <img
            src="https://i.postimg.cc/3wSbjLCQ/canang.webp"
            alt="Bali Villa"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold text-white mb-6 sm:text-5xl">Bali Expert Marketing</h1>
            <p className="text-xl text-white max-w-3xl mx-auto">
              Your trusted partner for high-impact marketing and lead generation in Bali's competitive market
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Problem-Solution Section */}
        <div className="bg-gray-50 rounded-2xl p-8 mb-16">
          <div className="text-center mb-12">
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Struggling to get <span className="font-semibold">consistent, high-quality leads</span>? Tired of overpriced agencies and unreliable freelancers who don't deliver results? We provide <span className="font-semibold">affordable, all-in-one marketing solutions</span> designed to bring you <span className="font-semibold">real clients, more sales, and long-term growth.</span> <a onClick={handleContactClick} className="text-blue-600 hover:text-blue-800 cursor-pointer">Contact us</a>
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="space-y-4">
                <div className="flex items-start">
                  <span className="text-red-500 font-bold mr-3">❌</span>
                  <div>
                    <p className="font-semibold text-gray-900">Not enough high-value leads</p>
                    <p className="text-gray-600">Wasting time on unqualified inquiries that never convert.</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <span className="text-red-500 font-bold mr-3">❌</span>
                  <div>
                    <p className="font-semibold text-gray-900">Overpriced marketing agencies</p>
                    <p className="text-gray-600">Paying premium prices for strategies that don't work.</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <span className="text-red-500 font-bold mr-3">❌</span>
                  <div>
                    <p className="font-semibold text-gray-900">Unreliable freelancers</p>
                    <p className="text-gray-600">Inconsistent branding, lack of strategy, and poor execution.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="space-y-4">
                <div className="flex items-start">
                  <span className="text-green-500 font-bold mr-3">✅</span>
                  <div>
                    <p className="font-semibold text-gray-900">Targeted Lead Generation</p>
                    <p className="text-gray-600">We bring in the right audience that's actively searching for what you offer.</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <span className="text-green-500 font-bold mr-3">✅</span>
                  <div>
                    <p className="font-semibold text-gray-900">All-in-One Marketing Partner</p>
                    <p className="text-gray-600">Social media, paid ads, content creation, and more—so you don't have to juggle multiple providers.</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <span className="text-green-500 font-bold mr-3">✅</span>
                  <div>
                    <p className="font-semibold text-gray-900">Affordable & Effective</p>
                    <p className="text-gray-600">Big-agency results without the big-agency price tag.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div className="mb-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {services.map((service, index) => {
              const ServiceComponent = (
                <div key={index} className={`bg-white p-8 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow ${service.link ? 'cursor-pointer' : ''}`}>
                  <div className="flex items-center mb-6">
                    <div className="bg-blue-100 p-3 rounded-lg mr-4">
                      <service.icon className="h-6 w-6 text-blue-600" />
                    </div>
                    <h3 className="text-xl font-bold text-gray-900">{service.title}</h3>
                  </div>
                  <p className="text-gray-600">{service.description}</p>
                </div>
              );

              // Only wrap with Link if service.link exists and service.title is not "Web Development"
              if (service.link && service.title !== "Web Development") {
                return (
                  <Link key={index} to={service.link} className="block">
                    {ServiceComponent}
                  </Link>
                );
              }
              
              return ServiceComponent;
            })}
          </div>
        </div>

        {/* Contact Us Button */}
        <div className="text-center mb-8">
          <button 
            onClick={handleContactClick}
            className="bg-gray-100 text-gray-800 px-8 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors text-lg"
          >
            Contact Us
          </button>
        </div>

        {/* Why Choose Us Section */}
        <div className="mb-16">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Why Choose Bali Expert Marketing?</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              With over a decade of experience, we've helped hundreds of businesses achieve their marketing goals
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {expertise.map((item, index) => (
              <div key={index} className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
                <div className="flex items-center mb-6">
                  <div className="bg-blue-100 p-3 rounded-lg mr-4">
                    <item.icon className="h-6 w-6 text-blue-600" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900">{item.title}</h3>
                </div>
                <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: item.description }}></p>
              </div>
            ))}
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="bg-gray-50 rounded-2xl p-8 mb-16">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">What Our Clients Say</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Don't just take our word for it - hear from businesses we've helped succeed
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="flex items-center mb-4">
                <img 
                  src="https://i.postimg.cc/B6QyBC6R/putu2.jpg" 
                  alt="Intan" 
                  className="h-12 w-12 rounded-full object-cover mr-4"
                />
                <div>
                  <h4 className="font-bold text-gray-900">Intan</h4>
                  <p className="text-gray-500 text-sm">Villa Sales</p>
                </div>
              </div>
              <div className="flex mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                ))}
              </div>
              <p className="text-gray-600 italic">"Our agency was overwhelmed with low-quality leads that never converted and now we're attracting serious buyers, closing more deals, and seeing real growth."</p>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="flex items-center mb-4">
                <img 
                  src="https://i.postimg.cc/8PrY4cpk/wayan.jpg" 
                  alt="Kadek" 
                  className="h-12 w-12 rounded-full object-cover mr-4"
                />
                <div>
                  <h4 className="font-bold text-gray-900">Kadek</h4>
                  <p className="text-gray-500 text-sm">Renewable Energy</p>
                </div>
              </div>
              <div className="flex mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                ))}
              </div>
              <p className="text-gray-600 italic">"We went from struggling for leads to a full sales pipeline. Their digital strategy and high-converting ads transformed our growth."</p>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="flex items-center mb-4">
                <img 
                  src="https://i.postimg.cc/XJz2cSty/wayan-hospitality.jpg" 
                  alt="Putu" 
                  className="h-12 w-12 rounded-full object-cover mr-4"
                />
                <div>
                  <h4 className="font-bold text-gray-900">Putu</h4>
                  <p className="text-gray-500 text-sm">Hospitality</p>
                </div>
              </div>
              <div className="flex mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                ))}
              </div>
              <p className="text-gray-600 italic">"Our direct bookings nearly double with the help of Bali Expert Marketing. We are seeing better results every month."</p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Get More Leads & Clients Today</h2>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Your business deserves <span className="font-semibold">more leads, more sales, and more success.</span> Let's make it happen.
          </p>
          <button 
            onClick={handleContactClick}
            className="bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors text-lg"
          >
            Contact Us Now
          </button>
        </div>
      </div>

      {/* WhatsApp Popup */}
      {isWhatsAppPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsWhatsAppPopupOpen(false)}></div>
          <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-900">Contact Us</h2>
                <button 
                  onClick={() => setIsWhatsAppPopupOpen(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <p className="text-gray-600 mb-6">
                Get in touch with our marketing team to discuss how we can help grow your business with targeted lead generation and marketing strategies.
              </p>
              <button 
                onClick={handleWhatsAppChat}
                className="w-full bg-green-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center mb-4"
              >
                <MessageSquare className="mr-2 h-5 w-5" />
                Chat on WhatsApp
              </button>
              <button 
                onClick={() => setIsWhatsAppPopupOpen(false)}
                className="w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}