import React, { useState, useEffect } from 'react';
import { Calendar, MapPin, ArrowRight, Users2, Home, Share2, PenTool, Target, Clock, Coffee, Building2, Star, Palmtree } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const heroSlides = [
  {
    title: "Villas in Bali",
    subtitle: "Exclusive properties for rent in prime locations",
    description: "Explore our selection of beautiful villas for rent and book directly with the owner for the best rates possible.",
    cta: {
      primary: {
        text: "Browse Rentals",
        href: "/villas-for-rent",
      }
    },
    image: "https://i.postimg.cc/mZ05qsyk/Screenshot-2025-03-03-at-10-01-45-AM.png",
    icon: Building2
  },
  {
    title: "Restaurants & Cafés",
    subtitle: "Curated restaurants and accommodations",
    description: "Discover the finest dining experiences and luxury accommodations across the island.",
    cta: {
      primary: {
        text: "Explore Places",
        href: "/dining",
      }
    },
    image: "https://i.imghippo.com/files/qVZ2268zk.jpg",
    icon: Coffee
  },
  {
    title: "Upcoming Events",
    subtitle: "Don't miss out on Bali's best experiences",
    description: "From traditional ceremonies to modern festivals, discover what's happening on the island.",
    cta: {
      primary: {
        text: "View Events",
        href: "/events",
      }
    },
    image: "https://i.imghippo.com/files/HciL3878WB.jpg",
    icon: Calendar
  },
  {
    title: "Guided Tours",
    subtitle: "Expert-led adventures across Bali",
    description: "Join our experienced guides for unforgettable journeys through Bali's most stunning locations.",
    cta: {
      primary: {
        text: "Book Tours",
        href: "/tours",
      }
    },
    image: "https://i.imghippo.com/files/GWC7459PfI.jpg",
    icon: Palmtree
  }
];

const upcomingEvents = [
  {
    id: "nyepi-day",
    title: "Nyepi Day",
    date: "March 29, 2025",
    image: "https://i.imghippo.com/files/sWpU8940oiQ.jpg",
    description: "Experience the unique Day of Silence where the entire island comes to a complete standstill.",
    category: "Cultural",
    duration: "24 hours"
  },
  {
    id: "galungan",
    title: "Galungan",
    date: "March 11, 2025",
    image: "https://i.imghippo.com/files/ZZS3144r.jpg",
    description: "One of Bali's most important religious festivals celebrating the victory of dharma over adharma.",
    category: "Cultural",
    duration: "10 days"
  },
  {
    id: "bali-spirit-festival",
    title: "Bali Spirit Festival",
    date: "March 29 - April 6, 2025",
    image: "https://images.unsplash.com/photo-1506126613408-eca07ce68773?auto=format&fit=crop&q=80",
    description: "Join the world's most inspiring celebration of yoga, dance, and music in the heart of Bali.",
    category: "Festival",
    duration: "9 days"
  }
];

const luxuryVillas = [
  {
    title: "Villas in Ubud",
    location: "Ubud",
    image: "https://i.imghippo.com/files/yg4561doE.webp",
    description: "Discover luxury villas nestled in the cultural heart of Bali",
    href: "/villas/location/ubud"
  },
  {
    title: "Villas in Uluwatu",
    location: "Uluwatu",
    image: "https://i.imghippo.com/files/YhQj9317kLk.jpg",
    description: "Experience clifftop luxury with stunning ocean views",
    href: "/villas/location/uluwatu"
  },
  {
    title: "Villas in Canggu",
    location: "Canggu",
    image: "https://i.imghippo.com/files/sztl8102Y.webp",
    description: "Modern luxury villas in Bali's trendiest area",
    href: "/villas/location/canggu"
  }
];

const popularTours = [
  {
    id: 'mt-agung-hiking',
    title: "Mt. Agung Premium Volcano Hiking",
    duration: "2 Days",
    image: "https://i.imghippo.com/files/RAZU8832.webp",
    description: "Premium guided trek to Bali's highest volcano with all equipment included.",
    price: "IDR 3M"
  },
  {
    id: 'nusa-penida-snorkeling',
    title: "Nusa Penida Snorkeling Trip",
    duration: "8 Hours",
    image: "https://i.imghippo.com/files/jjp6506gcc.jpg",
    description: "Experience the stunning underwater world of Nusa Penida and swim with majestic manta rays.",
    price: "From $30"
  },
  {
    id: 'waterfall-adventure',
    title: "Waterfall Tour",
    duration: "7 Hours",
    image: "https://i.imghippo.com/files/fOXl5804TGE.jpg",
    description: "Visit Bali's most beautiful waterfalls including Tukad Cepung, Tibumana, Kanto Lampo, and Suwat Waterfall.",
    price: "From IDR 240k"
  }
];

const restaurants = [
  {
    title: "Ours",
    location: "Uluwatu",
    image: "https://i.imghippo.com/files/VpA3203zXg.webp",
    description: "Ours Bali is one of the best restaurants in Uluwatu, serving gourmet breakfast, lunch, and dinner in a stylish, tropical-chic setting. Enjoy creative dishes made from high-quality local ingredients, paired with classic cocktails and wines.",
    category: "Fine Dining",
    rating: 4.9,
    reviews: 342,
    hours: "Daily: 8:00 AM - 10:00 PM"
  },
  {
    title: "Lemon & Salt Rooftop Bar",
    location: "Canggu",
    image: "https://i.imghippo.com/files/MGh7677EyE.jpg",
    description: "A hidden gem in the heart of Berawa with an infinity pool overlooking the beach, live music and awesome cocktails",
    category: "Rooftop Bar & Restaurant",
    rating: 4.8,
    reviews: 256,
    hours: "Daily: 11:00 AM - Late"
  },
  {
    title: "Huma Cafe",
    location: "Ubud",
    image: "https://i.imghippo.com/files/tHD9543HGY.jpg",
    description: "Charming cafe in the middle of a rice field, serving organic breakfast, artisanal coffee, and healthy lunch options with stunning views.",
    category: "Cafe",
    rating: 4.8,
    reviews: 478,
    hours: "Daily: 7:00 AM - 6:00 PM"
  }
];

const featuredExperiences = [
  {
    icon: Calendar,
    title: "Upcoming Events",
    description: "Don't miss Bali's special moments",
    href: "/events"
  },
  {
    icon: Building2,
    title: "Villas for Rent",
    description: "Experience world-class properties",
    href: "/villas-for-rent"
  },
  {
    icon: Palmtree,
    title: "Popular Tours",
    description: "Immerse yourself in adventure",
    href: "/tours"
  },
  {
    icon: Coffee,
    title: "Culinary Experiences",
    description: "Taste the flavors of Bali",
    href: "/dining"
  },
  {
    icon: Calendar,
    title: "Upcoming Events",
    description: "Don't miss Bali's special moments",
    href: "/events"
  },
  {
    icon: Building2,
    title: "Villas for Rent",
    description: "Experience world-class properties",
    href: "/villas-for-rent"
  },
  {
    icon: Palmtree,
    title: "Popular Tours",
    description: "Immerse yourself in adventure",
    href: "/tours"
  },
  {
    icon: Coffee,
    title: "Culinary Experiences",
    description: "Taste the flavors of Bali",
    href: "/dining"
  }
];

export default function FeaturedListing() {
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    heroSlides.forEach(slide => {
      const img = new Image();
      img.src = `${slide.image}?w=1200&q=80`;
      img.onload = () => {
        setLoadedImages(prev => new Set([...prev, slide.image]));
      };
    });
  }, []);

  const handleNavigation = (path: string) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  const handleMouseEnter = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.start();
    }
  };

  return (
    <div>
      {/* Hero Section */}
      <div className="relative w-full">
        {/* This div sets the aspect ratio */}
        <div className="relative w-full h-[70vh]">
          <Swiper
            modules={[Pagination, Autoplay]}
            pagination={{
              clickable: true,
              el: '.swiper-pagination',
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            onSwiper={(swiper) => {
              setSwiperInstance(swiper);
            }}
            onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
            className="absolute inset-0 h-full"
          >
            {heroSlides.map((slide, index) => {
              const thumbnailUrl = `${slide.image}?w=20&q=10`;
              const optimizedUrl = `${slide.image}?w=1200&q=80`;

              return (
                <SwiperSlide key={index} className="h-full">
                  <div className="relative h-full">
                    {/* Low quality placeholder */}
                    <div 
                      className="absolute inset-0"
                      style={{
                        backgroundImage: `url(${thumbnailUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: !loadedImages.has(slide.image) ? 'blur(20px)' : 'none',
                        transition: 'filter 0.3s ease-out'
                      }}
                    />
                    
                    {/* Main image */}
                    <img
                      src={optimizedUrl}
                      alt={slide.title}
                      className={`w-full h-full object-cover transition-opacity duration-300 ${
                        loadedImages.has(slide.image) ? 'opacity-100' : 'opacity-0'
                      }`}
                      loading={index === 0 ? "eager" : "lazy"}
                      decoding="async"
                      sizes="100vw"
                    />
                    
                    {/* Gradient overlay */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-10"></div>

                    {/* Content */}
                    <div className="absolute inset-0 flex items-center justify-center z-20">
                      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center h-full">
                        <div className="max-w-3xl md:mx-0">
                          <div className="flex items-center mb-2 md:mb-4">
                            <slide.icon className="h-6 w-6 md:h-8 md:w-8 text-blue-400 mr-2 md:mr-3" />
                            <span className="text-blue-400 font-semibold text-sm md:text-lg">{slide.subtitle}</span>
                          </div>
                          <h1 className="text-2xl md:text-5xl font-bold text-white mb-2 md:mb-4 md:mb-6">
                            {slide.title}
                          </h1>
                          <p className="text-base md:text-xl text-gray-200 mb-4 md:mb-8 line-clamp-3 md:line-clamp-none">
                            {slide.description}
                          </p>
                          <div className="flex flex-wrap gap-3 md:gap-4">
                            <div 
                              onClick={() => handleNavigation(slide.cta.primary.href)}
                              className="bg-blue-600 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-700 transition-colors inline-flex items-center cursor-pointer"
                            >
                              {slide.cta.primary.text}
                              <ArrowRight className="ml-2 h-4 w-4 md:h-5 md:w-5" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* Custom Pagination */}
          <div className="swiper-pagination absolute bottom-4 left-0 right-0 flex justify-center space-x-2 z-30"></div>
        </div>
      </div>

      {/* Featured Experiences Section */}
      <div className="bg-white pt-16 pb-0 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Swiper
            modules={[Autoplay]}
            slidesPerView="auto"
            spaceBetween={32}
            loop={true}
            speed={5000}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            className="!overflow-visible"
            onSwiper={(swiper) => {
              if (swiper && swiper.autoplay) {
                setSwiperInstance(swiper);
              }
            }}
          >
            {featuredExperiences.map((item, index) => (
              <SwiperSlide
                key={index}
                className="!w-auto"
                style={{ width: 'auto' }}
              >
                <div 
                  onClick={() => handleNavigation(item.href)}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="featured-experience-card bg-gray-50 rounded-xl p-6 hover:bg-gray-100 transition-all cursor-pointer w-[300px]"
                >
                  <item.icon className="h-8 w-8 text-blue-600 mb-4" />
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* Events Section */}
      <div className="bg-white pt-16 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-8">
            <div 
              onClick={() => handleNavigation('/events')}
              className="group cursor-pointer"
            >
              <h2 className="text-3xl font-bold text-gray-900 group-hover:text-blue-600 transition-colors">
                Upcoming Events
              </h2>
            </div>
            <div 
              onClick={() => handleNavigation('/events')}
              className="text-blue-600 hover:text-blue-700 font-semibold flex items-center cursor-pointer"
            >
              View All Events
              <ArrowRight className="ml-1 h-4 w-4" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {upcomingEvents.map((event, index) => (
              <div
                key={index}
                onClick={() => handleNavigation(`/events/${event.id}`)}
                className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-shadow cursor-pointer"
              >
                <div className="relative h-48 overflow-hidden">
                  <img
                    src={event.image}
                    alt={event.title}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                  />
                  <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                    {event.category}
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <Calendar className="h-4 w-4 mr-1" />
                    {event.date}
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{event.title}</h3>
                  <p className="text-gray-600">{event.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Restaurants & Cafés Section */}
      <div className="bg-white pt-16 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-8">
            <div 
              onClick={() => handleNavigation('/dining')}
              className="group cursor-pointer"
            >
              <h2 className="text-3xl font-bold text-gray-900 group-hover:text-blue-600 transition-colors">
                Restaurants & Cafés
              </h2>
            </div>
            <div 
              onClick={() => handleNavigation('/dining')}
              className="text-blue-600 hover:text-blue-700 font-semibold flex items-center cursor-pointer"
            >
              View All
              <ArrowRight className="ml-1 h-4 w-4" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {restaurants.map((restaurant, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-shadow">
                <div className="relative h-48 overflow-hidden">
                  <img
                    src={restaurant.image}
                    alt={restaurant.title}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                    {restaurant.category}
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <MapPin className="h-4 w-4 mr-1" />
                    <span>{restaurant.location}</span>
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{restaurant.title}</h3>
                  <p className="text-gray-600 mb-4">{restaurant.description}</p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Star className="h-4 w-4 text-yellow-400 fill-yellow-400 mr-1" />
                      <span className="font-semibold">{restaurant.rating}</span>
                      <span className="text-gray-500 text-sm ml-1">({restaurant.reviews} reviews)</span>
                    </div>
                    <div 
                      onClick={() => handleNavigation('/dining')}
                      className="text-blue-600 hover:text-blue-700 font-semibold flex items-center cursor-pointer"
                    >
                      View Menu
                      <ArrowRight className="ml-1 h-4 w-4" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Tours Section */}
      <div className="bg-white pt-16 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-8">
            <div 
              onClick={() => handleNavigation('/tours')}
              className="group cursor-pointer"
            >
              <h2 className="text-3xl font-bold text-gray-900 group-hover:text-blue-600 transition-colors">
                Popular Tours
              </h2>
            </div>
            <div 
              onClick={() => handleNavigation('/tours')}
              className="text-blue-600 hover:text-blue-700 font-semibold flex items-center cursor-pointer"
            >
              View All Tours
              <ArrowRight className="ml-1 h-4 w-4" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {popularTours.map((tour, index) => (
              <div
                key={index}
                onClick={() => handleNavigation(`/tours/${tour.id}`)}
                className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-shadow cursor-pointer"
              >
                <div className="relative h-48 overflow-hidden">
                  <img
                    src={tour.image}
                    alt={tour.title}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                  />
                  <div className="absolute bottom-4 right-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                    {tour.price}
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <Clock className="h-4 w-4 mr-1" />
                    {tour.duration}
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{tour.title}</h3>
                  <p className="text-gray-600">{tour.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}