import React from 'react';
import { X } from 'lucide-react';

interface GuidePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function GuidePopup({ isOpen, onClose }: GuidePopupProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="relative bg-white rounded-xl shadow-xl max-w-lg w-full mx-4 overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-900">About Turbo Tours</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-6">
          <div className="mb-6">
            <h3 className="text-xl font-bold text-gray-900 mb-2">About Hendrix and Turbo Tours</h3>
            <p className="text-gray-700 leading-relaxed">
              Hendrix, the founder of Turbo Tours, has called Bali home for over 15 years. Originally from Java, he specializes in leading unforgettable Mt. Agung trekking trips. When he's not guiding hikers, Hendrix is an actor, model, and MMA/fitness instructor, bringing his passion for adventure and fitness into every tour.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              With Turbo Tours, you'll experience Bali's stunning landscapes with a local guide who's dedicated to making your trek both safe and unforgettable.
            </p>
          </div>
          <div className="flex justify-end">
            <a 
              href="https://wa.me/6281617804338?text=Hey%20I%20found%20your%20contact%20on%20theabaliexpert.com.%20I%20am%20interested%20in%20the%20Mt.%20Agung%20Premium%20Volcano%20Hiking%20Experience."
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
            >
              Contact Turbo Tours
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}