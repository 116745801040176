import React, { useState } from 'react';
import { Clock, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const activities = [
  {
    id: 'mason-elephant-park',
    title: "Mason Elephant Park",
    description: "Get up close with Bali's gentle giants at this ethical elephant sanctuary. Feed, wash, and interact with rescued elephants in a natural setting.",
    image: "https://i.imghippo.com/files/Y6291FWg.jpg",
    price: "From IDR 950k",
    duration: "Half Day"
  },
  {
    id: 'uluwatu-paragliding',
    title: "Uluwatu Paragliding Experience",
    description: "Soar above Uluwatu's dramatic cliffs for breathtaking views of the Indian Ocean. Perfect for both beginners and experienced paragliders.",
    image: "https://i.imghippo.com/files/Nm4950iFc.jpg",
    price: "From IDR 1.2M",
    duration: "2 Hours"
  },
  {
    id: 'cooking-class',
    title: "Traditional Balinese Cooking Class",
    description: "Learn to prepare authentic Balinese dishes in a traditional family compound, including a visit to the local market.",
    image: "https://i.imghippo.com/files/wOg2291BX.jpg",
    price: "From IDR 550k",
    duration: "6 Hours"
  },
  {
    id: 'sacred-water-purification',
    title: "Sacred Water Purification",
    description: "Experience a traditional Balinese water purification ceremony at a sacred waterfall, guided by a local priest.",
    image: "https://i.imghippo.com/files/aYw6409AI.jpg",
    price: "From IDR 750k",
    duration: "4 Hours"
  },
  {
    id: 'canggu-surfing',
    title: "Surfing Lessons in Canggu",
    description: "Learn to surf with professional instructors at Canggu's best beaches. Suitable for all skill levels.",
    image: "https://i.imghippo.com/files/fnOy6410UDk.jpg",
    price: "From IDR 450k",
    duration: "2 Hours"
  }
];

export default function Activities() {
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const heroImageUrl = "https://i.imghippo.com/files/Y6291FWg.jpg";
  const thumbnailUrl = `${heroImageUrl}?w=20&q=10`;
  const navigate = useNavigate();

  const handleActivityClick = (activityId: string) => {
    if (activityId) {
      window.scrollTo(0, 0);
      navigate(`/activities/${activityId}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative w-full">
        {/* This div sets the aspect ratio */}
        <div className="relative w-full pb-[100%] md:pb-[56.25%]">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0"
              style={{
                backgroundImage: `url(${thumbnailUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: !heroImageLoaded ? 'blur(20px)' : 'none',
                transition: 'filter 0.3s ease-out'
              }}
            />
            <img
              src={heroImageUrl}
              alt="Activities"
              className={`w-full h-full object-cover transition-opacity duration-300 ${
                heroImageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setHeroImageLoaded(true)}
              loading="eager"
              fetchpriority="high"
              decoding="async"
              sizes="100vw"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
          </div>

          {/* Content */}
          <div className="absolute inset-0 flex flex-col justify-end">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-12 md:pb-24">
              <div className="max-w-3xl">
                <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                  Activities
                </h1>
                <p className="text-xl text-gray-200 mb-8">
                  From sacred temple ceremonies to thrilling adventures, discover extraordinary experiences curated by local experts. Each activity offers an authentic glimpse into the island's rich culture and natural wonders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Activities Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {activities.slice(0, 3).map((activity, index) => (
            <div
              key={index}
              onClick={() => activity.id && handleActivityClick(activity.id)}
              className={`bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300 ${
                activity.id ? 'cursor-pointer' : ''
              }`}
            >
              <div className="relative h-48">
                <img
                  src={activity.image}
                  alt={activity.title}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                  loading="lazy"
                  decoding="async"
                />
                <div className="absolute bottom-4 right-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                  {activity.price}
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center text-gray-500 text-sm mb-2">
                  <Clock className="h-4 w-4 mr-1" />
                  <span>{activity.duration}</span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{activity.title}</h3>
                <p className="text-gray-600">{activity.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}