import React, { useState, useEffect, useRef } from 'react';
import { Calendar, Megaphone, Settings, Star, Check, ArrowRight, MessageSquare, Mail, Users, Home, Share2, PenTool, Target, DollarSign, BarChart, Award, Shield, ChevronRight, X } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const heroImages = [
  "https://i.imghippo.com/files/gDQd2494gWA.jpg",
  "https://i.imghippo.com/files/Awi3900iw.jpeg",
  "https://i.imghippo.com/files/ZG6813Eso.jpg",
  "https://i.imghippo.com/files/TrW9586GhA.jpg"
];

const services = [
  {
    title: "Revenue Optimization",
    image: "https://i.imghippo.com/files/gDQd2494gWA.jpg",
    description: "We maximize your property's earning potential through dynamic pricing strategies, premium guest targeting, and strategic positioning in the market.",
    features: [
      "Dynamic pricing based on seasons and demand",
      "Premium guest targeting",
      "Strategic listing optimization",
      "Revenue performance tracking",
      "Competitive market analysis"
    ]
  },
  {
    title: "Guest Experience",
    image: "https://i.imghippo.com/files/Awi3900iw.jpeg",
    description: "We deliver exceptional guest experiences that lead to 5-star reviews, repeat bookings, and word-of-mouth referrals.",
    features: [
      "24/7 guest communication",
      "Personalized welcome packages",
      "Local recommendations and experiences",
      "Prompt issue resolution",
      "Seamless check-in/check-out process"
    ]
  },
  {
    title: "Property Care",
    image: "https://i.imghippo.com/files/ZG6813Eso.jpg",
    description: "We keep your property in pristine condition with regular inspections, professional cleaning, and prompt repairs.",
    features: [
      "Regular property inspections",
      "Professional cleaning services",
      "Preventative maintenance",
      "Quick-response repair network",
      "Inventory management"
    ]
  }
];

const benefits = [
  {
    title: "Personalized, Boutique Management",
    description: "We keep our portfolio intentionally small to give your villa the dedicated attention it deserves. You're not just another listing—you're a valued partner."
  },
  {
    title: "Maximized Earnings with Direct Payouts",
    description: "We ensure your property is strategically positioned for maximum revenue, with direct payouts from OTAs like Airbnb, Booking.com, VRBO, Agoda, and Marriott Homes & Villas."
  },
  {
    title: "High-Impact Digital Marketing",
    description: "Dedicated marketing team to create premium visuals & campaigns, strong media presence through our @thebaliexpert platform, and multi-channel listing management across 15+ top OTAs."
  }
];

const process = [
  {
    title: "Initial Consultation & Revenue Projection",
    description: "We analyze your villa's market potential and provide custom insights on expected earnings."
  },
  {
    title: "Professional Setup & Listing Optimization",
    description: "We prepare your villa for success with high-quality photos, marketing materials, and strategic positioning on OTAs."
  },
  {
    title: "Seamless Onboarding & Staff Training",
    description: "Our team ensures your property is ready for guests, handling staff coordination and operational setup."
  },
  {
    title: "Continuous Growth & Revenue Maximization",
    description: "We actively monitor pricing, bookings, and market trends, ensuring your villa consistently outperforms competitors."
  }
];

const testimonials = [
  {
    name: "Sarah",
    property: "Private Villa, Ubud",
    image: "https://i.imghippo.com/files/gDQd2494gWA.jpg",
    quote: "Since working with Bali Expert Villa Management my villa's occupancy has increased by 30% and my revenue has nearly doubled."
  },
  {
    name: "Michael",
    property: "Private Villa, Canggu",
    image: "https://i.imghippo.com/files/Awi3900iw.jpeg",
    quote: "I interviewed several management companies but Bali Expert Villa Management stood out with their attention to detail and personalized service."
  },
  {
    name: "Bianca",
    property: "Private Villa, Ubud",
    image: "https://i.imghippo.com/files/ZG6813Eso.jpg",
    quote: "Bali Expert Villa Management makes owning my villa completely hands-off they handle absolutely everything."
  }
];

interface AnimatedStat {
  value: string;
  label: string;
  endValue: number;
  suffix?: string;
  special?: string;
}

const stats: AnimatedStat[] = [
  { value: "35%", label: "Average Revenue Increase", endValue: 35, suffix: "%" },
  { value: "98%", label: "Guest Satisfaction", endValue: 98, suffix: "%" },
  { value: "24/7", label: "Support Available", endValue: 24, special: "/7" },
  { value: "6+", label: "Years Experience", endValue: 6, suffix: "+" }
];

export default function Management() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isWhatsAppPopupOpen, setIsWhatsAppPopupOpen] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [animatedValues, setAnimatedValues] = useState<{ [key: number]: number }>({});
  const statsRef = useRef<HTMLDivElement>(null);
  const animationStarted = useRef(false);
  
  const handleContactClick = () => {
    setIsWhatsAppPopupOpen(true);
  };

  const handleWhatsAppChat = () => {
    const message = encodeURIComponent("Hello, I'm interested in your property management services for my villa in Bali.");
    window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
    setIsWhatsAppPopupOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % heroImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + heroImages.length) % heroImages.length);
  };

  const handleMouseEnter = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.start();
    }
  };

  const formatNumber = (num: number, index: number): string => {
    if (index === 3) {
      return `${num}`;
    }
    return num < 10 ? `0${num}` : `${num}`;
  };

  const animateValue = (start: number, end: number, duration: number, index: number) => {
    const startTimestamp = performance.now();
    
    const updateNumber = (currentTimestamp: number) => {
      const elapsed = currentTimestamp - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);
      
      const easeOutQuart = 1 - Math.pow(1 - progress, 4);
      const current = Math.floor(start + (end - start) * easeOutQuart);
      
      setAnimatedValues(prev => ({
        ...prev,
        [index]: current
      }));
      
      if (progress < 1) {
        requestAnimationFrame(updateNumber);
      }
    };
    
    requestAnimationFrame(updateNumber);
  };

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
    
    return () => {
      document.documentElement.style.scrollBehavior = '';
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationStarted.current) {
            animationStarted.current = true;
            
            stats.forEach((stat, index) => {
              animateValue(0, stat.endValue, 2000, index);
            });
          }
        });
      },
      {
        threshold: 0.2
      }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <div className="relative h-[90vh]">
        <div className="absolute inset-0">
          {heroImages.map((image, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-1000 ${
                index === currentImageIndex ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <img
                src={image}
                alt="Villa Management"
                className="w-full h-full object-cover"
                onLoad={() => setImageLoaded(true)}
              />
            </div>
          ))}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>

        <div className="absolute inset-0 flex items-center">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-xl md:ml-0">
              <span className="text-blue-400 font-medium text-sm md:text-base mb-2 block">
                Bali Expert
              </span>
              <h1 className="text-2xl md:text-4xl font-bold text-white mb-2 md:mb-3">
                Villa Management
              </h1>
              <p className="text-sm md:text-base text-white mb-4 md:mb-6">
                More profits. Less stress. 100% personalized service for your Bali villa.
              </p>
              <div className="h-12 md:h-16 flex items-center">
                <button 
                  onClick={handleContactClick}
                  className="bg-green-600 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm font-semibold hover:bg-green-700 transition-colors inline-flex items-center"
                >
                  Chat on WhatsApp
                  <ArrowRight className="ml-2 h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute -bottom-20 left-0 right-0" ref={statsRef}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white rounded-xl shadow-xl grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 p-4 md:p-8">
              {stats.map((stat, index) => (
                <div key={index} className="text-center">
                  <div className="text-xl md:text-4xl font-bold text-blue-600 mb-1 md:mb-2 tabular-nums">
                    {typeof animatedValues[index] !== 'undefined' ? (
                      <>
                        {stat.special ? (
                          <>
                            {formatNumber(animatedValues[index], index)}
                            {stat.special}
                          </>
                        ) : (
                          <>
                            {formatNumber(animatedValues[index], index)}
                            {stat.suffix}
                          </>
                        )}
                      </>
                    ) : (
                      '00'
                    )}
                  </div>
                  <div className="text-xs md:text-base text-gray-600">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="h-32"></div>

      <div className="relative py-12">
        <div className="absolute inset-0">
          <img
            src="https://i.imghippo.com/files/TrW9586GhA.jpg"
            alt="Benefits Background"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg">
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-6 md:mb-8 text-center">
              Why Choose Bali Expert Villa Management?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <div key={index} className="bg-white rounded-xl p-8 shadow-sm">
                  <h3 className="text-lg md:text-xl font-bold text-gray-900 mb-4">{benefit.title}</h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {services.map((service, index) => (
        <div key={index} className="relative min-h-screen flex items-center">
          <div className="absolute inset-0">
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/60 to-transparent"></div>
          </div>
          <div className="relative w-full py-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-2xl">
                <h2 className="text-2xl md:text-4xl font-bold text-white mb-4 md:mb-6">{service.title}</h2>
                <div className="max-w-xl">
                  <p className="text-base md:text-xl text-gray-200 mb-6 md:mb-8">{service.description}</p>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  {service.features.map((feature, idx) => (
                    <div key={idx} className="flex items-center space-x-3">
                      <div className="flex-shrink-0 h-10 w-10 bg-blue-600/20 backdrop-blur-sm rounded-lg flex items-center justify-center">
                        <Check className="h-6 w-6 text-blue-400" />
                      </div>
                      <span className="text-sm md:text-lg text-white">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="relative py-12">
        <div className="absolute inset-0">
          <img
            src="https://i.imghippo.com/files/TrW9586GhA.jpg"
            alt="Process Background"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="relative w-full">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-6 md:mb-8 text-center">
              How We Work
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {process.map((step, index) => (
                <div key={index} className="bg-white rounded-xl p-8 shadow-lg">
                  <div className="flex items-center mb-4">
                    <div className="h-10 w-10 bg-blue-600 rounded-full flex items-center justify-center text-white font-bold mr-4">
                      {index + 1}
                    </div>
                    <h3 className="text-lg md:text-xl font-bold text-gray-900">{step.title}</h3>
                  </div>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="py-16 md:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-8 md:mb-12 text-center">
            What Our Clients Say
          </h2>
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            spaceBetween={24}
            loop={true}
            speed={5000}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            onSwiper={(swiper) => {
              setSwiperInstance(swiper);
            }}
            className="testimonial-swiper"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="bg-white rounded-2xl shadow-lg overflow-hidden h-full">
                  <div className="flex flex-col h-full">
                    <div className="relative h-48 md:h-64">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
                    </div>
                    <div className="p-6 relative flex-grow">
                      <div className="flex items-center mb-4">
                        {[...Array(5)].map((_, i) => (
                          <Star key={i} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                        ))}
                      </div>
                      <p className="text-gray-700 text-base md:text-lg italic mb-4">"{testimonial.quote}"</p>
                      <div className="mt-auto">
                        <h4 className="text-gray-900 font-bold">{testimonial.name}</h4>
                        <p className="text-gray-600">{testimonial.property}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="relative py-24 bg-blue-600">
        <div className="absolute inset-0 overflow-hidden">
          <img
            src="https://i.imghippo.com/files/TrW9586GhA.jpg"
            alt="Background"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl md:text-4xl font-bold text-white mb-3 md:mb-4">
            Ready to Maximize Your Villa's Potential?
          </h2>
          <p className="text-base md:text-xl text-blue-100 mb-8 md:mb-12 max-w-3xl mx-auto">
            Join Bali's boutique property management service and experience effortless ownership with maximum returns.
          </p>
          <div className="h-12 md:h-16 flex items-center justify-center">
            <button 
              onClick={handleContactClick}
              className="bg-white text-blue-600 px-6 md:px-8 py-3 md:py-4 rounded-lg text-sm md:text-lg font-semibold hover:bg-gray-100 transition-colors inline-flex items-center"
            >
              Free Consultation
              <ArrowRight className="ml-2 h-4 w-4 md:h-6 md:w-6" />
            </button>
          </div>
        </div>
      </div>

      {isWhatsAppPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsWhatsAppPopupOpen(false)}></div>
          <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-900">Contact Us</h2>
                <button 
                  onClick={() => setIsWhatsAppPopupOpen(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <p className="text-gray-600 mb-6">
                Get in touch with our villa management team to discuss how we can help maximize your property's potential.
              </p>
              <button 
                onClick={handleWhatsAppChat}
                className="w-full bg-green-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center mb-4"
              >
                <MessageSquare className="mr-2 h-5 w-5" />
                Chat on WhatsApp
              </button>
              <button 
                onClick={() => setIsWhatsAppPopupOpen(false)}
                className="w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}