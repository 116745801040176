import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface ImageCarouselProps {
  images: string[];
}

export default function ImageCarousel({ images }: ImageCarouselProps) {
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  // Preload the next image
  useEffect(() => {
    const preloadNextImage = (index: number) => {
      const nextIndex = (index + 1) % images.length;
      const nextImage = images[nextIndex];
      
      if (!loadedImages.has(nextImage)) {
        const img = new Image();
        img.src = nextImage;
        img.onload = () => {
          setLoadedImages(prev => new Set([...prev, nextImage]));
        };
      }
    };

    // Preload the next 2 images
    preloadNextImage(currentIndex);
    preloadNextImage((currentIndex + 1) % images.length);
  }, [currentIndex, images, loadedImages]);

  // Initial load of first image
  useEffect(() => {
    const img = new Image();
    img.src = images[0];
    img.onload = () => {
      setLoadedImages(prev => new Set([...prev, images[0]]));
    };
  }, [images]);

  const handleSlideChange = (swiper: any) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const handleMouseEnter = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.start();
    }
  };

  return (
    <div className="relative w-full h-full group">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onSlideChange={handleSlideChange}
        onSwiper={setSwiperInstance}
        className="h-full w-full"
        loop={true}
      >
        {images.map((image, index) => {
          const thumbnailUrl = `${image}?w=20&q=10`;
          const optimizedUrl = `${image}?w=1200&q=80`; // Reduced from 1920 to 1200 for better performance

          return (
            <SwiperSlide key={index} className="h-full">
              <div 
                className="relative w-full h-full"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {/* Low quality placeholder */}
                <div 
                  className="absolute inset-0"
                  style={{
                    backgroundImage: `url(${thumbnailUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: !loadedImages.has(image) ? 'blur(20px)' : 'none',
                    transition: 'filter 0.3s ease-out'
                  }}
                />
                
                {/* Main image */}
                <img
                  src={optimizedUrl}
                  alt={`Slide ${index + 1}`}
                  className={`w-full h-full object-cover transition-opacity duration-300 ${
                    loadedImages.has(image) ? 'opacity-100' : 'opacity-0'
                  }`}
                  loading={index === 0 ? "eager" : "lazy"}
                  decoding="async"
                  sizes="(max-width: 1200px) 100vw, 1200px"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <button 
        className="swiper-button-prev property-slider-nav absolute left-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/80 text-gray-800 z-30 shadow-md"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ChevronLeft className="h-6 w-6" />
      </button>
      <button 
        className="swiper-button-next property-slider-nav absolute right-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/80 text-gray-800 z-30 shadow-md"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ChevronRight className="h-6 w-6" />
      </button>

      {/* Custom Pagination */}
      <div className="swiper-pagination absolute bottom-4 left-0 right-0 flex justify-center space-x-2 z-30"></div>
    </div>
  );
}