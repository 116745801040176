import React, { useState, useEffect } from 'react';
import { Menu, X, Mail, ChevronRight, MessageSquare, Instagram, Calendar, Palmtree, Coffee, ChevronDown, Building2, PenTool, LogIn, Plus, LogOut, User, Home } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from '../lib/supabase';

const menuItems = [
  {
    name: 'Home',
    href: '/',
    submenu: [
      {
        name: 'Villas',
        href: '/villas-for-rent'
      },
      {
        name: 'Restaurants & Cafés',
        href: '/dining'
      },
      {
        name: 'Events',
        href: '/events'
      },
      {
        name: 'Tours',
        href: '/tours'
      }
    ]
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/thebaliexpert',
    external: true
  },
  { 
    name: 'Business', 
    href: '/business',
    submenu: [
      {
        name: 'Digital Marketing',
        href: '/marketing'
      },
      {
        name: 'Property Management',
        href: '/property-management'
      },
      {
        name: 'Advertising',
        href: '#',
        onClick: () => {
          const message = encodeURIComponent("Hello, I'm interested in your marketing and lead generation services.");
          window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
        }
      }
    ]
  },
  {
    name: 'Contact',
    submenu: [
      {
        name: 'WhatsApp',
        href: '#',
        icon: MessageSquare,
        onClick: () => {} // This will be set dynamically
      },
      {
        name: 'Instagram',
        href: '#',
        icon: Instagram,
        onClick: () => {} // This will be set dynamically
      }
    ]
  }
];

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [expandedMobileMenus, setExpandedMobileMenus] = useState<string[]>([]);
  const [expandedMobileSubmenus, setExpandedMobileSubmenus] = useState<string[]>([]);
  const [canAddProperty, setCanAddProperty] = useState(false);
  const [isWhatsAppPopupOpen, setIsWhatsAppPopupOpen] = useState(false);
  const [isInstagramPopupOpen, setIsInstagramPopupOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      checkCanAddProperty();
    } else {
      setCanAddProperty(false);
    }
  }, [user, location.pathname]);

  useEffect(() => {
    const contactMenu = menuItems.find(item => item.name === 'Contact');
    if (contactMenu && contactMenu.submenu) {
      const whatsAppItem = contactMenu.submenu.find(item => item.name === 'WhatsApp');
      if (whatsAppItem) {
        whatsAppItem.onClick = () => setIsWhatsAppPopupOpen(true);
      }
      const instagramItem = contactMenu.submenu.find(item => item.name === 'Instagram');
      if (instagramItem) {
        instagramItem.onClick = () => setIsInstagramPopupOpen(true);
      }
    }
  }, []);

  const checkCanAddProperty = () => {
    try {
      const membershipPlan = user?.user_metadata?.membership_plan || 'basic';
      
      let maxListings = 0;
      switch (membershipPlan) {
        case 'premium':
          maxListings = 4;
          break;
        case 'standard':
          maxListings = 1;
          break;
        default:
          maxListings = 0;
      }
      
      const existingProperties = JSON.parse(localStorage.getItem('properties') || '[]');
      const userProperties = existingProperties.filter((prop: any) => 
        prop.contactEmail === user.email
      );
      
      setCanAddProperty(userProperties.length < maxListings);
    } catch (error) {
      console.error('Error checking property limits:', error);
      setCanAddProperty(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
    } else {
      setExpandedMobileMenus([]);
      setExpandedMobileSubmenus([]);
    }
  }, [isOpen]);

  const isRentalPage = () => {
    return (
      location.pathname === '/villas-for-rent' || 
      location.pathname.includes('/villas/rent/') ||
      location.pathname === '/add-property' ||
      location.pathname === '/manage-properties'
    );
  };

  const handleMouseEnter = (menuName: string) => {
    setActiveDropdown(menuName);
  };

  const handleSubmenuEnter = (submenuName: string) => {
    setActiveSubmenu(submenuName);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
    setActiveSubmenu(null);
  };

  const handleNavigation = (path: string) => {
    window.scrollTo(0, 0);
    navigate(path);
    setIsOpen(false);
    setExpandedMobileMenus([]);
    setExpandedMobileSubmenus([]);
  };

  const toggleMobileMenu = (menuName: string) => {
    setExpandedMobileMenus(prev => 
      prev.includes(menuName) 
        ? prev.filter(name => name !== menuName) 
        : [...prev, menuName]
    );
  };

  const toggleMobileSubmenu = (submenuName: string) => {
    setExpandedMobileSubmenus(prev => 
      prev.includes(submenuName) 
        ? prev.filter(name => name !== submenuName) 
        : [...prev, submenuName]
    );
  };

  const handleLogout = async () => {
    const { error } = await signOut();
    if (error) {
      console.error('Error signing out:', error);
    }
    navigate('/');
  };

  const handleWhatsAppChat = () => {
    const message = encodeURIComponent("Hello, I'm interested in your marketing and lead generation services.");
    window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
    setIsWhatsAppPopupOpen(false);
  };

  const handleInstagramView = () => {
    window.open('https://www.instagram.com/thebaliexpert', '_blank');
    setIsInstagramPopupOpen(false);
  };

  const logoUrl = "https://i.imghippo.com/files/eWp5322SXg.png";
  const logoThumbnail = `${logoUrl}?w=20&q=10`;
  const logoOptimized = `${logoUrl}?w=150&q=80`;

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <div 
              onClick={() => handleNavigation('/')}
              className="flex-shrink-0 flex items-center cursor-pointer"
            >
              <div className="relative h-12 w-auto">
                <div 
                  className="absolute inset-0"
                  style={{
                    backgroundImage: `url(${logoThumbnail})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    filter: !logoLoaded ? 'blur(10px)' : 'none',
                    transition: 'filter 0.3s ease-out'
                  }}
                />
                <img 
                  src={logoOptimized}
                  alt="The Bali Expert"
                  className={`h-12 w-auto transition-opacity duration-300 ${
                    logoLoaded ? 'opacity-100' : 'opacity-0'
                  }`}
                  style={{ objectFit: 'contain' }}
                  onLoad={() => setLogoLoaded(true)}
                  loading="eager"
                  fetchpriority="high"
                  decoding="async"
                />
              </div>
            </div>
          </div>

          <div className="hidden md:flex md:items-center md:space-x-4">
            {menuItems.map((item) => (
              <div
                key={item.name}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(item.name)}
                onMouseLeave={handleMouseLeave}
              >
                {item.external ? (
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 px-3 py-2 text-sm font-medium"
                  >
                    <span>{item.name}</span>
                  </a>
                ) : item.href ? (
                  <div
                    onClick={() => handleNavigation(item.href)}
                    className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 px-3 py-2 text-sm font-medium cursor-pointer"
                  >
                    <span>{item.name}</span>
                    {item.submenu && (
                      <ChevronDown className="h-4 w-4 ml-1 text-gray-400" />
                    )}
                  </div>
                ) : (
                  <button
                    className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 px-3 py-2 text-sm font-medium"
                  >
                    <span>{item.name}</span>
                    {item.submenu && (
                      <ChevronDown className="h-4 w-4 ml-1 text-gray-400" />
                    )}
                  </button>
                )}
                
                {item.submenu && activeDropdown === item.name && (
                  <div className="absolute z-20 left-0 w-48 mt-0 pt-2">
                    <div className="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div className="py-1" role="menu">
                        {item.submenu.map((subItem) => (
                          <div
                            key={subItem.name}
                            className="relative"
                            onMouseEnter={() => handleSubmenuEnter(subItem.name)}
                          >
                            {subItem.external ? (
                              <a
                                href={subItem.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                role="menuitem"
                              >
                                <div className="flex items-center">
                                  {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                  <span>{subItem.name}</span>
                                </div>
                              </a>
                            ) : subItem.onClick ? (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (subItem.onClick) subItem.onClick();
                                }}
                                className="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full cursor-pointer"
                                role="menuitem"
                              >
                                <div className="flex items-center">
                                  {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                  <span>{subItem.name}</span>
                                </div>
                                {subItem.submenu && (
                                  <ChevronRight className="h-4 w-4 ml-2 text-gray-400" />
                                )}
                              </div>
                            ) : (
                              <div
                                onClick={() => handleNavigation(subItem.href)}
                                className="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full cursor-pointer"
                                role="menuitem"
                              >
                                <div className="flex items-center">
                                  {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                  <span>{subItem.name}</span>
                                </div>
                                {subItem.submenu && (
                                  <ChevronRight className="h-4 w-4 ml-2 text-gray-400" />
                                )}
                              </div>
                            )}
                            
                            {subItem.submenu && activeSubmenu === subItem.name && (
                              <div className="absolute z-30 top-0 left-full w-48 ml-0.5 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu">
                                  {subItem.submenu.map((nestedItem) => (
                                    <div
                                      key={nestedItem.name}
                                      onClick={() => handleNavigation(nestedItem.href)}
                                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                      role="menuitem"
                                    >
                                      <span>{nestedItem.name}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}

            {user && (
              <div className="relative group">
                <div
                  onClick={() => handleNavigation('/dashboard')}
                  className="bg-gray-100 text-gray-800 px-4 py-2 rounded-lg text-sm font-medium hover:bg-gray-200 transition-colors flex items-center cursor-pointer"
                >
                  <User className="h-4 w-4 mr-1" />
                  My Account
                  <ChevronDown className="h-4 w-4 ml-1 text-gray-400" />
                </div>
                <div className="absolute z-20 right-0 w-48 mt-1 pt-1 hidden group-hover:block">
                  <div className="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu">
                      <div
                        onClick={() => handleNavigation('/dashboard')}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        role="menuitem"
                      >
                        <Building2 className="h-4 w-4 mr-2" />
                        <span>My Dashboard</span>
                      </div>
                      <div
                        onClick={handleLogout}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        role="menuitem"
                      >
                        <LogOut className="h-4 w-4 mr-2" />
                        <span>Logout</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isRentalPage() && user && canAddProperty && (
              <button
                onClick={() => handleNavigation('/add-property')}
                className="bg-green-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-green-700 transition-colors flex items-center"
              >
                <Plus className="h-4 w-4 mr-1" />
                Add Property
              </button>
            )}
          </div>

          <div className="md:hidden flex items-center">
            {isRentalPage() && user && canAddProperty && (
              <button
                onClick={() => handleNavigation('/add-property')}
                className="bg-green-600 text-white px-3 py-1.5 rounded-lg text-sm font-medium hover:bg-green-700 transition-colors flex items-center mr-2"
              >
                <Plus className="h-4 w-4 mr-1" />
                Add
              </button>
            )}
            
            {user && (
              <button
                onClick={() => handleNavigation('/dashboard')}
                className="bg-gray-100 text-gray-800 px-3 py-1.5 rounded-lg text-sm font-medium hover:bg-gray-200 transition-colors flex items-center mr-2"
              >
                <User className="h-4 w-4 mr-1" />
                Account
              </button>
            )}
            
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {menuItems.map((item) => (
              <div key={item.name} className="border-b border-gray-100 last:border-b-0">
                {item.external ? (
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-blue-600 flex items-center space-x-2 px-4 py-3 text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <span>{item.name}</span>
                  </a>
                ) : item.href && !item.submenu ? (
                  <div
                    onClick={() => handleNavigation(item.href)}
                    className="text-gray-600 hover:text-blue-600 flex items-center space-x-2 px-4 py-3 text-base font-medium cursor-pointer"
                  >
                    <span>{item.name}</span>
                  </div>
                ) : (
                  <div className="px-4 py-2">
                    <div 
                      className="flex items-center justify-between text-gray-600 py-2 cursor-pointer"
                      onClick={() => {
                        if (item.href) {
                          handleNavigation(item.href);
                        } else {
                          toggleMobileMenu(item.name);
                        }
                      }}
                    >
                      <span className="text-base font-medium">{item.name}</span>
                      {item.submenu && (
                        <ChevronDown 
                          className={`h-5 w-5 text-gray-400 transition-transform ${
                            expandedMobileMenus.includes(item.name) ? 'rotate-180' : ''
                          }`} 
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleMobileMenu(item.name);
                          }}
                        />
                      )}
                    </div>
                    
                    {item.submenu && expandedMobileMenus.includes(item.name) && (
                      <div className="pl-4 mt-1 space-y-1 border-l-2 border-gray-100">
                        {item.submenu.map((subItem) => (
                          <div key={subItem.name} className="py-1">
                            {subItem.external ? (
                              <a
                                href={subItem.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-500 hover:text-blue-600 flex items-center space-x-2 py-2 text-sm"
                                onClick={() => setIsOpen(false)}
                              >
                                {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                <span>{subItem.name}</span>
                              </a>
                            ) : subItem.onClick ? (
                              <div 
                                className="text-gray-500 hover:text-blue-600 flex items-center space-x-2 py-2 text-sm cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (subItem.onClick) subItem.onClick();
                                  setIsOpen(false);
                                }}
                              >
                                {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                <span>{subItem.name}</span>
                              </div>
                            ) : subItem.submenu ? (
                              <div>
                                <div 
                                  className="flex items-center justify-between text-gray-500 hover:text-blue-600 py-2 text-sm cursor-pointer"
                                  onClick={() => {
                                    if (subItem.href) {
                                      handleNavigation(subItem.href);
                                    } else {
                                      toggleMobileSubmenu(subItem.name);
                                    }
                                  }}
                                >
                                  <div className="flex items-center">
                                    {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                    <span>{subItem.name}</span>
                                  </div>
                                  <ChevronDown 
                                    className={`h-4 w-4 text-gray-400 transition-transform ${
                                      expandedMobileSubmenus.includes(subItem.name) ? 'rotate-180' : ''
                                    }`} 
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleMobileSubmenu(subItem.name);
                                    }}
                                  />
                                </div>
                                
                                {expandedMobileSubmenus.includes(subItem.name) && (
                                  <div className="pl-6 mt-1 space-y-1 border-l border-gray-100">
                                    {subItem.submenu.map((nestedItem) => (
                                      <div
                                        key={nestedItem.name}
                                        onClick={() => handleNavigation(nestedItem.href)}
                                        className="text-gray-500 hover:text-blue-600 flex items-center space-x-2 py-2 text-sm cursor-pointer"
                                      >
                                        {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                        <span>{nestedItem.name}</span>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                onClick={() => handleNavigation(subItem.href)}
                                className="text-gray-500 hover:text-blue-600 flex items-center space-x-2 py-2 text-sm cursor-pointer"
                              >
                                {subItem.icon && <subItem.icon className="h-4 w-4 mr-2" />}
                                <span>{subItem.name}</span>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            
            {user && (
              <div className="border-t border-gray-100 pt-2">
                <div className="px-4 py-2">
                  <div className="flex items-center justify-between text-gray-600 py-2">
                    <span className="text-base font-medium">My Account</span>
                  </div>
                  <div className="pl-4 mt-1 space-y-1 border-l-2 border-gray-100">
                    <div
                      onClick={() => handleNavigation('/dashboard')}
                      className="text-gray-500 hover:text-blue-600 flex items-center py-2 text-sm cursor-pointer"
                    >
                      <Building2 className="h-4 w-4 mr-2" />
                      <span>My Dashboard</span>
                    </div>
                    <div
                      onClick={handleLogout}
                      className="text-gray-500 hover:text-blue-600 flex items-center py-2 text-sm cursor-pointer"
                    >
                      <LogOut className="h-4 w-4 mr-2" />
                      <span>Logout</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* WhatsApp Popup */}
      {isWhatsAppPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsWhatsAppPopupOpen(false)}></div>
          <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-900">Contact Us</h2>
                <button 
                  onClick={() => setIsWhatsAppPopupOpen(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <p className="text-gray-600 mb-6">
                Get in touch with our team to discuss how we can help you with your needs in Bali.
              </p>
              <button 
                onClick={handleWhatsAppChat}
                className="w-full bg-green-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center mb-4"
              >
                <MessageSquare className="mr-2 h-5 w-5" />
                Chat on WhatsApp
              </button>
              <button 
                onClick={() => setIsWhatsAppPopupOpen(false)}
                className="w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Instagram Popup */}
      {isInstagramPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsInstagramPopupOpen(false)}></div>
          <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-900">View Instagram</h2>
                <button 
                  onClick={() => setIsInstagramPopupOpen(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <p className="text-gray-600 mb-6">
                Follow us on Instagram to stay updated with our latest properties, events, and activities in Bali.
              </p>
              <button 
                onClick={handleInstagramView}
                className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-3 rounded-lg font-semibold hover:from-purple-600 hover:to-pink-600 transition-colors flex items-center justify-center mb-4"
              >
                <Instagram className="mr-2 h-5 w-5" />
                View on Instagram
              </button>
              <button 
                onClick={() => setIsInstagramPopupOpen(false)}
                className="w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}