import React from 'react';
import { Crown, Award, Shield } from 'lucide-react';

interface MembershipBadgeProps {
  plan: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export default function MembershipBadge({ plan, size = 'md', className = '' }: MembershipBadgeProps) {
  let badgeColor = '';
  let badgeIcon = null;
  let badgeText = '';

  switch (plan) {
    case 'premium':
      badgeColor = 'bg-gradient-to-r from-amber-500 to-yellow-400 text-white';
      badgeIcon = <Crown className={size === 'sm' ? 'h-3 w-3 mr-1' : 'h-4 w-4 mr-1.5'} />;
      badgeText = 'Premium';
      break;
    case 'standard':
      badgeColor = 'bg-gradient-to-r from-blue-500 to-blue-600 text-white';
      badgeIcon = <Award className={size === 'sm' ? 'h-3 w-3 mr-1' : 'h-4 w-4 mr-1.5'} />;
      badgeText = 'Standard';
      break;
    case 'basic':
      badgeColor = 'bg-gradient-to-r from-gray-500 to-gray-600 text-white';
      badgeIcon = <Shield className={size === 'sm' ? 'h-3 w-3 mr-1' : 'h-4 w-4 mr-1.5'} />;
      badgeText = 'Basic';
      break;
    default:
      badgeColor = 'bg-gray-100 text-gray-800';
      badgeText = 'Member';
  }

  const sizeClasses = {
    sm: 'text-xs px-2 py-0.5',
    md: 'text-sm px-2.5 py-1',
    lg: 'text-base px-3 py-1.5'
  };

  return (
    <span className={`inline-flex items-center rounded-full font-medium ${badgeColor} ${sizeClasses[size]} ${className}`}>
      {badgeIcon}
      {badgeText}
    </span>
  );
}