import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserPlus, Mail, Lock, User, Phone, CreditCard, Check, Calendar, Building2, ArrowRight, Utensils } from 'lucide-react';

// Registration type selection
const registrationTypes = [
  {
    id: 'events',
    title: 'Events & Promotions',
    description: 'Promote your events on our platform and social media',
    icon: Calendar,
    href: '/register-event'
  },
  {
    id: 'restaurants',
    title: 'Restaurants & Bars',
    description: 'Promote your restaurant or bar on our platform',
    icon: Utensils,
    href: '/register-restaurant'
  },
  {
    id: 'villas',
    title: 'Villa Rentals',
    description: 'List your villa rentals on our platform',
    icon: Building2,
    href: '/register'
  }
];

// Membership plans for event promotion
const eventMembershipPlans = [
  {
    id: 'basic',
    name: 'Basic Event Promo',
    price: 99,
    oneTime: true,
    features: [
      '2 Instagram stories per event (pre-event + day-of reminder)',
      'Pre-event flyer added to Events Highlight with a link to buy tickets'
    ],
    trial: false
  },
  {
    id: 'standard',
    name: 'Standard Event Promo',
    price: 149,
    features: [
      '2 Instagram stories per event (pre-event + day-of reminder)',
      'Pre-event flyer added to Events Highlight with a link to buy tickets',
      'Event featured on website with a direct booking link',
      'WhatsApp Events Group Blast with a link to buy tickets'
    ],
    trial: true
  },
  {
    id: 'premium',
    name: 'Premium Event Promo',
    price: 299,
    features: [
      'Maximum exposure for multiple events!',
      'Up to 4 events per month',
      'Instagram stories for each event with links to buy tickets',
      'Events added to the website Events List with direct booking links',
      'WhatsApp Events Group Blasts for each event'
    ],
    note: 'Up to 4 events per month max',
    trial: false
  }
];

// Membership plans for restaurant promotion
const restaurantMembershipPlans = [
  {
    id: 'basic',
    name: 'Basic Promo',
    price: 99,
    oneTime: true,
    features: [
      '1 Instagram story featuring your restaurant/bar',
      'Venue added to the "Eat & Drink" Highlight with a direct booking or reservation link'
    ],
    trial: false
  },
  {
    id: 'standard',
    name: 'Standard Promo',
    price: 149,
    features: [
      '1 Instagram story per month featuring your restaurant/bar',
      'Venue added to the "Eat & Drink" Highlight with a direct booking or reservation link',
      'Restaurant/bar listed on website with paid traffic and a direct booking or reservation link',
      'WhatsApp Food & Drinks Group Blast featuring your venue'
    ],
    trial: true
  },
  {
    id: 'premium',
    name: 'Premium Promo',
    price: 299,
    features: [
      'Maximum exposure for your restaurant or bar!',
      '4 Instagram stories per month featuring your venue',
      'Venue added to the "Eat & Drink" Highlight with a direct booking or reservation link',
      'Restaurant/bar listed on website with paid traffic and a direct booking or reservation link',
      'Unlimited WhatsApp Deals & Promotions Blasts',
      'Unlimited deals and promos added to the Deals & Promotions section of the website'
    ],
    trial: false
  }
];

export default function RegisterEvent() {
  const [step, setStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState('standard');
  const [registrationType, setRegistrationType] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });
  const [paymentDetails, setPaymentDetails] = useState({
    cardName: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPaymentDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePlanSelect = (planId: string) => {
    setSelectedPlan(planId);
  };

  const handleTypeSelect = (type: string) => {
    if (type === 'villas') {
      navigate('/register');
    } else if (type === 'restaurants') {
      setRegistrationType('restaurants');
      setStep(1);
    } else {
      setRegistrationType('events');
      setStep(1);
    }
  };

  const validateAccountInfo = () => {
    if (!formData.name || !formData.email || !formData.password || !formData.confirmPassword) {
      setError('Please fill in all required fields');
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }

    if (formData.password.length < 6) {
      setError('Password must be at least 6 characters');
      return false;
    }

    return true;
  };

  const validatePaymentDetails = () => {
    if (!paymentDetails.cardName || !paymentDetails.cardNumber || !paymentDetails.expiryDate || !paymentDetails.cvv) {
      setError('Please fill in all payment details');
      return false;
    }

    if (paymentDetails.cardNumber.replace(/\s/g, '').length !== 16) {
      setError('Card number must be 16 digits');
      return false;
    }

    if (paymentDetails.cvv.length < 3) {
      setError('CVV must be at least 3 digits');
      return false;
    }

    return true;
  };

  const handleContinueToAccountInfo = () => {
    setStep(2);
    window.scrollTo(0, 0);
  };

  const handleContinueToPayment = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (validateAccountInfo()) {
      setStep(3);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!validatePaymentDetails()) {
      setLoading(false);
      return;
    }

    // In a real app, this would be an API call to register the user and process payment
    // For demo purposes, we'll simulate a successful registration
    setTimeout(() => {
      // Store user info in localStorage (in a real app, you'd store a token)
      localStorage.setItem('user', JSON.stringify({ 
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        membershipPlan: selectedPlan,
        membershipType: registrationType
      }));
      setLoading(false);
      navigate('/');
    }, 1500);
  };

  const formatCardNumber = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCardNumber(e.target.value);
    setPaymentDetails(prev => ({
      ...prev,
      cardNumber: formattedValue
    }));
  };

  const renderStepIndicator = () => {
    return (
      <div className="mb-8">
        <div className="flex items-center justify-between">
          {[1, 2, 3].map((stepNumber) => (
            <div key={stepNumber} className="flex flex-col items-center">
              <div 
                className={`flex items-center justify-center w-10 h-10 rounded-full ${
                  step >= stepNumber ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'
                }`}
              >
                {stepNumber}
              </div>
              <div className="text-xs mt-2 text-gray-500">
                {stepNumber === 1 && 'Select Plan'}
                {stepNumber === 2 && 'Account Info'}
                {stepNumber === 3 && 'Payment'}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 h-2 bg-gray-200 rounded-full">
          <div 
            className="h-full bg-blue-600 rounded-full transition-all duration-300"
            style={{ width: `${((step - 1) / 3) * 100}%` }}
          ></div>
        </div>
      </div>
    );
  };

  const renderTypeSelection = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">Choose Registration Type</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {registrationTypes.map((type) => {
          const TypeIcon = type.icon;
          return (
            <div
              key={type.id}
              onClick={() => handleTypeSelect(type.id)}
              className="bg-white p-6 rounded-xl shadow-sm border-2 border-transparent hover:border-blue-600 hover:shadow-md transition-all flex flex-col items-center text-center cursor-pointer"
            >
              <TypeIcon className="h-16 w-16 text-blue-600 mb-4" />
              <h3 className="text-xl font-bold text-gray-900 mb-2">{type.title}</h3>
              <p className="text-gray-600 mb-4">{type.description}</p>
              <button className="mt-auto bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center">
                Select
                <ArrowRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderPlanSelection = () => {
    const plans = registrationType === 'restaurants' ? restaurantMembershipPlans : eventMembershipPlans;
    const title = registrationType === 'restaurants' ? 'Restaurant & Bar Promotion' : 'Event Promotion';
    
    return (
      <div className="space-y-6">
        <h3 className="text-xl font-bold text-gray-900 mb-4">{title} Plans</h3>
        <div className="flex flex-col md:flex-row md:items-stretch md:space-x-4 space-y-4 md:space-y-0">
          {plans.map((plan, index) => {
            const orderClass = 
              plan.id === 'standard' ? 'md:order-2' : 
              plan.id === 'basic' ? 'md:order-1' : 'md:order-3';
            
            const isStandard = plan.id === 'standard';
            const standardClass = isStandard ? 'md:-mt-4 md:mb-4 md:border-blue-500 md:shadow-lg' : '';
            
            return (
              <div 
                key={plan.id}
                onClick={() => handlePlanSelect(plan.id)}
                className={`border rounded-lg p-4 cursor-pointer transition-all flex-1 ${orderClass} ${standardClass} ${
                  selectedPlan === plan.id 
                    ? 'border-blue-500 bg-blue-50 ring-2 ring-blue-500' 
                    : 'border-gray-200 hover:border-blue-300'
                }`}
              >
                <div className="flex justify-between items-start mb-2">
                  <h4 className="text-lg font-semibold">{plan.name}</h4>
                  {selectedPlan === plan.id && (
                    <div className="bg-blue-500 text-white p-1 rounded-full">
                      <Check className="h-4 w-4" />
                    </div>
                  )}
                </div>
                {plan.trial && (
                  <div className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-xs font-bold inline-block mb-2">
                    FREE 1 MONTH TRIAL
                  </div>
                )}
                <div className="flex items-baseline">
                  <p className="text-2xl font-bold text-gray-900">${plan.price}</p>
                  {!plan.oneTime && <span className="text-sm font-normal text-gray-500">/month</span>}
                  {plan.trial && (
                    <span className="ml-1 text-sm font-medium text-green-600">after trial</span>
                  )}
                </div>
                {plan.note && (
                  <p className="text-sm font-medium text-blue-600 mb-3">{plan.note}</p>
                )}
                <ul className="mt-4 space-y-2">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-sm text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        <div className="flex items-center">
          <input
            id="terms"
            name="terms"
            type="checkbox"
            required
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
            I agree to the{' '}
            <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
              Privacy Policy
            </a>
          </label>
        </div>

        <div className="flex space-x-4">
          <button
            type="button"
            onClick={() => setStep(0)}
            className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Back
          </button>
          <button
            type="button"
            onClick={handleContinueToAccountInfo}
            className="flex-1 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Continue
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {step === 0 ? 'Create a New Account' : 
           step === 1 ? (registrationType === 'restaurants' ? 'Restaurant & Bar Promotion Registration' : 'Event Promotion Registration') : 
           step === 2 ? 'Create Your Account' : 
           'Payment Details'}
        </h2>
        {(step === 0 || step === 1) && (
          <p className="mt-2 text-center text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
              Sign in
            </Link>
          </p>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-4xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}

          {step > 0 && renderStepIndicator()}

          {step === 0 && renderTypeSelection()}
          {step === 1 && renderPlanSelection()}

          {step === 2 && (
            <form className="space-y-6" onSubmit={handleContinueToPayment}>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Full Name *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="John Doe"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="you@example.com"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  WhatsApp Phone Number
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Phone className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    autoComplete="tel"
                    value={formData.phone}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="+1234567890"
                  />
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Enter numbers only, optionally starting with +
                </p>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="••••••••"
                  />
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Must be at least 6 characters
                </p>
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                  Confirm Password *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="••••••••"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between space-x-4">
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="flex-1 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Continue to Payment
                </button>
              </div>
            </form>
          )}

          {step === 3 && (
            <>
              <div className="mb-6">
                <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
                  <div className="flex">
                    <div className="ml-3">
                      <p className="text-sm text-blue-700">
                        You've selected the <span className="font-semibold">{
                          registrationType === 'restaurants' 
                            ? restaurantMembershipPlans.find(p => p.id === selectedPlan)?.name 
                            : eventMembershipPlans.find(p => p.id === selectedPlan)?.name
                        }</span> plan
                      </p>
                      {selectedPlan === 'basic' ? (
                        <p className="text-sm text-gray-700 font-bold mt-1">
                          One-time payment of $99
                        </p>
                      ) : ((registrationType === 'events' && selectedPlan === 'standard') ||
                          (registrationType === 'restaurants' && selectedPlan === 'standard')) ? (
                        <p className="text-sm text-green-700 font-bold mt-1">
                          FREE for the first month, then $149/month
                        </p>
                      ) : (
                        <p className="text-sm text-gray-700 font-bold mt-1">
                          ${registrationType === 'restaurants' 
                            ? restaurantMembershipPlans.find(p => p.id === selectedPlan)?.price 
                            : eventMembershipPlans.find(p => p.id === selectedPlan)?.price}/month
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="cardName" className="block text-sm font-medium text-gray-700">
                    Name on Card *
                  </label>
                  <input
                    type="text"
                    id="cardName"
                    name="cardName"
                    value={paymentDetails.cardName}
                    onChange={handlePaymentChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="John Doe"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">
                    Card Number *
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <CreditCard className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="cardNumber"
                      name="cardNumber"
                      value={paymentDetails.cardNumber}
                      onChange={handleCardNumberChange}
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="4242 4242 4242 4242"
                      maxLength={19}
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">
                      Expiry Date *
                    </label>
                    <input
                      type="text"
                      id="expiryDate"
                      name="expiryDate"
                      value={paymentDetails.expiryDate}
                      onChange={handlePaymentChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="MM/YY"
                      maxLength={5}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="cvv" className="block text-sm font-medium text-gray-700">
                      CVV *
                    </label>
                    <input
                      type="text"
                      id="cvv"
                      name="cvv"
                      value={paymentDetails.cvv}
                      onChange={handlePaymentChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="123"
                      maxLength={4}
                      required
                    />
                  </div>
                </div>

                {((registrationType === 'events' && selectedPlan === 'standard') ||
                  (registrationType === 'restaurants' && selectedPlan === 'standard')) && (
                  <div className="bg-green-50 p-4 rounded-lg border border-green-200">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <Check className="h-5 w-5 text-green-500" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-green-700">
                          <span className="font-bold">FREE 1 MONTH TRIAL:</span> You won't be charged today. Your subscription will begin with a free trial, and your first payment of $149 will be processed 30 days will be processed 30 days from now.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-between space-x-4">
                  <button
                    type="button"
                    onClick={() => setStep(2)}
                    className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="flex-1 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {loading ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </span>
                    ) : (
                      <span className="flex items-center">
                        {selectedPlan === 'basic' ? 'Complete One-Time Payment' :
                         ((registrationType === 'events' && selectedPlan === 'standard') ||
                          (registrationType === 'restaurants' && selectedPlan === 'standard')) ? 'Start Free Trial' : 
                         'Complete Payment'}
                      </span>
                    )}
                  </button>
                </div>
              </form>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Secure Payment</span>
                  </div>
                </div>
                <div className="mt-4 flex justify-center">
                  <div className="flex space-x-2">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png" alt="Visa" className="h-8" />
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/1280px-Mastercard-logo.svg.png" alt="Mastercard" className="h-8" />
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/1200px-American_Express_logo_%282018%29.svg.png" alt="American Express" className="h-8" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}