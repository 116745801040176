import React from 'react';
import { X, Globe, MessageSquare, Mail } from 'lucide-react';

interface AgentPopupProps {
  agentName: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function AgentPopup({ agentName, isOpen, onClose }: AgentPopupProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="relative bg-white rounded-xl shadow-xl max-w-lg w-full mx-4 overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-900">{agentName}</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-6">
          <p className="text-gray-700 leading-relaxed mb-6">
            {agentName} stands as one of the premier real estate agencies in Ubud, with over 15 years of experience in selling high-end luxury real estate in Bali. Their deep understanding of the local market, combined with their commitment to excellence, has established them as the trusted choice for discerning property investors in Ubud's luxury real estate market.
          </p>
          <div className="flex space-x-4">
            <a 
              href="https://wa.me/6281353199090?text=Hey%20I'm%20interested%20in%20a%20listing%20I%20saw%20on%20thebaliexpert.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-green-600 hover:text-green-700 transition-colors"
            >
              <MessageSquare className="h-5 w-5 mr-2" />
              WhatsApp
            </a>
            <a 
              href="mailto:ubudlistings@gmail.com"
              className="flex items-center text-red-600 hover:text-red-700 transition-colors"
            >
              <Mail className="h-5 w-5 mr-2" />
              Email
            </a>
            <a 
              href="https://www.ubudhomes.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-600 hover:text-blue-700 transition-colors"
            >
              <Globe className="h-5 w-5 mr-2" />
              Website
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}