import React, { useState } from 'react';
import { MapPin, Clock, Users, ArrowRight, Star, Heart, Building2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import GuidePopup from '../components/GuidePopup';
import TourBookingPopup from '../components/TourBookingPopup';
import TourBookingGeneralPopup from '../components/TourBookingGeneralPopup';

const categories = [
  { name: 'All Events', count: 4 },
  { name: 'Adventure', count: 4 },
  { name: 'Nature', count: 4 }
];

const upcomingEvents = [
  {
    id: "nyepi-day",
    title: "Nyepi Day",
    date: "March 29, 2025",
    image: "https://i.imghippo.com/files/sWpU8940oiQ.jpg",
    description: "Experience the unique Day of Silence where the entire island comes to a complete standstill.",
    category: "Cultural",
    duration: "24 hours"
  },
  {
    id: "galungan",
    title: "Galungan",
    date: "March 11, 2025",
    image: "https://i.imghippo.com/files/ZZS3144r.jpg",
    description: "One of Bali's most important religious festivals celebrating the victory of dharma over adharma.",
    category: "Cultural",
    duration: "10 days"
  },
  {
    id: "bali-spirit-festival",
    title: "Bali Spirit Festival",
    date: "March 29 - April 6, 2025",
    image: "https://images.unsplash.com/photo-1506126613408-eca07ce68773?auto=format&fit=crop&q=80",
    description: "Join the world's most inspiring celebration of yoga, dance, and music in the heart of Bali.",
    category: "Festival",
    duration: "9 days"
  }
];

export default function Events() {
  const [selectedCategory, setSelectedCategory] = useState('All Events');
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const [isGuidePopupOpen, setIsGuidePopupOpen] = useState(false);
  const navigate = useNavigate();
  const heroImageUrl = upcomingEvents[0].image;
  const thumbnailUrl = `${heroImageUrl}?w=20&q=10`;

  const handleEventClick = (eventId: string) => {
    window.scrollTo(0, 0);
    navigate(`/events/${eventId}`);
  };

  const filteredEvents = selectedCategory === 'All Events'
    ? upcomingEvents
    : upcomingEvents.filter(event => event.category === selectedCategory);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[70vh]">
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: !heroImageLoaded ? 'blur(20px)' : 'none',
              transition: 'filter 0.3s ease-out'
            }}
          />
          <img
            src={heroImageUrl}
            alt={upcomingEvents[0].title}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              heroImageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setHeroImageLoaded(true)}
            loading="eager"
            fetchpriority="high"
            decoding="async"
            sizes="100vw"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex flex-col justify-end h-full pb-16">
            <div className="max-w-3xl">
              <div className="flex items-center space-x-4 mb-4">
                <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                  {upcomingEvents[0].category}
                </span>
                <span className="text-blue-400 font-medium flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {upcomingEvents[0].date}
                </span>
              </div>
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">
                {upcomingEvents[0].title}
              </h1>
              <div className="flex items-center space-x-6 text-gray-300 mb-6">
                <div className="flex items-center">
                  <Clock className="h-5 w-5 mr-2" />
                  <span>{upcomingEvents[0].duration}</span>
                </div>
              </div>
              <p className="text-xl text-gray-200 mb-8">
                {upcomingEvents[0].description}
              </p>
              <button 
                onClick={() => handleEventClick(upcomingEvents[0].id)}
                className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors inline-flex items-center"
              >
                Learn More
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Events Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex justify-between items-center mb-8">
          <div className="flex gap-4">
            {categories.map((category) => (
              <button
                key={category.name}
                onClick={() => setSelectedCategory(category.name)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedCategory === category.name
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {category.name} ({category.count})
              </button>
            ))}
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {filteredEvents.map((event) => (
            <div
              key={event.id}
              onClick={() => handleEventClick(event.id)}
              className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300 cursor-pointer"
            >
              <div className="relative h-48">
                <img
                  src={event.image}
                  alt={event.title}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                  loading="lazy"
                  decoding="async"
                />
                <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                  {event.category}
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center text-gray-500 text-sm mb-2">
                  <Calendar className="h-4 w-4 mr-1" />
                  {event.date}
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{event.title}</h3>
                <p className="text-gray-600">{event.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Guide Popup */}
      <GuidePopup 
        isOpen={isGuidePopupOpen}
        onClose={() => setIsGuidePopupOpen(false)}
      />
    </div>
  );
}