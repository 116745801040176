import React, { useState } from 'react';
import { MapPin, Bed, Bath, Square, Heart, Building2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AgentPopup from '../components/AgentPopup';
import ImageCarousel from '../components/ImageCarousel';
import ContactPopup from '../components/ContactPopup';

// This can be moved to a separate data file or fetched from an API
const villas = [
  {
    id: 1,
    title: "Sayan Ridge Boutique Hotel",
    location: "Ubud",
    price: "$1,850,000 USD",
    beds: 6,
    baths: 6,
    area: "950 m²",
    featured: true,
    images: [
      "https://i.imghippo.com/files/WMPS8682z.jpg",
      "https://i.imghippo.com/files/qrD8456RvU.jpeg",
      "https://i.imghippo.com/files/Fwue7238KGs.jpeg",
      "https://i.imghippo.com/files/wnaP8226QHg.jpeg"
    ],
    description: "Newly built luxury boutique hotel in Ubud's most prestigious area. Features 4 private villas, pools, and stunning views.",
    listedBy: "Ubud Homes"
  }
];

export default function Villas() {
  const [isAgentPopupOpen, setIsAgentPopupOpen] = useState(false);
  const [isContactPopupOpen, setIsContactPopupOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleVillaClick = () => {
    window.scrollTo(0, 0);
    navigate('/boutique-hotel');
  };

  const handleAgentClick = (e: React.MouseEvent, agentName: string) => {
    e.stopPropagation();
    setSelectedAgent(agentName);
    setIsAgentPopupOpen(true);
  };

  const handleContactClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsContactPopupOpen(true);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative w-full">
        <div className="relative w-full pb-[100%] md:pb-[56.25%]">
          {/* Image Carousel */}
          <div className="absolute inset-0 z-0">
            <ImageCarousel images={villas[0].images} />
          </div>
          
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-10"></div>
          
          {/* Content */}
          <div className="absolute inset-0 flex items-end z-20">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16 pt-20 md:pt-0">
              <div className="max-w-3xl">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-600 text-white mb-2 md:mb-4">
                  Featured Property
                </span>
                <h1 className="text-3xl md:text-5xl font-bold text-white mb-2 md:mb-4">
                  Sayan Ridge Boutique Hotel
                </h1>
                <div className="flex flex-wrap items-center gap-2 md:gap-4 text-gray-300 mb-3 md:mb-6">
                  <div className="flex items-center">
                    <MapPin className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">Ubud</span>
                  </div>
                  <div className="flex items-center">
                    <Bed className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">6 Bedrooms</span>
                  </div>
                  <div className="flex items-center">
                    <Bath className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">6 Bathrooms</span>
                  </div>
                  <div className="flex items-center">
                    <Square className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">950 m²</span>
                  </div>
                </div>
                <p className="text-base md:text-xl text-gray-200 mb-4 md:mb-8 line-clamp-3 md:line-clamp-none">
                  Newly built luxury boutique hotel in Ubud's most prestigious area. Features 4 private villas, pools, and stunning views.
                </p>
                <div className="mb-3 md:mb-6">
                  <span className="text-2xl md:text-3xl font-bold text-white">$1,850,000 USD</span>
                  <div className="mt-1 md:mt-2 text-gray-300 text-sm md:text-base">
                    <span className="font-semibold">Listed by: </span>
                    <button 
                      onClick={(e) => handleAgentClick(e, "Ubud Homes")}
                      className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                    >
                      Ubud Homes
                    </button>
                  </div>
                </div>
                <div className="flex flex-wrap gap-3 md:gap-4">
                  <button 
                    onClick={handleVillaClick}
                    className="bg-blue-600 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-700 transition-colors"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filters Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <div className="flex gap-4">
            <select className="rounded-lg border-gray-300 text-gray-700 text-sm focus:ring-blue-500 focus:border-blue-500">
              <option>Price Range</option>
              <option>$500k - $750k</option>
              <option>$750k - $1M</option>
              <option>$1M+</option>
            </select>
            <select className="rounded-lg border-gray-300 text-gray-700 text-sm focus:ring-blue-500 focus:border-blue-500">
              <option>Bedrooms</option>
              <option>2+</option>
              <option>3+</option>
              <option>4+</option>
            </select>
          </div>
          <div className="flex gap-4">
            <select className="rounded-lg border-gray-300 text-gray-700 text-sm focus:ring-blue-500 focus:border-blue-500">
              <option>Sort by: Latest</option>
              <option>Price: Low to High</option>
              <option>Price: High to Low</option>
            </select>
          </div>
        </div>
      </div>

      {/* Villas Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">Villas For Sale in Ubud</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {villas.map((villa) => (
            <div 
              key={villa.id} 
              onClick={handleVillaClick}
              className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-[1.02] duration-300 cursor-pointer"
            >
              <div className="relative">
                <img
                  src={villa.images[0]}
                  alt={villa.title}
                  className="w-full h-64 object-cover"
                  loading="lazy"
                  decoding="async"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                />
                <button 
                  className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Heart className="h-5 w-5 text-gray-600" />
                </button>
                {villa.featured && (
                  <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                    Featured
                  </div>
                )}
              </div>
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">{villa.title}</h3>
                    <div className="flex items-center text-gray-600">
                      <MapPin className="h-4 w-4 mr-1" />
                      <span>{villa.location}</span>
                    </div>
                  </div>
                  <span className="text-2xl font-bold text-blue-600">{villa.price}</span>
                </div>
                <p className="text-gray-600 mb-4">{villa.description}</p>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4 text-gray-600">
                    <div className="flex items-center">
                      <Bed className="h-4 w-4 mr-1" />
                      <span>{villa.beds}</span>
                    </div>
                    <div className="flex items-center">
                      <Bath className="h-4 w-4 mr-1" />
                      <span>{villa.baths}</span>
                    </div>
                    <div className="flex items-center">
                      <Square className="h-4 w-4 mr-1" />
                      <span>{villa.area}</span>
                    </div>
                  </div>
                </div>
                <div className="mt-4 text-gray-600">
                  <span className="font-semibold">Listed by: </span>
                  <button 
                    onClick={(e) => handleAgentClick(e, villa.listedBy)}
                    className="text-blue-600 hover:text-blue-700 hover:underline transition-colors"
                  >
                    {villa.listedBy}
                  </button>
                </div>
                <button className="mt-6 w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Looking for a Property in Ubud?</h2>
          <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
            We have more properties coming soon. Contact us to discuss your requirements and we'll help you find your dream property in Ubud.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <a 
              href="https://wa.me/6281574054569?text=Hello%2C%20I%20am%20interested%20in%20properties%20in%20Ubud"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
            >
              Contact Us
            </a>
            <a 
              href="/villas-for-sale"
              className="bg-blue-700 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-800 transition-colors"
            >
              View All Properties
            </a>
          </div>
        </div>
      </div>

      {/* Agent Popup */}
      {selectedAgent && (
        <AgentPopup 
          agentName={selectedAgent}
          isOpen={isAgentPopupOpen}
          onClose={() => {
            setIsAgentPopupOpen(false);
            setSelectedAgent(null);
          }}
        />
      )}

      {/* Contact Popup */}
      <ContactPopup 
        agentName="Ubud Homes"
        propertyTitle="Sayan Ridge Boutique Hotel"
        isOpen={isContactPopupOpen}
        onClose={() => setIsContactPopupOpen(false)}
      />
    </div>
  );
}