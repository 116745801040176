import React from 'react';
import { X, MessageSquare } from 'lucide-react';

interface VillaWayanPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function VillaWayanPopup({ isOpen, onClose }: VillaWayanPopupProps) {
  if (!isOpen) return null;

  const handleWhatsApp = () => {
    window.open('https://wa.me/6281574054569?text=Hello%20I%20found%20Villa%20Wayan%20on%20thebaliexpert.com%20and%20am%20interested%20in%20booking.', '_blank');
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-900">Contact Villa Wayan</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-6">
          <p className="text-gray-600 mb-6">
            Contact Villa Wayan directly to discuss your booking:
          </p>
          <button 
            onClick={handleWhatsApp}
            className="w-full bg-green-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center"
          >
            <MessageSquare className="mr-2 h-5 w-5" />
            Chat on WhatsApp
          </button>
        </div>
      </div>
    </div>
  );
}