interface ListingAgent {
  name: string;
  whatsapp: string;
  email: string;
  website?: string;
  description: string;
}

export const listingAgents: Record<string, ListingAgent> = {
  "Ubud Homes": {
    name: "Ubud Homes",
    whatsapp: "6281353199090",
    email: "ubudlistings@gmail.com",
    website: "https://www.ubudhomes.com",
    description: "Ubud Homes stands as one of the premier real estate agencies in Ubud, with over 15 years of experience in selling high-end luxury real estate in Bali. Their deep understanding of the local market, combined with their commitment to excellence, has established them as the trusted choice for discerning property investors in Ubud's luxury real estate market."
  }
  // Add more agents here as needed
};

export const getListingAgent = (name: string): ListingAgent | undefined => {
  return listingAgents[name];
};