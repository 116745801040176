import React, { useState, useEffect } from 'react';
import { MapPin, Bed, Bath, Square, Heart, Building2, Search, Filter, DollarSign, ArrowUpDown, Grid3X3, List } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AgentPopup from '../components/AgentPopup';
import ContactPopup from '../components/ContactPopup';

// Property data
const allProperties = [
  {
    id: 'property-1',
    title: "Sayan Ridge Boutique Hotel",
    location: "Ubud",
    price: "$1,850,000",
    beds: 6,
    baths: 6,
    area: "950 m²",
    featured: true,
    image: "https://i.imghippo.com/files/WMPS8682z.jpg",
    description: "Newly built luxury boutique hotel in Ubud's most prestigious area. Features 4 private villas, pools, and stunning views.",
    listedBy: "Ubud Homes",
    type: "Hotel",
    landSize: "950 m²",
    buildSize: "795 m²",
    leasehold: "18 years",
    roi: "15%+",
    active: true
  },
  {
    id: 'property-2',
    title: "Luxury Villa with Rice Field Views",
    location: "Ubud",
    price: "$750,000",
    beds: 4,
    baths: 4,
    area: "500 m²",
    featured: false,
    image: "https://images.unsplash.com/photo-1604999333679-b86d54738315?auto=format&fit=crop&q=80",
    description: "Beautiful villa surrounded by lush rice fields, featuring a private pool and modern Balinese design.",
    listedBy: "Ubud Homes",
    type: "Villa",
    landSize: "500 m²",
    buildSize: "350 m²",
    leasehold: "25 years",
    roi: "12%",
    active: false
  },
  {
    id: 'property-3',
    title: "Beachfront Estate with Ocean Views",
    location: "Uluwatu",
    price: "$2,500,000",
    beds: 5,
    baths: 6,
    area: "1200 m²",
    featured: false,
    image: "https://images.unsplash.com/photo-1577717903315-1691ae25ab3f?auto=format&fit=crop&q=80",
    description: "Stunning beachfront property with panoramic ocean views, infinity pool, and direct beach access.",
    listedBy: "Bali Luxury Estates",
    type: "Villa",
    landSize: "1200 m²",
    buildSize: "800 m²",
    leasehold: "30 years",
    roi: "10%",
    active: false
  },
  {
    id: 'property-4',
    title: "Modern Villa in Canggu",
    location: "Canggu",
    price: "$980,000",
    beds: 3,
    baths: 3,
    area: "350 m²",
    featured: false,
    image: "https://images.unsplash.com/photo-1537953773345-d172ccf13cf1?auto=format&fit=crop&q=80",
    description: "Contemporary villa in the heart of Canggu, walking distance to the beach and popular cafes.",
    listedBy: "Canggu Property",
    type: "Villa",
    landSize: "350 m²",
    buildSize: "250 m²",
    leasehold: "22 years",
    roi: "8%",
    active: false
  },
  {
    id: 'property-5',
    title: "Tropical Paradise Villa",
    location: "Ubud",
    price: "$1,200,000",
    beds: 4,
    baths: 4,
    area: "800 m²",
    featured: false,
    image: "https://images.unsplash.com/photo-1582610116397-edb318620f90?auto=format&fit=crop&q=80",
    description: "Luxurious villa nestled in a tropical paradise with private pool and lush gardens.",
    listedBy: "Ubud Homes",
    type: "Villa",
    landSize: "800 m²",
    buildSize: "400 m²",
    leasehold: "25 years",
    roi: "9%",
    active: false
  },
  {
    id: 'property-6',
    title: "Cliff-Edge Luxury Villa",
    location: "Uluwatu",
    price: "$3,200,000",
    beds: 6,
    baths: 7,
    area: "1500 m²",
    featured: false,
    image: "https://images.unsplash.com/photo-1540541338287-41700207dee6?auto=format&fit=crop&q=80",
    description: "Spectacular cliff-edge villa with uninterrupted ocean views and world-class amenities.",
    listedBy: "Bali Luxury Estates",
    type: "Villa",
    landSize: "1500 m²",
    buildSize: "900 m²",
    leasehold: "30 years",
    roi: "7%",
    active: false
  }
];

const locations = [
  { name: 'All Locations', value: '' },
  { name: 'Ubud', value: 'Ubud' },
  { name: 'Uluwatu', value: 'Uluwatu' },
  { name: 'Canggu', value: 'Canggu' },
  { name: 'Seminyak', value: 'Seminyak' },
  { name: 'Nusa Dua', value: 'Nusa Dua' }
];

const propertyTypes = [
  { name: 'All Types', value: '' },
  { name: 'Villa', value: 'Villa' },
  { name: 'Hotel', value: 'Hotel' },
  { name: 'Land', value: 'Land' },
  { name: 'Commercial', value: 'Commercial' }
];

const priceRanges = [
  { name: 'Any Price', value: '' },
  { name: 'Under $500k', value: '0-500000' },
  { name: '$500k - $1M', value: '500000-1000000' },
  { name: '$1M - $2M', value: '1000000-2000000' },
  { name: '$2M - $5M', value: '2000000-5000000' },
  { name: '$5M+', value: '5000000-999999999' }
];

const bedroomOptions = [
  { name: 'Bedrooms', value: '' },
  { name: '1 Bedroom', value: '1' },
  { name: '2 Bedrooms', value: '2' },
  { name: '3 Bedrooms', value: '3' },
  { name: '4+ Bedrooms', value: '4' }
];

export default function VillasForSale() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isAgentPopupOpen, setIsAgentPopupOpen] = useState(false);
  const [isContactPopupOpen, setIsContactPopupOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  
  // Search filters
  const [location, setLocation] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [sortBy, setSortBy] = useState('featured');
  
  // Filtered properties
  const [filteredProperties, setFilteredProperties] = useState(allProperties);
  
  const navigate = useNavigate();
  const imageUrl = "https://i.imghippo.com/files/WMPS8682z.jpg";
  const thumbnailUrl = `${imageUrl}?w=20&q=10`;

  // Featured property
  const featuredProperty = allProperties[0];

  useEffect(() => {
    // Filter properties based on selected filters
    let filtered = [...allProperties];
    
    if (location) {
      filtered = filtered.filter(property => property.location === location);
    }
    
    if (propertyType) {
      filtered = filtered.filter(property => property.type === propertyType);
    }
    
    if (priceRange) {
      const [min, max] = priceRange.split('-').map(Number);
      filtered = filtered.filter(property => {
        const price = Number(property.price.replace(/[^0-9.-]+/g, ''));
        return price >= min && price <= max;
      });
    }
    
    if (bedrooms) {
      const minBeds = Number(bedrooms);
      if (minBeds === 4) {
        // For 4+ bedrooms
        filtered = filtered.filter(property => property.beds >= minBeds);
      } else {
        // For exact bedroom count
        filtered = filtered.filter(property => property.beds === minBeds);
      }
    }
    
    // Sort properties
    if (sortBy === 'price-asc') {
      filtered.sort((a, b) => {
        const priceA = Number(a.price.replace(/[^0-9.-]+/g, ''));
        const priceB = Number(b.price.replace(/[^0-9.-]+/g, ''));
        return priceA - priceB;
      });
    } else if (sortBy === 'price-desc') {
      filtered.sort((a, b) => {
        const priceA = Number(a.price.replace(/[^0-9.-]+/g, ''));
        const priceB = Number(b.price.replace(/[^0-9.-]+/g, ''));
        return priceB - priceA;
      });
    } else if (sortBy === 'newest') {
      // In a real app, this would sort by date added
      // For now, we'll just use the original order
    } else {
      // Default: featured first
      filtered.sort((a, b) => (b.featured ? 1 : 0) - (a.featured ? 1 : 0));
    }
    
    setFilteredProperties(filtered);
  }, [location, propertyType, priceRange, bedrooms, sortBy]);

  const handleViewProperty = (propertyId: string) => {
    // Only navigate to the boutique hotel page for the active property
    if (propertyId === 'property-1') {
      window.scrollTo(0, 0);
      navigate('/boutique-hotel');
    }
  };

  const handleAgentClick = (e: React.MouseEvent, agentName: string) => {
    e.stopPropagation();
    setSelectedAgent(agentName);
    setIsAgentPopupOpen(true);
  };

  const handleContactClick = (e: React.MouseEvent, propertyTitle: string) => {
    e.stopPropagation();
    setSelectedProperty(propertyTitle);
    setIsContactPopupOpen(true);
  };

  const resetFilters = () => {
    setLocation('');
    setPropertyType('');
    setPriceRange('');
    setBedrooms('');
    setSortBy('featured');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Featured Property Hero Section */}
      <div className="relative w-full">
        <div className="relative w-full pb-[100%] md:pb-[56.25%]">
          <div className="absolute inset-0 z-0">
            <div 
              className="absolute inset-0"
              style={{
                backgroundImage: `url(${thumbnailUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: !imageLoaded ? 'blur(20px)' : 'none',
                transition: 'filter 0.3s ease-out'
              }}
            />
            
            <img
              src={imageUrl}
              alt={featuredProperty.title}
              className={`w-full h-full object-cover transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setImageLoaded(true)}
              loading="eager"
              fetchpriority="high"
              decoding="async"
              sizes="100vw"
            />
          </div>
          
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-10"></div>

          <div className="absolute inset-0 flex items-end z-20">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16 pt-20 md:pt-0">
              <div className="max-w-3xl">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-600 text-white mb-2 md:mb-4">
                  Featured Property
                </span>
                <h1 className="text-3xl md:text-5xl font-bold text-white mb-2 md:mb-4">
                  {featuredProperty.title}
                </h1>
                <div className="flex flex-wrap items-center gap-2 md:gap-4 text-gray-300 mb-3 md:mb-6">
                  <div className="flex items-center">
                    <MapPin className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.location}</span>
                  </div>
                  <div className="flex items-center">
                    <Bed className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.beds} Beds</span>
                  </div>
                  <div className="flex items-center">
                    <Bath className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.baths} Baths</span>
                  </div>
                  <div className="flex items-center">
                    <Square className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.area}</span>
                  </div>
                </div>
                <p className="text-base md:text-xl text-gray-200 mb-4 md:mb-8 line-clamp-3 md:line-clamp-none">
                  {featuredProperty.description}
                </p>
                <div className="mb-3 md:mb-6">
                  <span className="text-2xl md:text-3xl font-bold text-white">{featuredProperty.price} USD</span>
                  <div className="mt-1 md:mt-2 text-gray-300 text-sm md:text-base">
                    <span className="font-semibold">Listed by: </span>
                    <button 
                      onClick={(e) => handleAgentClick(e, featuredProperty.listedBy)}
                      className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                    >
                      {featuredProperty.listedBy}
                    </button>
                  </div>
                </div>
                <div className="flex flex-wrap gap-3 md:gap-4">
                  <a 
                    href="/boutique-hotel"
                    className="bg-blue-600 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-700 transition-colors"
                  >
                    View Details
                  </a>
                  <button
                    onClick={(e) => handleContactClick(e, featuredProperty.title)}
                    className="bg-white text-blue-600 px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-gray-100 transition-colors"
                  >
                    Contact Agent
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Search and Filter Section */}
      <div className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Location */}
            <div className="flex-1">
              <select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="w-full rounded-lg border-gray-300 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
              >
                {locations.map((option) => (
                  <option key={option.value} value={option.value}>{option.name}</option>
                ))}
              </select>
            </div>
            
            {/* Property Type */}
            <div className="flex-1">
              <select
                value={propertyType}
                onChange={(e) => setPropertyType(e.target.value)}
                className="w-full rounded-lg border-gray-300 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
              >
                {propertyTypes.map((option) => (
                  <option key={option.value} value={option.value}>{option.name}</option>
                ))}
              </select>
            </div>
            
            {/* Price Range */}
            <div className="flex-1">
              <select
                value={priceRange}
                onChange={(e) => setPriceRange(e.target.value)}
                className="w-full rounded-lg border-gray-300 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
              >
                {priceRanges.map((option) => (
                  <option key={option.value} value={option.value}>{option.name}</option>
                ))}
              </select>
            </div>
            
            {/* Bedrooms */}
            <div className="flex-1">
              <select
                value={bedrooms}
                onChange={(e) => setBedrooms(e.target.value)}
                className="w-full rounded-lg border-gray-300 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
              >
                {bedroomOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.name}</option>
                ))}
              </select>
            </div>
            
            {/* Search Button */}
            <div>
              <button 
                className="w-full bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center"
              >
                <Search className="h-5 w-5 mr-2" />
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Properties Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Results Header */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">
              {filteredProperties.length} Properties for Sale
              {location && ` in ${location}`}
            </h2>
            {(location || propertyType || priceRange || bedrooms !== '') && (
              <button 
                onClick={resetFilters}
                className="text-blue-600 hover:text-blue-700 text-sm font-medium mt-1 flex items-center"
              >
                Clear all filters
              </button>
            )}
          </div>
          
          <div className="flex items-center space-x-4 mt-4 md:mt-0">
            {/* Sort Options */}
            <div className="flex items-center">
              <ArrowUpDown className="h-4 w-4 text-gray-500 mr-2" />
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="rounded-lg border-gray-300 text-gray-700 text-sm focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="featured">Featured</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
                <option value="newest">Newest</option>
              </select>
            </div>
            
            {/* View Options */}
            <div className="flex items-center space-x-2">
              <button 
                onClick={() => setViewMode('grid')}
                className={`p-1.5 rounded ${viewMode === 'grid' ? 'bg-blue-100 text-blue-600' : 'text-gray-500 hover:bg-gray-100'}`}
              >
                <Grid3X3 className="h-5 w-5" />
              </button>
              <button 
                onClick={() => setViewMode('list')}
                className={`p-1.5 rounded ${viewMode === 'list' ? 'bg-blue-100 text-blue-600' : 'text-gray-500 hover:bg-gray-100'}`}
              >
                <List className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Properties Grid/List */}
        {filteredProperties.length > 0 ? (
          viewMode === 'grid' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {filteredProperties.map((property) => (
                <div 
                  key={property.id} 
                  onClick={() => property.active ? handleViewProperty(property.id) : null}
                  className={`bg-white rounded-xl shadow-md overflow-hidden transition-transform ${
                    property.active ? 'hover:scale-[1.02] cursor-pointer' : 'opacity-90'
                  }`}
                >
                  <div className="relative">
                    <img
                      src={property.image}
                      alt={property.title}
                      className={`w-full h-64 object-cover ${!property.active && 'filter brightness-90'}`}
                      loading="lazy"
                      decoding="async"
                    />
                    <button 
                      className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Heart className="h-5 w-5 text-gray-600" />
                    </button>
                    {property.featured && (
                      <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                        Featured
                      </div>
                    )}
                    {!property.active && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="absolute inset-0 bg-black/30 backdrop-blur-[2px]"></div>
                        <div className="relative px-8 py-3 border-2 border-white/50 bg-blue-600/40 backdrop-blur-sm text-white text-xl font-semibold tracking-wider uppercase z-10">
                          Coming Soon
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="p-6">
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-xl font-bold text-gray-900 mb-2">{property.title}</h3>
                        <div className="flex items-center text-gray-600">
                          <MapPin className="h-4 w-4 mr-1" />
                          <span>{property.location}</span>
                        </div>
                      </div>
                      <span className="text-2xl font-bold text-blue-600">{property.price}</span>
                    </div>
                    <p className="text-gray-600 mb-4 line-clamp-2">{property.description}</p>
                    <div className="flex justify-between items-center">
                      <div className="flex space-x-4 text-gray-600">
                        <div className="flex items-center">
                          <Bed className="h-4 w-4 mr-1" />
                          <span>{property.beds}</span>
                        </div>
                        <div className="flex items-center">
                          <Bath className="h-4 w-4 mr-1" />
                          <span>{property.baths}</span>
                        </div>
                        <div className="flex items-center">
                          <Square className="h-4 w-4 mr-1" />
                          <span>{property.area}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-gray-600">
                      <span className="font-semibold">Listed by: </span>
                      {property.active ? (
                        <button 
                          onClick={(e) => handleAgentClick(e, property.listedBy)}
                          className="text-blue-600 hover:text-blue-700 hover:underline transition-colors"
                        >
                          {property.listedBy}
                        </button>
                      ) : (
                        <span className="text-gray-500">Coming Soon</span>
                      )}
                    </div>
                    <div className="mt-6 flex space-x-3">
                      {property.active ? (
                        <>
                          <button className="flex-1 bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
                            View Details
                          </button>
                          <button 
                            onClick={(e) => handleContactClick(e, property.title)}
                            className="flex-1 bg-white border border-blue-600 text-blue-600 px-4 py-2 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
                          >
                            Contact Agent
                          </button>
                        </>
                      ) : (
                        <button className="flex-1 bg-gray-200 text-gray-600 px-4 py-2 rounded-lg font-semibold cursor-default">
                          Coming Soon
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="space-y-6">
              {filteredProperties.map((property) => (
                <div 
                  key={property.id} 
                  onClick={() => property.active ? handleViewProperty(property.id) : null}
                  className={`bg-white rounded-xl shadow-md overflow-hidden transition-all ${
                    property.active ? 'hover:shadow-lg cursor-pointer' : 'opacity-90'
                  }`}
                >
                  <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/3 relative">
                      <img
                        src={property.image}
                        alt={property.title}
                        className={`w-full h-64 md:h-full object-cover ${!property.active && 'filter brightness-90'}`}
                        loading="lazy"
                        decoding="async"
                      />
                      <button 
                        className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Heart className="h-5 w-5 text-gray-600" />
                      </button>
                      {property.featured && (
                        <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                          Featured
                        </div>
                      )}
                      {!property.active && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="absolute inset-0 bg-black/30 backdrop-blur-[2px]"></div>
                          <div className="relative px-8 py-3 border-2 border-white/50 bg-blue-600/40 backdrop-blur-sm text-white text-xl font-semibold tracking-wider uppercase z-10">
                            Coming Soon
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="md:w-2/3 p-6">
                      <div className="flex justify-between items-start mb-4">
                        <div>
                          <h3 className="text-xl font-bold text-gray-900 mb-2">{property.title}</h3>
                          <div className="flex items-center text-gray-600 mb-2">
                            <MapPin className="h-4 w-4 mr-1" />
                            <span>{property.location}</span>
                          </div>
                        </div>
                        <span className="text-2xl font-bold text-blue-600">{property.price}</span>
                      </div>
                      <p className="text-gray-600 mb-4">{property.description}</p>
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
                        <div className="bg-gray-50 p-3 rounded-lg">
                          <div className="text-gray-500 text-sm">Bedrooms</div>
                          <div className="font-semibold flex items-center">
                            <Bed className="h-4 w-4 mr-1 text-blue-600" />
                            {property.beds}
                          </div>
                        </div>
                        <div className="bg-gray-50 p-3 rounded-lg">
                          <div className="text-gray-500 text-sm">Bathrooms</div>
                          <div className="font-semibold flex items-center">
                            <Bath className="h-4 w-4 mr-1 text-blue-600" />
                            {property.baths}
                          </div>
                        </div>
                        <div className="bg-gray-50 p-3 rounded-lg">
                          <div className="text-gray-500 text-sm">Area</div>
                          <div className="font-semibold flex items-center">
                            <Square className="h-4 w-4 mr-1 text-blue-600" />
                            {property.area}
                          </div>
                        </div>
                        <div className="bg-gray-50 p-3 rounded-lg">
                          <div className="text-gray-500 text-sm">Type</div>
                          <div className="font-semibold flex items-center">
                            <Building2 className="h-4 w-4 mr-1 text-blue-600" />
                            {property.type}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="text-gray-600">
                          <span className="font-semibold">Listed by: </span>
                          {property.active ? (
                            <button 
                              onClick={(e) => handleAgentClick(e, property.listedBy)}
                              className="text-blue-600 hover:text-blue-700 hover:underline transition-colors"
                            >
                              {property.listedBy}
                            </button>
                          ) : (
                            <span className="text-gray-500">Coming Soon</span>
                          )}
                        </div>
                        <div className="flex space-x-3">
                          {property.active ? (
                            <>
                              <button className="bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
                                View Details
                              </button>
                              <button 
                                onClick={(e) => handleContactClick(e, property.title)}
                                className="bg-white border border-blue-600 text-blue-600 px-4 py-2 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
                              >
                                Contact Agent
                              </button>
                            </>
                          ) : (
                            <button className="bg-gray-200 text-gray-600 px-4 py-2 rounded-lg font-semibold cursor-default">
                              Coming Soon
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          <div className="text-center py-12 bg-white rounded-xl shadow-sm">
            <Building2 className="h-16 w-16 text-gray-300 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-gray-700 mb-2">No properties match your search criteria</h3>
            <p className="text-gray-500 mb-6">Try adjusting your filters or contact us for assistance</p>
            <button 
              onClick={resetFilters}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
            >
              Clear filters
            </button>
          </div>
        )}
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600 py-12 md:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-3 md:mb-4">Looking for a Property in Bali?</h2>
          <p className="text-base md:text-xl text-blue-100 mb-6 md:mb-8 max-w-3xl mx-auto">
            We have more properties coming soon. Contact us to discuss your requirements and we'll help you find your dream property in Bali.
          </p>
          <div className="flex flex-wrap justify-center gap-3 md:gap-4">
            <a 
              href="https://wa.me/6281574054569?text=Hello%2C%20I%20am%20interested%20in%20properties%20in%20Bali"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-blue-600 px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-gray-100 transition-colors"
            >
              Contact Us
            </a>
            <a 
              href="/villas"
              className="bg-blue-700 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-800 transition-colors"
            >
              View All Properties
            </a>
          </div>
        </div>
      </div>

      {/* Agent Popup */}
      {selectedAgent && (
        <AgentPopup 
          agentName={selectedAgent}
          isOpen={isAgentPopupOpen}
          onClose={() => {
            setIsAgentPopupOpen(false);
            setSelectedAgent(null);
          }}
        />
      )}

      {/* Contact Popup */}
      {selectedProperty && (
        <ContactPopup 
          agentName="Ubud Homes"
          propertyTitle={selectedProperty}
          isOpen={isContactPopupOpen}
          onClose={() => {
            setIsContactPopupOpen(false);
            setSelectedProperty(null);
          }}
        />
      )}
    </div>
  );
}