import React, { useState, useEffect } from 'react';
import { Megaphone, Building2, Globe } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function BusinessLanding() {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const handleNavigation = (path: string) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  const handleWhatsAppChat = () => {
    const message = encodeURIComponent("Hello, I'm interested in Advertising services.");
    window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
  };

  return (
    <div className="min-h-screen bg-white flex items-start md:items-center">
      <div className="max-w-5xl w-full mx-auto px-4 pt-8 md:pt-12 md:py-12 flex flex-col gap-6 md:gap-16 items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 w-full">
          {/* Villa Management Section */}
          <div 
            onClick={() => handleNavigation('/property-management')}
            className="w-full flex flex-col items-center text-center p-6 md:p-8 cursor-pointer hover:bg-gray-100 transition-colors border-2 border-transparent hover:border-blue-100 hover:shadow-lg rounded-xl"
          >
            <div className="bg-blue-50 p-4 md:p-5 rounded-full mb-4 md:mb-5">
              <Building2 className="h-10 w-10 md:h-12 md:w-12 text-blue-600" />
            </div>
            <h2 className="text-xl md:text-2xl font-bold text-gray-900">Villa Management</h2>
          </div>

          {/* Digital Marketing Section */}
          <div 
            onClick={() => handleNavigation('/marketing')}
            className="w-full flex flex-col items-center text-center p-6 md:p-8 cursor-pointer hover:bg-gray-100 transition-colors border-2 border-transparent hover:border-blue-100 hover:shadow-lg rounded-xl"
          >
            <div className="bg-blue-50 p-4 md:p-5 rounded-full mb-4 md:mb-5">
              <Megaphone className="h-10 w-10 md:h-12 md:w-12 text-blue-600" />
            </div>
            <h2 className="text-xl md:text-2xl font-bold text-gray-900">Digital Marketing</h2>
          </div>

          {/* Advertising Section */}
          <div 
            onClick={() => setIsPopupOpen(true)}
            className="w-full flex flex-col items-center text-center p-6 md:p-8 cursor-pointer hover:bg-gray-100 transition-colors border-2 border-transparent hover:border-blue-100 hover:shadow-lg rounded-xl"
          >
            <div className="bg-blue-50 p-4 md:p-5 rounded-full mb-4 md:mb-5">
              <Globe className="h-10 w-10 md:h-12 md:w-12 text-blue-600" />
            </div>
            <h2 className="text-xl md:text-2xl font-bold text-gray-900">Advertising</h2>
          </div>
        </div>
      </div>

      {/* WhatsApp Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsPopupOpen(false)}></div>
          <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
            <div className="p-6">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Advertising</h2>
              <p className="text-gray-600 mb-6">
                Increase your visibility and reach your target audience with our Advertising solutions. Perfect for businesses looking to promote their products or services on our platform.
              </p>
              <button 
                onClick={handleWhatsAppChat}
                className="w-full bg-green-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center mb-4"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                  <path d="M3 21l1.65-3.8a9 9 0 1 1 3.4 2.9L3 21"></path>
                  <path d="M9 10a.5.5 0 0 0 1 0V9a.5.5 0 0 0-1 0v1Z"></path>
                  <path d="M14 10a.5.5 0 0 0 1 0V9a.5.5 0 0 0-1 0v1Z"></path>
                  <path d="M9 14a.5.5 0 0 0 .5.5c.28 0 .5-.22.5-.5s.22-.5.5-.5.5.22.5.5.22.5.5.5.5-.22.5-.5"></path>
                </svg>
                Chat on WhatsApp
              </button>
              <button 
                onClick={() => setIsPopupOpen(false)}
                className="w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}