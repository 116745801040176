import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Clock, MapPin, Users, DollarSign, Star, ArrowRight } from 'lucide-react';

const activities = {
  'mason-elephant-park': {
    title: "Mason Elephant Park",
    description: "Get up close with Bali's gentle giants at this ethical elephant sanctuary. Feed, wash, and interact with rescued elephants in a natural setting.",
    image: "https://i.imghippo.com/files/Y6291FWg.jpg",
    price: "From IDR 950k",
    duration: "Half Day",
    location: "Taro Village, Tegallalang",
    rating: 4.8,
    reviews: 342,
    includes: [
      "Hotel pickup & drop-off",
      "Welcome drink",
      "Elephant feeding experience",
      "Elephant bathing experience",
      "Professional guide",
      "Insurance",
      "All entrance fees",
      "Bottled water"
    ],
    schedule: [
      "08:00 AM - Hotel pickup",
      "09:30 AM - Arrival & welcome drink",
      "10:00 AM - Elephant education session",
      "10:30 AM - Elephant feeding experience",
      "11:30 AM - Elephant bathing activity",
      "12:30 PM - Photo opportunities",
      "01:30 PM - Return to hotel"
    ],
    highlights: [
      "Interact with rescued elephants",
      "Learn about elephant conservation",
      "Beautiful jungle setting",
      "Professional photos available",
      "Ethical treatment practices"
    ]
  },
  'uluwatu-paragliding': {
    title: "Uluwatu Paragliding Experience",
    description: "Soar above Uluwatu's dramatic cliffs for breathtaking views of the Indian Ocean. Perfect for both beginners and experienced paragliders.",
    image: "https://i.imghippo.com/files/Nm4950iFc.jpg",
    price: "From IDR 1.2M",
    duration: "2 Hours",
    location: "Uluwatu",
    rating: 4.9,
    reviews: 156,
    includes: [
      "Professional instructor",
      "All equipment",
      "Safety briefing",
      "Insurance",
      "GoPro photos/videos",
      "Certificate",
      "Light refreshments"
    ],
    schedule: [
      "Multiple daily sessions available",
      "30-minute safety briefing",
      "15-20 minute flight",
      "Photo/video session",
      "Certificate presentation"
    ],
    highlights: [
      "No experience necessary",
      "Professional equipment",
      "Stunning ocean views",
      "Expert instructors",
      "HD video recording"
    ]
  },
  'cooking-class': {
    title: "Traditional Balinese Cooking Class",
    description: "Learn to prepare authentic Balinese dishes in a traditional family compound, including a visit to the local market.",
    image: "https://i.imghippo.com/files/wOg2291BX.jpg",
    price: "From IDR 550k",
    duration: "6 Hours",
    location: "Ubud",
    rating: 4.9,
    reviews: 278,
    includes: [
      "Market tour",
      "Cooking equipment",
      "Ingredients",
      "Recipe booklet",
      "Welcome drink",
      "Full meal",
      "Hotel transfer"
    ],
    schedule: [
      "07:00 AM - Hotel pickup",
      "07:30 AM - Local market tour",
      "08:30 AM - Garden tour",
      "09:00 AM - Cooking class begins",
      "12:00 PM - Enjoy your creations",
      "01:00 PM - Return to hotel"
    ],
    highlights: [
      "Market tour included",
      "Hands-on cooking experience",
      "Traditional techniques",
      "Take-home recipe book",
      "Authentic location"
    ]
  },
  'sacred-water-purification': {
    title: "Sacred Water Purification",
    description: "Experience a traditional Balinese water purification ceremony at a sacred waterfall, guided by a local priest.",
    image: "https://i.imghippo.com/files/aYw6409AI.jpg",
    price: "From IDR 750k",
    duration: "4 Hours",
    location: "Tirta Empul Temple, Tampaksiring",
    rating: 4.9,
    reviews: 195,
    includes: [
      "Hotel pickup & drop-off",
      "Traditional ceremonial dress",
      "Offerings (canang sari)",
      "Local priest guidance",
      "Professional guide",
      "Temple entrance fees",
      "Purification ceremony",
      "Blessing ceremony",
      "Light refreshments"
    ],
    schedule: [
      "07:00 AM - Hotel pickup",
      "08:00 AM - Arrival at temple",
      "08:30 AM - Change into traditional dress",
      "09:00 AM - Temple tour & history",
      "09:30 AM - Offering preparation",
      "10:00 AM - Purification ceremony",
      "10:45 AM - Blessing ceremony",
      "11:30 AM - Return to hotel"
    ],
    highlights: [
      "Authentic spiritual experience",
      "Traditional ceremonial dress",
      "Sacred temple location",
      "Professional photos allowed",
      "Cultural immersion"
    ]
  },
  'canggu-surfing': {
    title: "Surfing Lessons in Canggu",
    description: "Learn to surf with professional instructors at Canggu's best beaches. Suitable for all skill levels.",
    image: "https://i.imghippo.com/files/fnOy6410UDk.jpg",
    price: "From IDR 450k",
    duration: "2 Hours",
    location: "Batu Bolong Beach, Canggu",
    rating: 4.8,
    reviews: 324,
    includes: [
      "Professional surf instructor",
      "Surfboard rental",
      "Rash guard",
      "Water",
      "Beach setup",
      "Insurance",
      "Photos",
      "Theory lesson"
    ],
    schedule: [
      "Multiple sessions daily:",
      "07:00 AM - Morning session",
      "09:00 AM - Mid-morning session",
      "02:00 PM - Afternoon session",
      "04:00 PM - Sunset session",
      "Each session includes:",
      "- 30min theory & safety",
      "- 90min practical lesson"
    ],
    highlights: [
      "Professional instructors",
      "All equipment provided",
      "Perfect for beginners",
      "Small group sizes",
      "Beautiful beach location"
    ]
  }
};

export default function ActivityDetails() {
  const { activityId } = useParams();
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!activityId || !activities[activityId as keyof typeof activities]) {
    return <Navigate to="/activities" replace />;
  }

  const activity = activities[activityId as keyof typeof activities];
  const thumbnailUrl = `${activity.image}?w=20&q=10`;

  const handleBookNow = () => {
    const message = encodeURIComponent(`Hello, I would like to book the ${activity.title} activity.`);
    window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
  };

  const renderStars = () => (
    <div className="flex">
      {[...Array(5)].map((_, index) => (
        <Star key={index} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
      ))}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[70vh]">
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: !imageLoaded ? 'blur(20px)' : 'none',
              transition: 'filter 0.3s ease-out'
            }}
          />
          <img
            src={activity.image}
            alt={activity.title}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
            loading="eager"
            fetchpriority="high"
            decoding="async"
            sizes="100vw"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex flex-col justify-end h-full pb-16">
            <div className="max-w-3xl">
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                {activity.title}
              </h1>
              <div className="flex items-center space-x-6 text-gray-300 mb-4">
                <div className="flex items-center">
                  <MapPin className="h-5 w-5 mr-2" />
                  <span>{activity.location}</span>
                </div>
                <div className="flex items-center">
                  <Clock className="h-5 w-5 mr-2" />
                  <span>{activity.duration}</span>
                </div>
                <div className="flex items-center space-x-2">
                  {renderStars()}
                  <span>{activity.rating} ({activity.reviews} reviews)</span>
                </div>
              </div>
              <p className="text-xl text-gray-200 mb-8">
                {activity.description}
              </p>
              <div className="flex items-center justify-between">
                <span className="text-3xl font-bold text-white">{activity.price}</span>
                <button 
                  onClick={handleBookNow}
                  className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors inline-flex items-center"
                >
                  Book Now
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Activity Details */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Details */}
          <div className="lg:col-span-2 space-y-8">
            {/* Schedule Section */}
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Schedule</h2>
              <div className="space-y-4">
                {activity.schedule.map((item, index) => (
                  <div key={index} className="flex items-center text-gray-700">
                    <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Highlights Section */}
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Highlights</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {activity.highlights.map((highlight, index) => (
                  <div key={index} className="flex items-center">
                    <Star className="h-5 w-5 text-yellow-400 fill-yellow-400 mr-2" />
                    <span className="text-gray-700">{highlight}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column - Inclusions & Booking */}
          <div className="space-y-6">
            {/* What's Included */}
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <h2 className="text-xl font-bold text-gray-900 mb-4">What's Included</h2>
              <div className="space-y-3">
                {activity.includes.map((item, index) => (
                  <div key={index} className="flex items-center text-gray-700">
                    <div className="h-2 w-2 bg-green-500 rounded-full mr-3"></div>
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Booking Card */}
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Book This Activity</h2>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Price</span>
                  <span className="text-2xl font-bold text-blue-600">{activity.price}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Duration</span>
                  <span className="font-semibold">{activity.duration}</span>
                </div>
                <button 
                  onClick={handleBookNow}
                  className="w-full bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}