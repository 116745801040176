import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { MapPin, Star, Heart, Bed, Bath, Square, ArrowLeft } from 'lucide-react';
import ImageCarousel from '../components/ImageCarousel';
import VillaCasablancaPopup from '../components/VillaCasablancaPopup';
import VillaEmiliaPopup from '../components/VillaEmiliaPopup';
import ImageLightbox from '../components/ImageLightbox';

// Villa data
const villas = {
  'villa-casablanca': {
    id: 'villa-casablanca',
    title: 'Villa Casablanca',
    location: 'Uluwatu',
    bedrooms: 4,
    price: '$199',
    perNight: true,
    images: [
      'https://i.imghippo.com/files/ICQ9835YDE.jpg',
      'https://i.imghippo.com/files/SwxB6811scc.jpg',
      'https://i.imghippo.com/files/EcI5881WjQ.jpg',
      'https://i.imghippo.com/files/otj3833rOo.jpg',
      'https://i.imghippo.com/files/puqz8361Ho.jpg',
      'https://i.imghippo.com/files/TTmk1694J.jpg',
      'https://i.imghippo.com/files/Qx2480dTY.jpg'
    ],
    beds: 4,
    baths: 4,
    guests: 8,
    rating: 4.9,
    reviews: 92,
    superhost: true,
    area: "950 m²",
    description: "Casablanca is a designer 4BR villa exalts in the effortless beauty of natural materials and smooth shapes. Raw, minimalist, striking a balance between full and empty, this villa features an unbeatable combination of cultural flavor and low-key luxury.",
    location_details: [
      "5 minutes to Melasti and Karma Beach",
      "7-10 minutes to Green Bowl and Pandawa beach",
      "15 minutes to Uluwatu beach"
    ],
    amenities: [
      'Private Pool',
      'En-suite Bathrooms',
      'Super High-speed WiFi',
      'Close to Beach',
      'Close to Surfing',
      'Close to Restaurants',
      'Close to Cafes',
      'Close to Grocery Store',
      'Daily Housekeeping',
      'Breakfast Service'
    ],
    access: [
      'Internal garage (2 cars or 10 motorbikes)',
      'Panoramic external garage (2 additional cars)'
    ]
  },
  'villa-emilia': {
    id: 'villa-emilia',
    title: 'Villa Emilia',
    location: 'Canggu',
    bedrooms: 2,
    price: '$175',
    perNight: true,
    images: [
      'https://i.imghippo.com/files/Ji8789Ow.jpg',
      'https://i.imghippo.com/files/bjT5077UpI.jpg',
      'https://i.imghippo.com/files/Mqw2930UI.jpg',
      'https://i.imghippo.com/files/BEo2200MA.jpg',
      'https://i.imghippo.com/files/GBe8952YU.jpg',
      'https://i.imghippo.com/files/JVvQ8423Obk.jpg',
      'https://i.imghippo.com/files/mvc3387iZc.jpg',
      'https://i.imghippo.com/files/Fgs5401wB.jpg',
      'https://i.imghippo.com/files/PXII6191U.jpg',
      'https://i.imghippo.com/files/yydD6967xHU.jpg'
    ],
    beds: 2,
    baths: 2,
    guests: 4,
    rating: 4.8,
    reviews: 76,
    superhost: true,
    area: "350 m²",
    description: "Located in Pantai Nyanyi near the Canggu area, this charming 2-bedroom villa is perfect for families who love the ocean but prefer a quieter setting away from central Canggu. Enjoy the tranquil beachfront location while still being close to Canggu's amenities.",
    location_details: [
      "10 minutes to central Canggu",
      "5 minutes to Pantai Nyanyi Beach",
      "15 minutes to Tanah Lot Temple",
      "20 minutes to Seminyak"
    ],
    amenities: [
      'Private Pool',
      'En-suite Bathrooms',
      'High-speed WiFi',
      'Ocean Views',
      'Beach Access',
      'Fully Equipped Kitchen',
      'Air Conditioning',
      'Daily Housekeeping',
      'Garden',
      'Parking'
    ],
    access: [
      'Private parking for 2 cars',
      'Secure gated entrance'
    ]
  }
};

export default function VillaPage() {
  const { villaId } = useParams();
  const [isBookingPopupOpen, setIsBookingPopupOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  if (!villaId || !villas[villaId as keyof typeof villas]) {
    return <Navigate to="/villas-for-rent" replace />;
  }

  const villa = villas[villaId as keyof typeof villas];

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setIsLightboxOpen(true);
  };

  const renderStars = () => (
    <div className="flex">
      {[...Array(5)].map((_, index) => (
        <Star key={index} className="h-4 w-4 text-yellow-400 fill-yellow-400" />
      ))}
    </div>
  );

  const BookingPopup = villa.id === 'villa-casablanca' ? VillaCasablancaPopup : VillaEmiliaPopup;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Carousel */}
      <div className="relative w-full">
        <div className="relative w-full pb-[100%] md:pb-[56.25%]">
          {/* Back Button - Mobile Only */}
          <a 
            href="/villas-for-rent"
            className="md:hidden absolute top-4 right-4 z-30 text-white p-2 rounded-lg flex items-center"
          >
            <ArrowLeft className="h-5 w-5" />
          </a>

          {/* Image Carousel */}
          <div className="absolute inset-0 z-0">
            <ImageCarousel images={villa.images} />
          </div>
          
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-10"></div>
          
          {/* Content */}
          <div className="absolute inset-0 flex items-end z-20">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-8 md:pb-16">
              <div className="max-w-xl">
                <span className="inline-flex items-center px-2 py-1 md:px-3 md:py-1 rounded-full text-xs md:text-sm font-medium bg-blue-600 text-white mb-2 md:mb-4">
                  Featured Villa
                </span>
                <h1 className="text-2xl md:text-4xl font-bold text-white mb-2 md:mb-4">
                  {villa.title}
                </h1>
                <div className="flex flex-wrap items-center gap-2 md:gap-4 text-gray-300 mb-2 md:mb-4">
                  <div className="flex items-center">
                    <MapPin className="h-3 w-3 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{villa.location}</span>
                  </div>
                  <div className="flex items-center">
                    <Bed className="h-3 w-3 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{villa.beds} Beds</span>
                  </div>
                  <div className="flex items-center">
                    <Bath className="h-3 w-3 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{villa.baths} Baths</span>
                  </div>
                  <div className="flex items-center">
                    <Square className="h-3 w-3 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{villa.area}</span>
                  </div>
                </div>
                <p className="text-sm md:text-base text-gray-200 mb-3 md:mb-6 line-clamp-3 md:line-clamp-none">
                  {villa.description}
                </p>
                <div className="flex items-center">
                  <div className="flex items-center">
                    {renderStars()}
                    <span className="text-white text-sm md:text-base font-medium ml-2">{villa.rating}</span>
                    <span className="text-gray-300 text-xs md:text-sm ml-1">({villa.reviews} reviews)</span>
                  </div>
                  <span className="text-xl md:text-2xl font-bold text-white ml-auto">{villa.price}/night</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Touch-Scrollable Image Gallery */}
      <div className="bg-white py-4 md:py-8 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex overflow-x-auto pb-4 space-x-4 snap-x snap-mandatory scrollbar-hide">
            {villa.images.map((image, index) => (
              <div
                key={index}
                onClick={() => handleImageClick(index)}
                className="flex-none w-[280px] h-[200px] rounded-lg overflow-hidden snap-start cursor-pointer"
              >
                <img
                  src={image}
                  alt={`${villa.title} - View ${index + 1}`}
                  className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Villa Details */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Details */}
          <div className="lg:col-span-2 space-y-8">
            {/* Location Details */}
            <div className="bg-white p-6 md:p-8 rounded-xl shadow-sm">
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6">The Space</h2>
              <p className="text-gray-700 mb-6">Located in a very prime location:</p>
              <div className="space-y-2">
                {villa.location_details.map((detail, index) => (
                  <div key={index} className="flex items-center">
                    <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                    <span className="text-gray-700">{detail}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Amenities */}
            <div className="bg-white p-6 md:p-8 rounded-xl shadow-sm">
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6">Amenities</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {villa.amenities.map((amenity, index) => (
                  <div key={index} className="flex items-center bg-gray-50 p-3 md:p-4 rounded-lg">
                    <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                    <span className="text-sm md:text-base text-gray-700">{amenity}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Access Details */}
            <div className="bg-white p-6 md:p-8 rounded-xl shadow-sm">
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6">Guest Access</h2>
              <p className="text-gray-700 mb-4">The guests are given access of all the villa.</p>
              <p className="text-gray-700 mb-4">There are two access:</p>
              <div className="space-y-3">
                {villa.access.map((access, index) => (
                  <div key={index} className="flex items-center">
                    <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                    <span className="text-gray-700">{access}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column - Booking Card */}
          <div className="space-y-6">
            <div className="bg-white p-6 rounded-xl shadow-sm sticky top-24">
              <div className="flex justify-between items-center mb-6">
                <span className="text-2xl md:text-3xl font-bold text-blue-600">{villa.price}</span>
                <span className="text-gray-500">per night</span>
              </div>
              <div className="space-y-4 mb-6">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Max Guests</span>
                  <span className="font-semibold">{villa.guests} people</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Bedrooms</span>
                  <span className="font-semibold">{villa.beds} bedrooms</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Bathrooms</span>
                  <span className="font-semibold">{villa.baths} bathrooms</span>
                </div>
              </div>
              <button 
                onClick={() => setIsBookingPopupOpen(true)}
                className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Booking Popup */}
      <BookingPopup
        isOpen={isBookingPopupOpen}
        onClose={() => setIsBookingPopupOpen(false)}
      />

      {/* Image Lightbox */}
      <ImageLightbox
        images={villa.images}
        isOpen={isLightboxOpen}
        initialIndex={selectedImageIndex}
        onClose={() => setIsLightboxOpen(false)}
      />

      <style jsx>{`
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </div>
  );
}