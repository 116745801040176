import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import FeaturedListing from './components/FeaturedListing';
import Villas from './pages/Villas';
import VillasMain from './pages/VillasMain';
import Management from './pages/Management';
import BoutiqueHotel from './pages/BoutiqueHotel';
import About from './pages/About';
import VillasForSale from './pages/VillasForSale';
import VillasForRent from './pages/VillasForRent';
import ComingSoon from './pages/ComingSoon';
import Events from './pages/Events';
import EventDetails from './pages/EventDetails';
import Tours from './pages/Tours';
import TourDetails from './pages/TourDetails';
import LocationVillas from './pages/LocationVillas';
import Dining from './pages/Dining';
import Activities from './pages/Activities';
import ActivityDetails from './pages/ActivityDetails';
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterEvent from './pages/RegisterEvent';
import Dashboard from './pages/Dashboard';
import BusinessLanding from './pages/BusinessLanding';
import VillaPage from './pages/VillaPage';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) {
      setTransitionStage("fadeOut");
    }
  }, [location, displayLocation]);

  // Update page title based on route
  useEffect(() => {
    const baseTitle = "Bali Expert";
    let pageTitle = baseTitle;

    // Map routes to titles
    switch (location.pathname) {
      case '/':
        pageTitle = `${baseTitle} | Home`;
        break;
      case '/property-management':
        pageTitle = `${baseTitle} | Property Management`;
        break;
      case '/villas':
        pageTitle = `${baseTitle} | Villas`;
        break;
      case '/villas-for-sale':
        pageTitle = `${baseTitle} | Villas for Sale`;
        break;
      case '/villas-for-rent':
        pageTitle = `${baseTitle} | Villas for Rent`;
        break;
      case '/boutique-hotel':
        pageTitle = `${baseTitle} | Boutique Hotel`;
        break;
      case '/marketing':
        pageTitle = `${baseTitle} | Marketing`;
        break;
      case '/business':
        pageTitle = `${baseTitle} | Business`;
        break;
      case '/events':
        pageTitle = `${baseTitle} | Events`;
        break;
      case '/tours':
        pageTitle = `${baseTitle} | Tours`;
        break;
      case '/dining':
        pageTitle = `${baseTitle} | Dining`;
        break;
      case '/activities':
        pageTitle = `${baseTitle} | Activities`;
        break;
      case '/dashboard':
        pageTitle = `${baseTitle} | Dashboard`;
        break;
      case '/login':
        pageTitle = `${baseTitle} | Login`;
        break;
      case '/register':
        pageTitle = `${baseTitle} | Register`;
        break;
      default:
        // For nested routes like /villas/sale/ubud
        if (location.pathname.includes('/villas/sale/')) {
          pageTitle = `${baseTitle} | Villas for Sale`;
        } else if (location.pathname.includes('/villas/rent/')) {
          pageTitle = `${baseTitle} | Villas for Rent`;
        } else if (location.pathname.includes('/events/')) {
          pageTitle = `${baseTitle} | Event Details`;
        } else if (location.pathname.includes('/tours/')) {
          pageTitle = `${baseTitle} | Tour Details`;
        } else if (location.pathname.includes('/activities/')) {
          pageTitle = `${baseTitle} | Activity Details`;
        }
    }

    document.title = pageTitle;
  }, [location]);

  const handleAnimationEnd = () => {
    if (transitionStage === "fadeOut") {
      setTransitionStage("fadeIn");
      setDisplayLocation(location);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <main>
        <div 
          className={`page-transition ${transitionStage}`}
          onAnimationEnd={handleAnimationEnd}
        >
          <Routes location={displayLocation}>
            <Route path="/" element={<FeaturedListing />} />
            <Route path="/villas" element={<VillasMain />} />
            <Route path="/villas-for-sale" element={<VillasForSale />} />
            <Route path="/villas-for-rent" element={<VillasForRent />} />
            <Route path="/villas/:villaId" element={<VillaPage />} />
            <Route path="/villas/sale/ubud" element={<Villas />} />
            <Route path="/villas/sale/uluwatu" element={<ComingSoon location="Uluwatu" />} />
            <Route path="/villas/sale/canggu" element={<ComingSoon location="Canggu" />} />
            <Route path="/villas/rent/ubud" element={<ComingSoon location="Ubud" />} />
            <Route path="/villas/rent/uluwatu" element={<ComingSoon location="Uluwatu" />} />
            <Route path="/villas/rent/canggu" element={<ComingSoon location="Canggu" />} />
            <Route path="/property-management" element={<Management />} />
            <Route path="/boutique-hotel" element={<BoutiqueHotel />} />
            <Route path="/business" element={<BusinessLanding />} />
            <Route path="/marketing" element={<About />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:eventId" element={<EventDetails />} />
            <Route path="/tours" element={<Tours />} />
            <Route path="/tours/:tourId" element={<TourDetails />} />
            <Route path="/dining" element={<Dining />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/activities/:activityId" element={<ActivityDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register-event" element={<RegisterEvent />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </div>
      </main>
    </div>
  );
}

export default App;