import React, { useState } from 'react';
import { Building2, MapPin } from 'lucide-react';

export default function VillasMain() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageUrl = "https://i.imghippo.com/files/IHra9540Qk.jpeg";
  const thumbnailUrl = `${imageUrl}?w=20&q=10`;
  const optimizedUrl = `${imageUrl}?w=1920&q=80`;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative w-full">
        {/* This div sets the aspect ratio - square on mobile, 16:9 on desktop */}
        <div className="relative w-full h-[70vh]">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0"
              style={{
                backgroundImage: `url(${thumbnailUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: !imageLoaded ? 'blur(20px)' : 'none',
                transition: 'filter 0.3s ease-out'
              }}
            />
            <img
              src={optimizedUrl}
              alt="Luxury Bali Villas"
              className={`w-full h-full object-cover transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setImageLoaded(true)}
              loading="eager"
              fetchpriority="high"
              decoding="async"
              sizes="100vw"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
          </div>

          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-600 text-white mb-2 md:mb-4">
                  Featured Properties
                </span>
                <h1 className="text-3xl md:text-5xl font-bold text-white mb-2 md:mb-4">
                  Villas in Bali
                </h1>
                <p className="text-base md:text-xl text-gray-200 mb-4 md:mb-8">
                  Discover your perfect villa in Bali's most sought-after locations. Whether you're looking to rent or buy, we have the perfect property for you.
                </p>
                <div className="flex flex-wrap gap-3 md:gap-4">
                  <a
                    href="/villas-for-rent"
                    className="bg-blue-600 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-700 transition-colors inline-flex items-center"
                  >
                    Browse Rentals
                    <Building2 className="ml-2 h-4 w-4 md:h-5 md:w-5" />
                  </a>
                  <a
                    href="/villas-for-sale"
                    className="bg-white text-blue-600 px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-gray-100 transition-colors"
                  >
                    View Properties
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Villa Categories Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          {/* Villas for Rent - Now first */}
          <a
            href="/villas-for-rent"
            className="group relative overflow-hidden rounded-2xl shadow-lg transition-transform hover:scale-[1.02] duration-300"
          >
            <div className="aspect-square md:aspect-[16/9] relative">
              <img
                src="https://images.unsplash.com/photo-1613490493576-7fde63acd811?auto=format&fit=crop&q=80"
                alt="Villas for Rent"
                className="w-full h-full object-cover"
                loading="lazy"
                decoding="async"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 transition-opacity group-hover:bg-opacity-30" />
              <div className="absolute inset-0 p-4 md:p-8 flex flex-col justify-between">
                <div>
                  <h2 className="text-2xl md:text-3xl font-bold text-white mb-2 md:mb-4">Villas for Rent</h2>
                  <p className="text-base md:text-lg text-gray-200">
                    Experience luxury living in Bali with our curated selection of rental villas.
                  </p>
                </div>
                <span className="inline-flex items-center px-4 md:px-6 py-2 md:py-3 bg-white text-blue-600 rounded-lg text-sm md:text-base font-semibold w-fit group-hover:bg-gray-100 transition-colors">
                  <Building2 className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                  View Rentals
                </span>
              </div>
            </div>
          </a>

          {/* Villas for Sale - Now second */}
          <a
            href="/villas-for-sale"
            className="group relative overflow-hidden rounded-2xl shadow-lg transition-transform hover:scale-[1.02] duration-300"
          >
            <div className="aspect-square md:aspect-[16/9] relative">
              <img
                src="https://images.unsplash.com/photo-1604999333679-b86d54738315?auto=format&fit=crop&q=80"
                alt="Villas for Sale"
                className="w-full h-full object-cover"
                loading="lazy"
                decoding="async"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 transition-opacity group-hover:bg-opacity-30" />
              <div className="absolute inset-0 p-4 md:p-8 flex flex-col justify-between">
                <div>
                  <h2 className="text-2xl md:text-3xl font-bold text-white mb-2 md:mb-4">Villas for Sale</h2>
                  <p className="text-base md:text-lg text-gray-200">
                    Find your dream villa in Bali. From traditional Balinese homes to modern luxury estates.
                  </p>
                </div>
                <span className="inline-flex items-center px-4 md:px-6 py-2 md:py-3 bg-blue-600 text-white rounded-lg text-sm md:text-base font-semibold w-fit group-hover:bg-blue-700 transition-colors">
                  <Building2 className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                  Browse Properties
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}