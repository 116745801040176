import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { MapPin, Star, Check, ArrowRight, Clock } from 'lucide-react';
import TourBookingPopup from '../components/TourBookingPopup';
import TourBookingGeneralPopup from '../components/TourBookingGeneralPopup';

// Rest of the imports and tour data...

export default function TourDetails() {
  const { tourId } = useParams();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isBookingPopupOpen, setIsBookingPopupOpen] = useState(false);
  const [isGeneralBookingPopupOpen, setIsGeneralBookingPopupOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!tourId || !tours[tourId as keyof typeof tours]) {
    return <Navigate to="/tours" replace />;
  }

  const tour = tours[tourId as keyof typeof tours];
  const thumbnailUrl = `${tour.image}?w=20&q=10`;

  const handleBookNow = () => {
    if (tourId === 'mt-agung-hiking') {
      setIsBookingPopupOpen(true);
    } else {
      setIsGeneralBookingPopupOpen(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[70vh]">
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: !imageLoaded ? 'blur(20px)' : 'none',
              transition: 'filter 0.3s ease-out'
            }}
          />
          <img
            src={tour.image}
            alt={tour.title}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
            loading="eager"
            fetchpriority="high"
            decoding="async"
            sizes="100vw"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex flex-col justify-end h-full pb-16">
            <div className="max-w-3xl">
              <div className="flex items-center space-x-4 mb-4">
                <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                  {tour.category}
                </span>
                <span className="text-blue-400 font-medium flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {tour.date}
                </span>
              </div>
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">
                {tour.title}
              </h1>
              <div className="text-blue-400 font-medium mb-4">
                by: {tour.company}
              </div>
              <div className="flex items-center space-x-6 text-gray-300 mb-6">
                <div className="flex items-center">
                  <MapPin className="h-5 w-5 mr-2" />
                  <span>Bali, Indonesia</span>
                </div>
                <div className="flex items-center">
                  {renderStars(tour.rating)}
                  <span className="ml-2">{tour.rating} ({tour.reviews} reviews)</span>
                </div>
              </div>
              <p className="text-xl text-gray-200 mb-8">
                {tour.description}
              </p>
              <button 
                onClick={handleBookNow}
                className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors inline-flex items-center"
              >
                Book Now
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Tour Details */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Tour Details */}
          <div className="lg:col-span-2 space-y-8">
            {/* Features */}
            <div className="mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-8">Tour Features</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {tour.features.map((feature, index) => (
                  <div key={index} className="flex items-center bg-white p-4 rounded-lg shadow-sm">
                    <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                    <span className="text-gray-700">{feature}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Schedule */}
            <div className="mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-8">Schedule</h2>
              <div className="space-y-6">
                {tour.schedule.map((item, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                    <div className="flex items-center mb-4">
                      <Clock className="h-5 w-5 text-blue-600 mr-2" />
                      <h3 className="text-xl font-bold text-gray-900">{item.day}</h3>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">{item.title}</h4>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Experiences */}
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-8">What to Expect</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {tour.experiences.map((exp, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                    <h3 className="text-xl font-bold text-gray-900 mb-3">{exp.title}</h3>
                    <p className="text-gray-600">{exp.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column - Pricing */}
          <div className="space-y-6">
            {/* Right column content would go here */}
          </div>
        </div>

        {/* Bottom CTA Section */}
        <div className="mt-16 text-center">
          <button 
            onClick={handleBookNow}
            className="bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors text-lg"
          >
            Book Now
            <ArrowRight className="inline-block ml-2 h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Mobile Book Now Button - Fixed at bottom for easy access on mobile */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 p-4 bg-white shadow-lg z-50">
        <button 
          onClick={handleBookNow}
          className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center"
        >
          Book Now
          <ArrowRight className="ml-2 h-5 w-5" />
        </button>
      </div>

      {/* Mt. Agung Booking Popup */}
      {tourId === 'mt-agung-hiking' && (
        <TourBookingPopup
          isOpen={isBookingPopupOpen}
          onClose={() => setIsBookingPopupOpen(false)}
          tourName={tour.title}
          instagramLink={tour.instagramLink || ""}
          whatsappLink={tour.whatsappLink || ""}
        />
      )}

      {/* General Booking Popup for other tours */}
      <TourBookingGeneralPopup
        isOpen={isGeneralBookingPopupOpen}
        onClose={() => setIsGeneralBookingPopupOpen(false)}
        tourName={tour.title}
      />
    </div>
  );
}

function renderStars(rating: number) {
  return (
    <div className="flex">
      {[...Array(5)].map((_, index) => (
        <Star key={index} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
      ))}
    </div>
  );
}