import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import ImageLightbox from './ImageLightbox';

interface ThumbnailCarouselProps {
  images: string[];
}

export default function ThumbnailCarousel({ images }: ThumbnailCarouselProps) {
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  
  // Preload images
  useEffect(() => {
    images.forEach(image => {
      const img = new Image();
      img.src = `${image}?w=300&q=80`;
      img.onload = () => {
        setLoadedImages(prev => new Set([...prev, image]));
      };
    });
  }, [images]);

  const handleMouseEnter = () => {
    setIsHovering(true);
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.start();
    }
  };

  const openLightbox = (index: number) => {
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <div className="relative">
      <h3 className="text-2xl font-bold text-gray-900 mb-4">Property Gallery</h3>
      
      <div 
        className="relative group"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Swiper
          modules={[Navigation, Autoplay]}
          slidesPerView={4}
          spaceBetween={16}
          navigation={{
            prevEl: '.thumbnail-prev',
            nextEl: '.thumbnail-next',
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          breakpoints={{
            320: { slidesPerView: 2 },
            640: { slidesPerView: 3 },
            768: { slidesPerView: 4 },
          }}
          onSwiper={setSwiperInstance}
          className="w-full"
        >
          {images.map((image, index) => {
            const thumbnailUrl = `${image}?w=20&q=10`;
            const optimizedUrl = `${image}?w=300&q=80`;

            return (
              <SwiperSlide key={index} className="h-full">
                <div 
                  className="relative aspect-[4/3] overflow-hidden rounded-lg cursor-pointer transition-transform duration-300 hover:scale-105"
                  onClick={() => openLightbox(index)}
                >
                  {/* Low quality placeholder */}
                  <div 
                    className="absolute inset-0"
                    style={{
                      backgroundImage: `url(${thumbnailUrl})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      filter: !loadedImages.has(image) ? 'blur(20px)' : 'none',
                      transition: 'filter 0.3s ease-out'
                    }}
                  />
                  
                  {/* Main image */}
                  <img
                    src={optimizedUrl}
                    alt={`Thumbnail ${index + 1}`}
                    className={`w-full h-full object-cover transition-opacity duration-300 ${
                      loadedImages.has(image) ? 'opacity-100' : 'opacity-0'
                    }`}
                    loading="lazy"
                    decoding="async"
                  />
                  
                  {/* Hover overlay */}
                  <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-20 transition-opacity duration-300 flex items-center justify-center">
                    <div className="opacity-0 hover:opacity-100 transition-opacity duration-300 transform scale-90 hover:scale-100">
                      <div className="bg-white/80 rounded-full p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-blue-600">
                          <path d="M15 3h6v6"></path>
                          <path d="M10 14 21 3"></path>
                          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        
        {/* Custom Navigation Buttons */}
        <button 
          className={`thumbnail-prev absolute left-0 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white shadow-md text-gray-800 z-10 transition-opacity duration-300 ${
            isHovering ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <ChevronLeft className="h-5 w-5" />
        </button>
        <button 
          className={`thumbnail-next absolute right-0 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white shadow-md text-gray-800 z-10 transition-opacity duration-300 ${
            isHovering ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <ChevronRight className="h-5 w-5" />
        </button>
      </div>
      
      {/* Lightbox */}
      <ImageLightbox 
        images={images}
        isOpen={isLightboxOpen}
        initialIndex={lightboxIndex}
        onClose={() => setIsLightboxOpen(false)}
      />
    </div>
  );
}