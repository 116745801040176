import React, { useState, useEffect } from 'react';
import { MapPin, Search, Calendar, Users, ArrowRight, Star, Heart, Bed, Bath, Square, Filter, ChevronDown, DollarSign, Building2, X, MessageSquare } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AgentPopup from '../components/AgentPopup';
import VillaCasablancaPopup from '../components/VillaCasablancaPopup';
import VillaEmiliaPopup from '../components/VillaEmiliaPopup';
import VillaWayanPopup from '../components/VillaWayanPopup';

const villaCasablancaImages = [
  'https://i.imghippo.com/files/ICQ9835YDE.jpg',
  'https://i.imghippo.com/files/SwxB6811scc.jpg',
  'https://i.imghippo.com/files/EcI5881WjQ.jpg',
  'https://i.imghippo.com/files/otj3833rOo.jpg',
  'https://i.imghippo.com/files/puqz8361Ho.jpg',
  'https://i.imghippo.com/files/TTmk1694J.jpg',
  'https://i.imghippo.com/files/Qx2480dTY.jpg'
];

const villaEmiliaImages = [
  'https://i.imghippo.com/files/Ji8789Ow.jpg',
  'https://i.imghippo.com/files/bjT5077UpI.jpg',
  'https://i.imghippo.com/files/Mqw2930UI.jpg',
  'https://i.imghippo.com/files/BEo2200MA.jpg',
  'https://i.imghippo.com/files/GBe8952YU.jpg',
  'https://i.imghippo.com/files/JVvQ8423Obk.jpg',
  'https://i.imghippo.com/files/mvc3387iZc.jpg',
  'https://i.imghippo.com/files/Fgs5401wB.jpg',
  'https://i.imghippo.com/files/PXII6191U.jpg',
  'https://i.imghippo.com/files/yydD6967xHU.jpg'
];

const locations = [
  { name: 'Canggu', image: 'https://i.imghippo.com/files/sztl8102Y.webp', href: '/villas/rent/canggu' },
  { name: 'Uluwatu', image: 'https://i.imghippo.com/files/YhQj9317kLk.jpg', href: '/villas/rent/uluwatu' },
  { name: 'Ubud', image: 'https://i.imghippo.com/files/yg4561doE.webp', href: '/villas/rent/ubud' },
  { name: 'Gili Islands', image: 'https://images.unsplash.com/photo-1570789210967-2cac24afeb00?auto=format&fit=crop&q=80', href: '/villas/rent/gili-islands' },
  { name: 'Nusa Islands', image: 'https://images.unsplash.com/photo-1577717903315-1691ae25ab3f?auto=format&fit=crop&q=80', href: '/villas/rent/nusa-islands' }
];

const featuredVilla = {
  id: 'villa-casablanca',
  title: 'Villa Casablanca',
  location: 'Uluwatu',
  bedrooms: 4,
  price: '$199',
  perNight: true,
  images: villaCasablancaImages,
  beds: 4,
  baths: 4,
  guests: 8,
  rating: 4.9,
  reviews: 92,
  superhost: true,
  area: "950 m²",
  description: "Casablanca is a designer 4BR villa exalts in the effortless beauty of natural materials and smooth shapes. Raw, minimalist, striking a balance between full and empty, this villa features an unbeatable combination of cultural flavor and low-key luxury."
};

const allVillas = [
  {
    id: 'villa-casablanca',
    title: 'Villa Casablanca',
    location: 'Uluwatu',
    bedrooms: 4,
    price: '$199',
    perNight: true,
    image: 'https://i.imghippo.com/files/ICQ9835YDE.jpg',
    beds: 4,
    baths: 4,
    guests: 8,
    rating: 4.9,
    reviews: 92,
    superhost: true,
    area: "950 m²",
    description: "Casablanca is a designer 4BR villa exalts in the effortless beauty of natural materials and smooth shapes. Raw, minimalist, striking a balance between full and empty, this villa features an unbeatable combination of cultural flavor and low-key luxury."
  },
  {
    id: 'villa-emilia',
    title: 'Villa Emilia',
    location: 'Canggu',
    bedrooms: 2,
    price: '$175',
    perNight: true,
    images: villaEmiliaImages,
    image: 'https://i.imghippo.com/files/Ji8789Ow.jpg',
    beds: 2,
    baths: 2,
    guests: 4,
    rating: 4.8,
    reviews: 76,
    superhost: true,
    area: "350 m²",
    description: "Located in Pantai Nyanyi near the Canggu area, this charming 2-bedroom villa is perfect for families who love the ocean but prefer a quieter setting away from central Canggu. Enjoy the tranquil beachfront location while still being close to Canggu's amenities."
  },
  {
    id: 'villa-wayan',
    title: 'Villa Wayan',
    location: 'Ubud',
    bedrooms: 3,
    price: '$250',
    perNight: true,
    image: 'https://i.imghippo.com/files/VvtF7544P.webp',
    beds: 3,
    baths: 3,
    guests: 6,
    rating: 4.9,
    reviews: 84,
    superhost: true,
    area: "450 m²",
    description: "Nestled in the heart of Ubud, Villa Wayan offers a perfect blend of traditional Balinese architecture and modern luxury. Surrounded by lush tropical gardens and rice fields, this villa provides a serene escape while maintaining easy access to Ubud's cultural center."
  }
];

export default function VillasForRent() {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedBedrooms, setSelectedBedrooms] = useState('');
  const [filteredVillas, setFilteredVillas] = useState(allVillas);
  const [featuredImageLoaded, setFeaturedImageLoaded] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [isAgentPopupOpen, setIsAgentPopupOpen] = useState(false);
  const [isVillaCountExpanded, setIsVillaCountExpanded] = useState(false);
  const [isBookingPopupOpen, setIsBookingPopupOpen] = useState(false);
  const [isEmiliaBookingPopupOpen, setIsEmiliaBookingPopupOpen] = useState(false);
  const [isWayanBookingPopupOpen, setIsWayanBookingPopupOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isWhatsAppPopupOpen, setIsWhatsAppPopupOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let filtered = [...allVillas];
    
    if (selectedLocation) {
      filtered = filtered.filter(villa => villa.location === selectedLocation);
    }
    
    if (selectedBedrooms) {
      const beds = parseInt(selectedBedrooms);
      filtered = filtered.filter(villa => villa.bedrooms >= beds);
    }
    
    setFilteredVillas(filtered);
  }, [selectedLocation, selectedBedrooms]);

  const handleInquire = () => {
    setIsWhatsAppPopupOpen(true);
  };

  const handleLocationClick = (location: string) => {
    setSelectedLocation(location === selectedLocation ? '' : location);
  };

  const handleMouseEnter = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.start();
    }
  };

  const handleBookDirect = (e: React.MouseEvent, propertyId: string) => {
    e.stopPropagation();
    if (propertyId === 'villa-casablanca') {
      setIsBookingPopupOpen(true);
    } else if (propertyId === 'villa-emilia') {
      setIsEmiliaBookingPopupOpen(true);
    } else if (propertyId === 'villa-wayan') {
      setIsWayanBookingPopupOpen(true);
    } else {
      const message = encodeURIComponent(`Hello, I would like to book the property with ID: ${propertyId}`);
      window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
    }
  };

  const handleVillaClick = (villaId: string) => {
    if (villaId === 'villa-casablanca') {
      window.scrollTo(0, 0);
      navigate('/villas/villa-casablanca');
    } else if (villaId === 'villa-emilia') {
      window.scrollTo(0, 0);
      navigate('/villas/villa-emilia');
    }
  };

  const renderStars = () => (
    <div className="flex">
      {[...Array(5)].map((_, index) => (
        <Star key={index} className="h-4 w-4 text-yellow-400 fill-yellow-400" />
      ))}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative w-full">
        <div className="relative w-full h-[70vh]">
          <div className="absolute inset-0">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop={true}
              className="h-full"
              onSwiper={setSwiperInstance}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              grabCursor={true}
              touchEventsTarget="container"
            >
              {featuredVilla.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="relative h-full">
                    <img
                      src={image}
                      alt={`${featuredVilla.title} - View ${index + 1}`}
                      className="w-full h-full object-cover"
                      loading={index === 0 ? "eager" : "lazy"}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-20"></div>
          </div>

          <div className="absolute inset-0 flex items-center justify-center z-30">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center h-full">
              <div className="max-w-2xl md:mx-0">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-xs md:text-sm font-medium bg-blue-600 text-white mb-2 md:mb-3">
                  Featured Villa
                </span>
                <h1 className="text-2xl md:text-4xl font-bold text-white mb-2 md:mb-3">
                  {featuredVilla.title}
                </h1>
                <div className="flex flex-wrap items-center gap-2 md:gap-3 text-gray-300 mb-2 md:mb-4">
                  <div className="flex items-center">
                    <MapPin className="h-3 w-3 md:h-4 md:w-4 mr-1" />
                    <span className="text-xs md:text-sm">{featuredVilla.location}</span>
                  </div>
                  <div className="flex items-center">
                    <Bed className="h-3 w-3 md:h-4 md:w-4 mr-1" />
                    <span className="text-xs md:text-sm">{featuredVilla.beds} Beds</span>
                  </div>
                  <div className="flex items-center">
                    <Bath className="h-3 w-3 md:h-4 md:w-4 mr-1" />
                    <span className="text-xs md:text-sm">{featuredVilla.baths} Baths</span>
                  </div>
                  <div className="flex items-center">
                    <Square className="h-3 w-3 md:h-4 md:w-4 mr-1" />
                    <span className="text-xs md:text-sm">{featuredVilla.area}</span>
                  </div>
                </div>
                <p className="text-sm md:text-base text-gray-200 mb-3 md:mb-5 line-clamp-3">
                  {featuredVilla.description}
                </p>
                <div className="flex items-center justify-between mb-3 md:mb-5">
                  <div className="flex items-center">
                    {renderStars()}
                    <span className="text-white text-xs md:text-sm font-medium ml-2">{featuredVilla.rating}</span>
                    <span className="text-gray-300 text-xs md:text-sm ml-1">({featuredVilla.reviews} reviews)</span>
                  </div>
                  <span className="text-xl md:text-2xl font-bold text-white">{featuredVilla.price} / night</span>
                </div>
                <div className="flex space-x-3">
                  <button 
                    onClick={(e) => handleBookDirect(e, 'villa-casablanca')}
                    className="bg-blue-600 text-white px-4 md:px-6 py-2 rounded-lg text-xs md:text-sm font-semibold hover:bg-blue-700 transition-colors"
                  >
                    Book Direct
                  </button>
                  <button 
                    onClick={() => handleVillaClick('villa-casablanca')}
                    className="bg-white text-blue-600 px-4 md:px-6 py-2 rounded-lg text-xs md:text-sm font-semibold hover:bg-gray-100 transition-colors"
                  >
                    More Info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md hidden md:block">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex gap-4">
            <div className="flex-1">
              <div className="relative">
                <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
                  <Bed className="h-5 w-5 text-gray-400 mr-2" />
                  <select
                    className="w-full border-none bg-transparent p-0 focus:ring-0"
                    value={selectedBedrooms}
                    onChange={(e) => setSelectedBedrooms(e.target.value)}
                  >
                    <option value="">Any Bedrooms</option>
                    <option value="1">1+ bedroom</option>
                    <option value="2">2+ bedrooms</option>
                    <option value="3">3+ bedrooms</option>
                    <option value="4">4+ bedrooms</option>
                    <option value="5">5+ bedrooms</option>
                  </select>
                </div>
              </div>
            </div>
            
            <div>
              <button 
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center"
              >
                <Search className="h-5 w-5 mr-2" />
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="hidden md:grid md:grid-cols-5 gap-4">
          {locations.map((location) => (
            <div 
              key={location.name}
              onClick={() => handleLocationClick(location.name)}
              className={`relative rounded-xl overflow-hidden cursor-pointer transition-all ${
                selectedLocation === location.name 
                  ? 'scale-[1.02]' 
                  : 'hover:shadow-md'
              }`}
            >
              <div className="aspect-square">
                <img
                  src={location.image}
                  alt={location.name}
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-black/30"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <h3 className="text-white font-bold text-lg md:text-xl">{location.name}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
        
        <div className="md:hidden">
          <Swiper
            modules={[Navigation]}
            slidesPerView={2.2}
            spaceBetween={12}
            className="location-swiper"
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            grabCursor={true}
          >
            {locations.map((location) => (
              <SwiperSlide key={location.name}>
                <div 
                  onClick={() => handleLocationClick(location.name)}
                  className="relative rounded-xl overflow-hidden cursor-pointer transition-all h-full"
                >
                  <div className="aspect-square">
                    <img
                      src={location.image}
                      alt={location.name}
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                    <div className="absolute inset-0 bg-black/30"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <h3 className="text-white font-bold text-lg">{location.name}</h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="md:hidden bg-white shadow-sm mb-2">
        <button
          onClick={() => setIsVillaCountExpanded(!isVillaCountExpanded)}
          className="w-full px-4 py-3 flex items-center justify-between"
        >
          <div className="flex items-center">
            <Building2 className="h-5 w-5 text-blue-600 mr-2" />
            <span className="font-medium text-gray-900">
              {filteredVillas.length} Villas Available
            </span>
          </div>
          <ChevronDown 
            className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${
              isVillaCountExpanded ? 'rotate-180' : ''
            }`}
          />
        </button>
        
        {isVillaCountExpanded && (
          <div className="px-4 py-3 border-t border-gray-100">
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Total Villas:</span>
                <span className="font-medium">{allVillas.length}</span>
              </div>
              {selectedLocation && (
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">In {selectedLocation}:</span>
                  <span className="font-medium">
                    {filteredVillas.filter(v => v.location === selectedLocation).length}
                  </span>
                </div>
              )}
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Available Now:</span>
                <span className="font-medium">{filteredVillas.length}</span>
              </div>
              <div className="mt-3">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Number of Bedrooms
                </label>
                <div className="grid grid-cols-3 gap-2">
                  {['1+', '2+', '3+', '4+', '5+', 'Any'].map((option) => (
                    <button
                      key={option}
                      onClick={() => setSelectedBedrooms(option === 'Any' ? '' : option.replace('+', ''))}
                      className={`py-2 px-3 rounded-lg text-sm font-medium transition-colors ${
                        (option === 'Any' && !selectedBedrooms) || 
                        (option !== 'Any' && selectedBedrooms === option.replace('+', ''))
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 md:py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredVillas.map((villa) => (
            <div 
              key={villa.id} 
              onClick={() => handleVillaClick(villa.id)}
              className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-[1.02] duration-300 cursor-pointer h-full"
            >
              <div className="relative">
                <img
                  src={villa.image}
                  alt={villa.title}
                  className="w-full h-64 object-cover"
                  loading="lazy"
                  decoding="async"
                />
                <button className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
                  <Heart className="h-5 w-5 text-gray-600" />
                </button>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">{villa.title}</h3>
                    <div className="flex items-center text-gray-600">
                      <MapPin className="h-4 w-4 mr-1" />
                      <span>{villa.location}</span>
                    </div>
                  </div>
                  <span className="text-2xl font-bold text-blue-600">{villa.price}/night</span>
                </div>
                <p className="text-gray-600 mb-4">{villa.description}</p>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4 text-gray-600">
                    <div className="flex items-center">
                      <Bed className="h-4 w-4 mr-1" />
                      <span>{villa.beds}</span>
                    </div>
                    <div className="flex items-center">
                      <Bath className="h-4 w-4 mr-1" />
                      <span>{villa.baths}</span>
                    </div>
                    <div className="flex items-center">
                      <Square className="h-4 w-4 mr-1" />
                      <span>{villa.area}</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-4">
                  {renderStars()}
                  <span className="font-medium ml-2">{villa.rating}</span>
                  <span className="text-gray-500 text-sm ml-1">({villa.reviews} reviews)</span>
                </div>
                <div className="flex flex-col space-y-3 mt-6">
                  <button 
                    onClick={(e) => handleBookDirect(e, villa.id)}
                    className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                  >
                    Book Direct
                  </button>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleVillaClick(villa.id);
                    }}
                    className="w-full bg-white border border-blue-600 text-blue-600 px-4 py-2 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
                  >
                    More Info
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-blue-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Looking for a Villa in Bali?</h2>
          <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
            We have more properties coming soon. Contact us to discuss your requirements and we'll help you find your perfect rental in Bali.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <button 
              onClick={handleInquire}
              className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
            >
              Contact Us
            </button>
            <a 
              href="/villas-for-rent"
              className="bg-blue-700 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-800 transition-colors"
            >
              View All Rentals
            </a>
          </div>
        </div>
      </div>

      {/* WhatsApp Popup */}
      {isWhatsAppPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsWhatsAppPopupOpen(false)}></div>
          <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-900">Contact Us</h2>
                <button 
                  onClick={() => setIsWhatsAppPopupOpen(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <p className="text-gray-600 mb-6">
                Get in touch with our team to discuss villa rentals in Bali.
              </p>
              <button 
                onClick={() => {
                  const message = encodeURIComponent("Hello, I would like to inquire about villa rentals in Bali.");
                  window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
                  setIsWhatsAppPopupOpen(false);
                }}
                className="w-full bg-green-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center mb-4"
              >
                <MessageSquare className="mr-2 h-5 w-5" />
                Chat on WhatsApp
              </button>
              <button 
                onClick={() => setIsWhatsAppPopupOpen(false)}
                className="w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <AgentPopup 
        agentName="The Bali Expert"
        isOpen={isAgentPopupOpen}
        onClose={() => setIsAgentPopupOpen(false)}
      />

      <VillaCasablancaPopup
        isOpen={isBookingPopupOpen}
        onClose={() => setIsBookingPopupOpen(false)}
      />

      <VillaEmiliaPopup
        isOpen={isEmiliaBookingPopupOpen}
        onClose={() => setIsEmiliaBookingPopupOpen(false)}
      />

      <VillaWayanPopup
        isOpen={isWayanBookingPopupOpen}
        onClose={() => setIsWayanBookingPopupOpen(false)}
      />
    </div>
  );
}