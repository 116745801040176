import React, { useState, useEffect } from 'react';
import { MapPin, Search, Calendar, Users, ArrowRight, Star, Heart, Bed, Bath, Square, Filter, ChevronDown, DollarSign } from 'lucide-react';
import AgentPopup from '../components/AgentPopup';

interface ComingSoonProps {
  location: string;
}

export default function ComingSoon({ location }: ComingSoonProps) {
  const [searchDates, setSearchDates] = useState('');
  const [searchGuests, setSearchGuests] = useState('');
  const [isAgentPopupOpen, setIsAgentPopupOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedBedrooms, setSelectedBedrooms] = useState('');
  const [selectedBudget, setSelectedBudget] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [userProperties, setUserProperties] = useState<any[]>([]);
  
  // Featured property data
  const featuredProperty = {
    id: 'villa-1',
    title: "Luxury Villa with Jungle Views",
    location: location,
    price: "From $450/night",
    beds: 4,
    baths: 4,
    area: "650 m²",
    image: "https://i.imghippo.com/files/cq8633Qs.jpeg",
    description: "Experience the ultimate in luxury living with this stunning villa rental, featuring panoramic jungle views, infinity pool, and world-class amenities."
  };

  // Sample villas data
  const villas = [
    {
      id: 'villa-1',
      title: 'Luxury Villa with Jungle Views',
      location: location,
      price: "From $450/night",
      beds: 4,
      baths: 4,
      area: "650 m²",
      image: "https://i.imghippo.com/files/cq8633Qs.jpeg",
      description: "Experience the ultimate in luxury living with this stunning villa rental, featuring panoramic jungle views, infinity pool, and world-class amenities.",
      rating: 4.9,
      reviews: 124
    },
    {
      id: 'villa-2',
      title: 'Modern Tropical Paradise',
      location: location,
      price: "From $380/night",
      beds: 3,
      baths: 3,
      area: "450 m²",
      image: "https://images.unsplash.com/photo-1582610116397-edb318620f90?auto=format&fit=crop&q=80",
      description: "Nestled in the heart of Ubud, this modern villa offers a perfect blend of luxury and nature with a private pool and lush garden.",
      rating: 4.8,
      reviews: 98
    },
    {
      id: 'villa-3',
      title: 'Riverside Retreat',
      location: location,
      price: "From $520/night",
      beds: 5,
      baths: 5,
      area: "800 m²",
      image: "https://images.unsplash.com/photo-1604999333679-b86d54738315?auto=format&fit=crop&q=80",
      description: "Perched on the edge of a river valley, this stunning villa offers breathtaking views and complete privacy in a lush tropical setting.",
      rating: 4.9,
      reviews: 87
    }
  ];

  // Load user-added properties from localStorage
  useEffect(() => {
    try {
      const storedProperties = localStorage.getItem('properties');
      if (storedProperties) {
        const parsedProperties = JSON.parse(storedProperties);
        
        // Filter properties by location
        const locationProperties = parsedProperties.filter((prop: any) => 
          prop.location.toLowerCase() === location.toLowerCase()
        );
        
        if (locationProperties.length > 0) {
          // Format properties for display
          const formattedProperties = locationProperties.map((prop: any) => ({
            id: prop.id,
            title: prop.title,
            location: prop.location,
            price: `$${prop.price}/month`,
            beds: parseInt(prop.bedrooms),
            baths: parseInt(prop.bathrooms),
            area: `${prop.area} m²`,
            image: prop.imageUrls && prop.imageUrls.length > 0 ? prop.imageUrls[0] : 'https://images.unsplash.com/photo-1582610116397-edb318620f90?auto=format&fit=crop&q=80',
            description: prop.description,
            rating: 5.0,
            reviews: 0,
            userAdded: true
          }));
          
          setUserProperties(formattedProperties);
        }
      }
    } catch (error) {
      console.error('Error loading properties from localStorage:', error);
    }
  }, [location]);

  const handleInquire = () => {
    const message = encodeURIComponent(`Hello, I would like to inquire about villa rentals in ${location}.`);
    window.open(`https://wa.me/6281574054569?text=${message}`, '_blank');
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // In a real app, this would filter based on dates and guests
    console.log('Searching with dates:', searchDates, 'and guests:', searchGuests);
  };

  const thumbnailUrl = `${featuredProperty.image}?w=20&q=10`;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Featured Property Section */}
      <div className="relative w-full">
        <div className="relative w-full pb-[100%] md:pb-[56.25%]">
          <div className="absolute inset-0 z-0">
            <div 
              className="absolute inset-0"
              style={{
                backgroundImage: `url(${thumbnailUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: !imageLoaded ? 'blur(20px)' : 'none',
                transition: 'filter 0.3s ease-out'
              }}
            />
            <img
              src={`${featuredProperty.image}?w=1920&q=80`}
              alt={featuredProperty.title}
              className={`w-full h-full object-cover transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setImageLoaded(true)}
              loading="eager"
              fetchpriority="high"
              decoding="async"
              sizes="100vw"
            />
          </div>
          
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-10"></div>
          
          <div className="absolute inset-0 flex items-center justify-center z-20">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center h-full">
              <div className="max-w-3xl md:mx-0">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-600 text-white mb-2 md:mb-4">
                  Featured Rental
                </span>
                <h1 className="text-3xl md:text-5xl font-bold text-white mb-2 md:mb-4">
                  {featuredProperty.title}
                </h1>
                <p className="text-base md:text-xl text-gray-200 mb-3 md:mb-6 line-clamp-3 md:line-clamp-none">
                  {featuredProperty.description}
                </p>
                <div className="flex flex-wrap items-center gap-2 md:gap-4 text-gray-300 mb-3 md:mb-6">
                  <div className="flex items-center">
                    <MapPin className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.location}</span>
                  </div>
                  <div className="flex items-center">
                    <Bed className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.beds} Bedrooms</span>
                  </div>
                  <div className="flex items-center">
                    <Bath className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.baths} Bathrooms</span>
                  </div>
                  <div className="flex items-center">
                    <Square className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">{featuredProperty.area}</span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-4 md:mb-8">
                  <span className="text-2xl md:text-3xl font-bold text-white">{featuredProperty.price}</span>
                  <button 
                    onClick={handleInquire}
                    className="bg-blue-600 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-700 transition-colors"
                  >
                    Inquire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Search and Filter Section */}
      <div className="bg-white shadow-md sticky top-20 z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <form onSubmit={handleSearch} className="flex flex-col md:flex-row gap-4">
            {/* Dates */}
            <div className="flex-1">
              <div className="relative">
                <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
                  <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                  <input
                    type="text"
                    placeholder="Check in - Check out"
                    className="w-full border-none p-0 focus:ring-0"
                    value={searchDates}
                    onChange={(e) => setSearchDates(e.target.value)}
                  />
                </div>
              </div>
            </div>
            
            {/* Guests */}
            <div className="flex-1">
              <div className="relative">
                <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
                  <Users className="h-5 w-5 text-gray-400 mr-2" />
                  <select
                    className="w-full border-none bg-transparent p-0 focus:ring-0"
                    value={searchGuests}
                    onChange={(e) => setSearchGuests(e.target.value)}
                  >
                    <option value="">Number of guests</option>
                    <option value="1-2">1-2 guests</option>
                    <option value="3-4">3-4 guests</option>
                    <option value="5-6">5-6 guests</option>
                    <option value="7+">7+ guests</option>
                  </select>
                </div>
              </div>
            </div>
            
            {/* Bedrooms */}
            <div className="flex-1">
              <div className="relative">
                <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
                  <Bed className="h-5 w-5 text-gray-400 mr-2" />
                  <select
                    className="w-full border-none bg-transparent p-0 focus:ring-0"
                    value={selectedBedrooms}
                    onChange={(e) => setSelectedBedrooms(e.target.value)}
                  >
                    <option value="">Bedrooms</option>
                    <option value="1">1+ bedroom</option>
                    <option value="2">2+ bedrooms</option>
                    <option value="3">3+ bedrooms</option>
                    <option value="4">4+ bedrooms</option>
                    <option value="5">5+ bedrooms</option>
                  </select>
                </div>
              </div>
            </div>
            
            {/* Budget */}
            <div className="flex-1">
              <div className="relative">
                <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2">
                  <DollarSign className="h-5 w-5 text-gray-400 mr-2" />
                  <select
                    className="w-full border-none bg-transparent p-0 focus:ring-0"
                    value={selectedBudget}
                    onChange={(e) => setSelectedBudget(e.target.value)}
                  >
                    <option value="">Budget per night</option>
                    <option value="0-200">Under $200</option>
                    <option value="200-400">$200 - $400</option>
                    <option value="400-600">$400 - $600</option>
                    <option value="600-1000">$600 - $1000</option>
                    <option value="1000-99999">$1000+</option>
                  </select>
                </div>
              </div>
            </div>
            
            {/* Search Button */}
            <div>
              <button 
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center"
              >
                <Search className="h-5 w-5 mr-2" />
                Search
              </button>
            </div>
          </form>
          
          {/* Mobile Filter Toggle */}
          <div className="md:hidden mt-4">
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className="w-full flex items-center justify-center gap-2 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium py-2 px-4 rounded-lg transition-colors"
            >
              <Filter className="h-5 w-5" />
              Filters
              <ChevronDown className={`h-5 w-5 transition-transform ${isFilterOpen ? 'rotate-180' : ''}`} />
            </button>
          </div>
          
          {/* Mobile Filters (Expandable) */}
          {isFilterOpen && (
            <div className="md:hidden mt-4 space-y-4 border-t pt-4">
              {/* Bedrooms Filter */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Bedrooms</label>
                <select
                  value={selectedBedrooms}
                  onChange={(e) => setSelectedBedrooms(e.target.value)}
                  className="w-full rounded-lg border-gray-300 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Any</option>
                  <option value="1">1+ bedroom</option>
                  <option value="2">2+ bedrooms</option>
                  <option value="3">3+ bedrooms</option>
                  <option value="4">4+ bedrooms</option>
                  <option value="5">5+ bedrooms</option>
                </select>
              </div>
              
              {/* Budget Filter */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Budget per night</label>
                <select
                  value={selectedBudget}
                  onChange={(e) => setSelectedBudget(e.target.value)}
                  className="w-full rounded-lg border-gray-300 text-gray-700 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Any</option>
                  <option value="0-200">Under $200</option>
                  <option value="200-400">$200 - $400</option>
                  <option value="400-600">$400 - $600</option>
                  <option value="600-1000">$600 - $1000</option>
                  <option value="1000-99999">$1000+</option>
                </select>
              </div>
              
              {/* Clear Filters Button */}
              {(selectedBedrooms || selectedBudget) && (
                <div>
                  <button
                    onClick={() => {
                      setSelectedBedrooms('');
                      setSelectedBudget('');
                    }}
                    className="w-full px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors"
                  >
                    Clear All Filters
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Villas Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">Villas For Rent in {location}</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* User-added properties */}
          {userProperties.map((villa) => (
            <div 
              key={villa.id} 
              className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-[1.02] duration-300 cursor-pointer"
            >
              <div className="relative">
                <img
                  src={villa.image}
                  alt={villa.title}
                  className="w-full h-64 object-cover"
                  loading="lazy"
                  decoding="async"
                />
                <button className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
                  <Heart className="h-5 w-5 text-gray-600" />
                </button>
                <div className="absolute top-4 left-4 bg-green-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                  NEW LISTING
                </div>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">{villa.title}</h3>
                    <div className="flex items-center text-gray-600">
                      <MapPin className="h-4 w-4 mr-1" />
                      <span>{villa.location}</span>
                    </div>
                  </div>
                  <span className="text-2xl font-bold text-blue-600">{villa.price}</span>
                </div>
                <p className="text-gray-600 mb-4">{villa.description}</p>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4 text-gray-600">
                    <div className="flex items-center">
                      <Bed className="h-4 w-4 mr-1" />
                      <span>{villa.beds}</span>
                    </div>
                    <div className="flex items-center">
                      <Bath className="h-4 w-4 mr-1" />
                      <span>{villa.baths}</span>
                    </div>
                    <div className="flex items-center">
                      <Square className="h-4 w-4 mr-1" />
                      <span>{villa.area}</span>
                    </div>
                  </div>
                </div>
                <button 
                  onClick={handleInquire}
                  className="mt-6 w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                >
                  Inquire Now
                </button>
              </div>
            </div>
          ))}

          {/* Sample villas with Coming Soon overlay */}
          {villas.map((villa) => (
            <div 
              key={villa.id} 
              className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-[1.02] duration-300 cursor-pointer"
            >
              <div className="relative">
                <img
                  src={villa.image}
                  alt={villa.title}
                  className="w-full h-64 object-cover"
                  loading="lazy"
                  decoding="async"
                />
                <button className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
                  <Heart className="h-5 w-5 text-gray-600" />
                </button>
                <div className="coming-soon-overlay">
                  <div className="coming-soon-text">Coming Soon</div>
                </div>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">{villa.title}</h3>
                    <div className="flex items-center text-gray-600">
                      <MapPin className="h-4 w-4 mr-1" />
                      <span>{villa.location}</span>
                    </div>
                  </div>
                  <span className="text-2xl font-bold text-blue-600">{villa.price}</span>
                </div>
                <p className="text-gray-600 mb-4">{villa.description}</p>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4 text-gray-600">
                    <div className="flex items-center">
                      <Bed className="h-4 w-4 mr-1" />
                      <span>{villa.beds}</span>
                    </div>
                    <div className="flex items-center">
                      <Bath className="h-4 w-4 mr-1" />
                      <span>{villa.baths}</span>
                    </div>
                    <div className="flex items-center">
                      <Square className="h-4 w-4 mr-1" />
                      <span>{villa.area}</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-4">
                  <Star className="h-4 w-4 text-yellow-400 fill-yellow-400 mr-1" />
                  <span className="font-medium">{villa.rating}</span>
                  <span className="text-gray-500 text-sm ml-1">({villa.reviews} reviews)</span>
                </div>
                <button 
                  onClick={handleInquire}
                  className="mt-6 w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                >
                  Inquire Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Looking for a Rental in {location}?</h2>
          <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
            We have more properties coming soon. Contact us to discuss your requirements and we'll help you find your perfect rental in {location}.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <a 
              href={`https://wa.me/6281574054569?text=Hello%2C%20I%20am%20interested%20in%20rental%20properties%20in%20${location}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
            >
              Contact Us
            </a>
            <a 
              href="/villas-for-rent"
              className="bg-blue-700 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-800 transition-colors"
            >
              View All Rentals
            </a>
          </div>
        </div>
      </div>

      {/* Agent Popup */}
      <AgentPopup 
        agentName="The Bali Expert"
        isOpen={isAgentPopupOpen}
        onClose={() => setIsAgentPopupOpen(false)}
      />
    </div>
  );
}