import React from 'react';
import { X, MessageSquare, Mail } from 'lucide-react';
import { getListingAgent } from '../data/listingAgents';

interface ContactPopupProps {
  agentName: string;
  propertyTitle: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function ContactPopup({ agentName, propertyTitle, isOpen, onClose }: ContactPopupProps) {
  if (!isOpen) return null;

  const agent = getListingAgent(agentName);
  if (!agent) return null;

  const whatsappMessage = encodeURIComponent(`Hello, I am interested in the ${propertyTitle} listing on thebaliexpert.com`);
  const whatsappUrl = `https://wa.me/${agent.whatsapp}?text=${whatsappMessage}`;
  const emailSubject = encodeURIComponent(`Inquiry about ${propertyTitle}`);
  const emailBody = encodeURIComponent(`Hello,\n\nI am interested in the ${propertyTitle} listing I saw on thebaliexpert.com.\n\nPlease provide more information.\n\nThank you.`);
  const emailUrl = `mailto:${agent.email}?subject=${emailSubject}&body=${emailBody}`;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="relative bg-white rounded-xl shadow-xl max-w-lg w-full mx-4 overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-900">Contact {agent.name}</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-6">
          <p className="text-gray-700 leading-relaxed mb-6">
            Contact {agentName} directly about the {propertyTitle}.
          </p>
          <div className="space-y-4">
            <a 
              href={whatsappUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-full bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-colors"
            >
              <MessageSquare className="h-5 w-5 mr-2" />
              Contact via WhatsApp
            </a>
            <a 
              href={emailUrl}
              className="flex items-center justify-center w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Mail className="h-5 w-5 mr-2" />
              Contact via Email
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}