import React, { useState } from 'react';
import { MapPin, Bed, Bath, Square, Building2, DollarSign, TrendingUp, Shield, Map, Lock, Calendar } from 'lucide-react';
import ImageCarousel from '../components/ImageCarousel';
import ThumbnailCarousel from '../components/ThumbnailCarousel';
import ContactPopup from '../components/ContactPopup';
import AgentPopup from '../components/AgentPopup';

const images = [
  "https://i.imghippo.com/files/qrD8456RvU.jpeg",
  "https://i.imghippo.com/files/Fwue7238KGs.jpeg",
  "https://i.imghippo.com/files/wnaP8226QHg.jpeg",
  "https://i.imghippo.com/files/BdZQ7127Lc.jpeg",
  "https://i.imghippo.com/files/SA9693cUo.jpeg",
  "https://i.imghippo.com/files/lVS7298zg.jpeg",
  "https://i.imghippo.com/files/NXG9442L.jpeg"
];

export default function BoutiqueHotel() {
  const [contactPopupOpen, setContactPopupOpen] = useState(false);
  const [agentPopupOpen, setAgentPopupOpen] = useState(false);
  const agentName = "Ubud Homes";
  const propertyTitle = "Sayan Ridge Boutique Hotel";

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section with Carousel */}
      <div className="relative w-full">
        <div className="relative w-full pb-[100%] md:pb-[56.25%]">
          {/* Image Carousel */}
          <div className="absolute inset-0 z-0">
            <ImageCarousel images={images} />
          </div>
          
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-10"></div>
          
          {/* Content */}
          <div className="absolute inset-0 flex items-end z-20">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16 pt-20 md:pt-0">
              <div className="max-w-3xl">
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-600 text-white mb-2 md:mb-4">
                  Featured Property
                </span>
                <h1 className="text-3xl md:text-5xl font-bold text-white mb-2 md:mb-4">
                  Sayan Ridge Boutique Hotel
                </h1>
                <div className="flex flex-wrap items-center gap-2 md:gap-4 text-gray-300 mb-3 md:mb-6">
                  <div className="flex items-center">
                    <MapPin className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">Ubud</span>
                  </div>
                  <div className="flex items-center">
                    <Bed className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">6 Bedrooms</span>
                  </div>
                  <div className="flex items-center">
                    <Bath className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">6 Bathrooms</span>
                  </div>
                  <div className="flex items-center">
                    <Square className="h-4 w-4 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-sm md:text-base">950 m²</span>
                  </div>
                </div>
                <p className="text-base md:text-xl text-gray-200 mb-4 md:mb-8 line-clamp-3 md:line-clamp-none">
                  Newly built luxury boutique hotel in Ubud's most prestigious area. Features 4 private villas, pools, and stunning views.
                </p>
                <div className="mb-3 md:mb-6">
                  <span className="text-2xl md:text-3xl font-bold text-white">$1,850,000 USD</span>
                  <div className="mt-1 md:mt-2 text-gray-300 text-sm md:text-base">
                    <span className="font-semibold">Listed by: </span>
                    <button 
                      onClick={() => setAgentPopupOpen(true)}
                      className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                    >
                      {agentName}
                    </button>
                  </div>
                </div>
                <div className="flex flex-wrap gap-3 md:gap-4">
                  <button 
                    onClick={() => setContactPopupOpen(true)}
                    className="bg-blue-600 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base font-semibold hover:bg-blue-700 transition-colors"
                  >
                    Contact Agent
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-12">
        {/* Thumbnail Carousel */}
        <ThumbnailCarousel images={images} />

        {/* Overview Section */}
        <section className="mt-12 md:mt-16 mb-12 md:mb-16">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">Property Overview</h2>
          <div className="bg-blue-50 border-l-4 border-blue-600 p-3 md:p-4 mb-4 md:mb-6">
            <p className="text-base md:text-lg font-semibold text-blue-900">
              Newly built in the most prestigious and scenic area of Bali's most popular tourist destination
            </p>
          </div>
          <div className="space-y-3 md:space-y-4 text-gray-600">
            <p className="text-sm md:text-base">
              Designed for effortless ownership, this boutique estate offers a private, fully managed retreat where your family can enjoy luxury living without the burden of maintenance—while generating strong, passive income when not in use.
            </p>
            <p className="font-semibold text-blue-900 text-sm md:text-base">
              Feels like a home and earns like a business
            </p>
            <p className="font-bold text-blue-900 text-sm md:text-base">
              Easily managed and highly profitable
            </p>
          </div>
        </section>

        {/* Property Features Section */}
        <section className="mb-12 md:mb-16">
          <div className="bg-gray-50 p-4 md:p-8 rounded-xl">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">Property Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
              {/* First Column */}
              <div className="space-y-3 md:space-y-4">
                {[
                  "950m² of land",
                  "795m² total build-out",
                  "One two bedroom villa",
                  "Three one bedroom villas"
                ].map((feature, index) => (
                  <div key={index} className="flex items-center bg-white p-3 md:p-4 rounded-lg">
                    <span className="h-2 w-2 bg-blue-600 rounded-full mr-2 md:mr-3"></span>
                    <span className="text-gray-700 text-sm md:text-base">{feature}</span>
                  </div>
                ))}
              </div>

              {/* Second Column */}
              <div className="space-y-3 md:space-y-4">
                {[
                  "Four private pools",
                  "Four kitchens",
                  "Fully furnished",
                  "Reception & office w/ bathroom"
                ].map((feature, index) => (
                  <div key={index} className="flex items-center bg-white p-3 md:p-4 rounded-lg">
                    <span className="h-2 w-2 bg-blue-600 rounded-full mr-2 md:mr-3"></span>
                    <span className="text-gray-700 text-sm md:text-base">{feature}</span>
                  </div>
                ))}
              </div>

              {/* Third Column */}
              <div className="space-y-3 md:space-y-4 md:col-span-2 lg:col-span-1">
                {[
                  "Car parking",
                  "Enclosable living areas",
                  "High speed internet",
                  "Elevator"
                ].map((feature, index) => (
                  <div key={index} className="flex items-center bg-white p-3 md:p-4 rounded-lg">
                    <span className="h-2 w-2 bg-blue-600 rounded-full mr-2 md:mr-3"></span>
                    <span className="text-gray-700 text-sm md:text-base">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Investment Details Section */}
        <section className="mb-12 md:mb-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-start">
            <div className="bg-blue-600 text-white p-6 md:p-8 rounded-xl">
              <h3 className="text-xl md:text-2xl font-semibold mb-3 md:mb-4">Investment Details</h3>
              <div className="space-y-2 md:space-y-3 text-base md:text-lg">
                <p>Price: $1,850,000 USD</p>
                <p>18 year lease with guaranteed 10 year extension</p>
                <p>Zoning: Tourist development/residential</p>
                <p>Includes all necessary licenses and permits</p>
              </div>
            </div>
            <div className="bg-white p-6 md:p-8 rounded-xl shadow-lg">
              <h3 className="text-xl md:text-2xl font-semibold mb-3 md:mb-4 text-gray-900">Interested in this property?</h3>
              <p className="text-gray-600 mb-4 md:mb-6 text-sm md:text-base">
                Contact {agentName} directly about the {propertyTitle}.
              </p>
              <button 
                onClick={() => setContactPopupOpen(true)}
                className="w-full bg-blue-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors text-base md:text-lg"
              >
                Request More Information
              </button>
            </div>
          </div>
        </section>

        {/* Investment Highlights Section */}
        <section>
          <h2 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8">Investment Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
            <div className="bg-white p-4 md:p-6 rounded-xl shadow-sm">
              <div className="flex items-center mb-3 md:mb-4">
                <DollarSign className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <h3 className="text-lg md:text-xl font-bold ml-2 md:ml-3">15%+ ROI</h3>
              </div>
              <p className="text-gray-600 text-sm md:text-base">
                Comfortably enjoy a 15%+ annual return on investment (ROI) or more. Premium one-bedroom pool villas at boutique hotels in Ubud command between $200 to $600 per night depending on amenities, seasonality and location.
              </p>
            </div>
            <div className="bg-white p-4 md:p-6 rounded-xl shadow-sm">
              <div className="flex items-center mb-3 md:mb-4">
                <TrendingUp className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <h3 className="text-lg md:text-xl font-bold ml-2 md:ml-3">High Occupancy Potential</h3>
              </div>
              <p className="text-gray-600 text-sm md:text-base">
                Bali's luxury rental market is booming, with consistent demand for high-end private stays. Ubud attracts over 3 million foreign tourists annually, drawn to its unique blend of culture and natural beauty.
              </p>
            </div>
            <div className="bg-white p-4 md:p-6 rounded-xl shadow-sm">
              <div className="flex items-center mb-3 md:mb-4">
                <Shield className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <h3 className="text-lg md:text-xl font-bold ml-2 md:ml-3">Fully Managed (Optional)</h3>
              </div>
              <p className="text-gray-600 text-sm md:text-base">
                Running a boutique hotel in Bali is effortless and cost-effective, with low staffing costs and affordable property management services ensuring seamless operation and high profitability.
              </p>
            </div>
            <div className="bg-white p-4 md:p-6 rounded-xl shadow-sm">
              <div className="flex items-center mb-3 md:mb-4">
                <Map className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <h3 className="text-lg md:text-xl font-bold ml-2 md:ml-3">Incredible Location</h3>
              </div>
              <p className="text-gray-600 text-sm md:text-base">
                Sayan Ridge is Ubud's most prestigious area, offering unmatched privacy and breathtaking views over the Ayung River Valley. Wake up to misty sunrises, lush jungle canopies, and absolute tranquility—all just minutes from world-class dining, wellness retreats, and Ubud's vibrant culture.
              </p>
            </div>
            <div className="bg-white p-4 md:p-6 rounded-xl shadow-sm">
              <div className="flex items-center mb-3 md:mb-4">
                <Lock className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <h3 className="text-lg md:text-xl font-bold ml-2 md:ml-3">Long-term Security</h3>
              </div>
              <p className="text-gray-600 text-sm md:text-base">
                As Ubud's property values continue to rise, this estate offers not just a home, but a rare opportunity to own a high-appreciation asset in a thriving market. With 18+ years remaining on a secure lease—plus a guaranteed 10-year extension—you'll enjoy long-term stability, strong returns, and the luxury of a private retreat.
              </p>
            </div>
            <div className="bg-white p-4 md:p-6 rounded-xl shadow-sm">
              <div className="flex items-center mb-3 md:mb-4">
                <Calendar className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <h3 className="text-lg md:text-xl font-bold ml-2 md:ml-3">Multi-use Potential</h3>
              </div>
              <p className="text-gray-600 text-sm md:text-base">
                Operate as a boutique hotel, luxury retreat, or private residence with flexible rental models that maximize revenue year-round. As the most famous island in the world, Bali attracts North American travelers in summer and European, Australian, and Asian visitors in winter.
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Contact Popup */}
      <ContactPopup 
        agentName={agentName}
        propertyTitle={propertyTitle}
        isOpen={contactPopupOpen}
        onClose={() => setContactPopupOpen(false)}
      />

      {/* Agent Popup */}
      <AgentPopup 
        agentName={agentName}
        isOpen={agentPopupOpen}
        onClose={() => setAgentPopupOpen(false)}
      />
    </div>
  );
}