import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { MapPin, Star, Check, ArrowRight, Clock } from 'lucide-react';

const events = {
  'nyepi-day': {
    title: "Nyepi Day (Day of Silence)",
    date: "March 29, 2025",
    time: "6:00 AM – 6:00 AM (next day)",
    location: "Island-wide",
    image: "https://i.imghippo.com/files/sWpU8940oiQ.jpg",
    description: "Experience the unique Day of Silence where the entire island comes to a complete standstill for 24 hours of self-reflection and spiritual purification.",
    category: "Cultural",
    duration: "24 hours",
    features: [
      "Complete island silence",
      "No lights or fires",
      "No travel or activities",
      "Time for meditation",
      "Self-reflection",
      "Traditional ceremonies"
    ],
    schedule: [
      {
        day: "March 28",
        title: "Melasti Ceremony",
        description: "Purification ceremonies at beaches and waterways"
      },
      {
        day: "March 28 Evening",
        title: "Pengrupukan",
        description: "Ogoh-ogoh parades and ceremonies"
      },
      {
        day: "March 29",
        title: "Nyepi Day",
        description: "24 hours of complete silence and self-reflection"
      }
    ],
    experiences: [
      {
        title: "Cultural Immersion",
        description: "Experience Bali's unique cultural tradition"
      },
      {
        title: "Spiritual Practice",
        description: "Perfect time for meditation and reflection"
      },
      {
        title: "Environmental Impact",
        description: "Witness clear skies and reduced pollution"
      },
      {
        title: "Traditional Observance",
        description: "Participate in Balinese Hindu practices"
      }
    ]
  },
  'galungan': {
    title: "Galungan",
    date: "March 11, 2025",
    time: "All Day",
    location: "Island-wide",
    image: "https://www.imghippo.com/i/ZWXy7189DW.jpg",
    description: "One of Bali's most important religious festivals celebrating the victory of dharma over adharma. Witness beautiful decorations and traditional ceremonies.",
    category: "Cultural",
    duration: "10 days",
    features: [
      "Penjor decorations",
      "Temple ceremonies",
      "Traditional offerings",
      "Family gatherings",
      "Cultural performances",
      "Special prayers"
    ],
    schedule: [
      {
        day: "March 11",
        title: "Galungan Day",
        description: "Main celebration with temple visits and family gatherings"
      },
      {
        day: "March 12-20",
        title: "Manis Galungan",
        description: "Continued celebrations and ceremonies"
      }
    ],
    experiences: [
      {
        title: "Temple Visits",
        description: "Visit beautifully decorated temples across the island"
      },
      {
        title: "Cultural Activities",
        description: "Witness traditional ceremonies and performances"
      },
      {
        title: "Local Cuisine",
        description: "Taste special Galungan ceremonial dishes"
      },
      {
        title: "Community Celebration",
        description: "Experience Balinese community spirit"
      }
    ]
  },
  'kuningan': {
    title: "Kuningan",
    date: "March 21, 2025",
    time: "All Day",
    location: "Island-wide",
    image: "https://i.imghippo.com/files/ZWXy7189DW.jpg",
    description: "The final day of the Galungan festival period, marking the descent of ancestral spirits back to their realm.",
    category: "Cultural",
    duration: "1 day",
    features: [
      "Special offerings",
      "Temple ceremonies",
      "Traditional rituals",
      "Family gatherings",
      "Yellow rice offerings",
      "Closing ceremonies"
    ],
    schedule: [
      {
        day: "March 21 Morning",
        title: "Morning Ceremonies",
        description: "Special offerings and prayers at family temples"
      },
      {
        day: "March 21 Afternoon",
        title: "Community Gatherings",
        description: "Shared meals and closing ceremonies"
      }
    ],
    experiences: [
      {
        title: "Religious Ceremonies",
        description: "Participate in special Kuningan prayers"
      },
      {
        title: "Traditional Offerings",
        description: "Learn about yellow rice offerings significance"
      },
      {
        title: "Cultural Experience",
        description: "Witness the closing of Galungan period"
      },
      {
        title: "Community Spirit",
        description: "Join in community celebrations"
      }
    ]
  },
  'bali-spirit-festival': {
    title: "Bali Spirit Festival",
    date: "March 29 - April 6, 2025",
    time: "7:00 AM - 11:00 PM",
    location: "Ubud",
    image: "https://images.unsplash.com/photo-1506126613408-eca07ce68773?auto=format&fit=crop&q=80",
    description: "Join the world's most inspiring celebration of yoga, dance, and music in the heart of Bali.",
    category: "Festival",
    duration: "9 days",
    features: [
      "Yoga workshops",
      "Dance classes",
      "Live music performances",
      "Healing sessions",
      "Meditation practices",
      "Wellness workshops"
    ],
    schedule: [
      {
        day: "Daily Schedule",
        title: "Morning Sessions",
        description: "Yoga, meditation, and movement practices"
      },
      {
        day: "Afternoons",
        title: "Workshops & Classes",
        description: "Various workshops, dance classes, and healing sessions"
      },
      {
        day: "Evenings",
        title: "Performances",
        description: "Live music, dance performances, and community gatherings"
      }
    ],
    experiences: [
      {
        title: "Yoga & Movement",
        description: "Classes with world-renowned instructors"
      },
      {
        title: "Music & Dance",
        description: "Performances from global artists"
      },
      {
        title: "Healing & Wellness",
        description: "Various healing modalities and workshops"
      },
      {
        title: "Community",
        description: "Connect with like-minded individuals"
      }
    ]
  }
};

function EventDetails() {
  const { eventId } = useParams();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!eventId || !events[eventId as keyof typeof events]) {
    return <Navigate to="/events" replace />;
  }

  const event = events[eventId as keyof typeof events];
  const thumbnailUrl = `${event.image}?w=20&q=10`;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[70vh]">
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: !imageLoaded ? 'blur(20px)' : 'none',
              transition: 'filter 0.3s ease-out'
            }}
          />
          <img
            src={event.image}
            alt={event.title}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
            loading="eager"
            fetchpriority="high"
            decoding="async"
            sizes="100vw"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex flex-col justify-end h-full pb-16">
            <div className="max-w-3xl">
              <div className="flex items-center space-x-4 mb-4">
                <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                  {event.category}
                </span>
                <span className="text-blue-400 font-medium flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {event.date}
                </span>
              </div>
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">
                {event.title}
              </h1>
              <div className="flex items-center space-x-6 text-gray-300 mb-6">
                <div className="flex items-center">
                  <MapPin className="h-5 w-5 mr-2" />
                  <span>{event.location}</span>
                </div>
                <div className="flex items-center">
                  <Clock className="h-5 w-5 mr-2" />
                  <span>{event.duration}</span>
                </div>
              </div>
              <p className="text-xl text-gray-200 mb-8">
                {event.description}
              </p>
              <button 
                className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors inline-flex items-center"
              >
                Learn More
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Event Details */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Details */}
          <div className="lg:col-span-2 space-y-8">
            {/* Features */}
            <div className="mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-8">Event Features</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {event.features.map((feature, index) => (
                  <div key={index} className="flex items-center bg-white p-4 rounded-lg shadow-sm">
                    <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                    <span className="text-gray-700">{feature}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Schedule */}
            <div className="mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-8">Schedule</h2>
              <div className="space-y-6">
                {event.schedule.map((item, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                    <div className="flex items-center mb-4">
                      <Clock className="h-5 w-5 text-blue-600 mr-2" />
                      <h3 className="text-xl font-bold text-gray-900">{item.day}</h3>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">{item.title}</h4>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Experiences */}
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-8">What to Expect</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {event.experiences.map((exp, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                    <h3 className="text-xl font-bold text-gray-900 mb-3">{exp.title}</h3>
                    <p className="text-gray-600">{exp.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column - Pricing */}
          <div className="space-y-6">
            {/* Right column content would go here */}
          </div>
        </div>

        {/* Bottom CTA Section */}
        <div className="mt-16 text-center">
          <button 
            className="bg-blue-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-700 transition-colors text-lg"
          >
            Learn More
            <ArrowRight className="inline-block ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default EventDetails;