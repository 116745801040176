import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Package, Edit, Plus, Crown, Award, Shield, ArrowUpRight, Trash2, Building2 } from 'lucide-react';
import MembershipBadge from '../components/MembershipBadge';
import CheckoutPopup from '../components/CheckoutPopup';
import UpgradeSuccessPopup from '../components/UpgradeSuccessPopup';

interface Listing {
  id: string;
  title: string;
  location: string;
  price: string;
  image: string;
  createdAt: string;
  status: 'active' | 'pending' | 'inactive';
}

export default function Dashboard() {
  const [userData, setUserData] = useState<any>(null);
  const [listings, setListings] = useState<Listing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [targetPlan, setTargetPlan] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in
    const user = localStorage.getItem('user');
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      const parsedUser = JSON.parse(user);
      setUserData(parsedUser);

      // Get user's listings from localStorage
      const allProperties = JSON.parse(localStorage.getItem('properties') || '[]');
      const userListings = allProperties.filter((property: any) => 
        property.contactEmail === parsedUser.email
      );

      // Format listings
      const formattedListings = userListings.map((listing: any) => ({
        id: listing.id,
        title: listing.title,
        location: listing.location,
        price: `$${listing.price}/month`,
        image: listing.imageUrls && listing.imageUrls.length > 0 
          ? listing.imageUrls[0] 
          : 'https://images.unsplash.com/photo-1582610116397-edb318620f90?auto=format&fit=crop&q=80',
        createdAt: listing.createdAt || new Date().toISOString(),
        status: 'active' as const
      }));

      setListings(formattedListings);
      setIsLoading(false);
    } catch (error) {
      console.error('Error parsing user data:', error);
      navigate('/login');
    }
  }, [navigate]);

  const handleUpgrade = (plan: string) => {
    // Set the target plan and open the checkout popup
    setTargetPlan(plan);
    setIsCheckoutOpen(true);
  };

  const handleCheckoutComplete = (success: boolean) => {
    setIsCheckoutOpen(false);
    
    if (success) {
      // Update user's plan in localStorage
      const updatedUser = { ...userData, membershipPlan: targetPlan };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      setUserData(updatedUser);
      
      // Show success popup
      setIsSuccessOpen(true);
    }
  };

  const handleAddListing = () => {
    navigate('/add-property');
  };

  const handleEditListing = (listingId: string) => {
    // In a real app, this would navigate to an edit page with the listing ID
    navigate(`/edit-property/${listingId}`);
  };

  const handleDeleteListing = (listingId: string) => {
    // Remove the listing from localStorage
    const allProperties = JSON.parse(localStorage.getItem('properties') || '[]');
    const updatedProperties = allProperties.filter((property: any) => property.id !== listingId);
    localStorage.setItem('properties', JSON.stringify(updatedProperties));
    
    // Update the listings state
    setListings(listings.filter(listing => listing.id !== listingId));
  };

  const getMaxListings = () => {
    switch (userData?.membershipPlan) {
      case 'premium':
        return 4;
      case 'standard':
        return 1;
      default:
        return 0;
    }
  };

  const canAddListing = () => {
    const maxListings = getMaxListings();
    return listings.length < maxListings;
  };

  const getMembershipFeatures = () => {
    switch (userData?.membershipPlan) {
      case 'premium':
        return [
          '1 Instagram story per villa/month (Up to 4 villas)',
          'Villa added to the Villas Highlight with a direct booking link',
          'Villas listed on website with paid traffic and direct booking links',
          'WhatsApp Rental Group Blasts for each villa',
          'Up to 4 villa listings'
        ];
      case 'standard':
        return [
          '1 Instagram story per month featuring your villa',
          'Villa added to the Villas Highlight with a direct booking link',
          'Villa listed on website with paid traffic and a direct booking link',
          'WhatsApp Rental Group Blast featuring your villa',
          '1 villa listing'
        ];
      default:
        return [
          '1 Instagram story per month featuring your villa',
          'Villa added to the Villas Highlight with a direct booking link',
          'No villa listings'
        ];
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex items-center mb-4 md:mb-0">
              <div className="bg-blue-100 p-3 rounded-full mr-4">
                <User className="h-8 w-8 text-blue-600" />
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{userData.name}</h1>
                <p className="text-gray-600">{userData.email}</p>
              </div>
            </div>
            <div className="flex items-center">
              <Package className="h-5 w-5 text-blue-600 mr-2" />
              <span className="text-gray-700 mr-3">Current Plan:</span>
              <MembershipBadge plan={userData.membershipPlan || 'basic'} size="lg" />
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Membership Info */}
          <div className="lg:col-span-1 space-y-6">
            {/* Membership Card */}
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className={`p-6 ${
                userData.membershipPlan === 'premium' 
                  ? 'bg-gradient-to-r from-amber-500 to-yellow-400 text-white' 
                  : userData.membershipPlan === 'standard'
                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
                    : 'bg-gradient-to-r from-gray-500 to-gray-600 text-white'
              }`}>
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold">
                    {userData.membershipPlan === 'premium' 
                      ? 'Premium Plan' 
                      : userData.membershipPlan === 'standard'
                        ? 'Standard Plan'
                        : 'Basic Plan'}
                  </h2>
                  {userData.membershipPlan === 'premium' 
                    ? <Crown className="h-6 w-6" />
                    : userData.membershipPlan === 'standard'
                      ? <Award className="h-6 w-6" />
                      : <Shield className="h-6 w-6" />}
                </div>
                <div className="text-2xl font-bold mb-1">
                  {userData.membershipPlan === 'premium' 
                    ? '$299' 
                    : userData.membershipPlan === 'standard'
                      ? '$150'
                      : '$99'}
                  <span className="text-sm font-normal opacity-80">/month</span>
                </div>
                <div className="text-sm opacity-90">
                  {userData.membershipPlan === 'premium' 
                    ? 'Up to 4 villa listings' 
                    : userData.membershipPlan === 'standard'
                      ? '1 villa listing'
                      : 'No villa listings'}
                </div>
              </div>
              <div className="p-6">
                <h3 className="font-semibold text-gray-900 mb-4">Your Plan Includes:</h3>
                <ul className="space-y-3">
                  {getMembershipFeatures().map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <div className="h-5 w-5 text-green-500 mr-2 flex-shrink-0">✓</div>
                      <span className="text-gray-600 text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>

                {/* Upgrade Options */}
                {userData.membershipPlan !== 'premium' && (
                  <div className="mt-6 space-y-3">
                    {userData.membershipPlan !== 'standard' && (
                      <button
                        onClick={() => handleUpgrade('standard')}
                        className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center"
                      >
                        <Award className="h-4 w-4 mr-2" />
                        Upgrade to Standard
                      </button>
                    )}
                    <button
                      onClick={() => handleUpgrade('premium')}
                      className="w-full bg-amber-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-amber-600 transition-colors flex items-center justify-center"
                    >
                      <Crown className="h-4 w-4 mr-2" />
                      Upgrade to Premium
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Account Stats */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Account Stats</h2>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Active Listings</span>
                  <span className="font-semibold">{listings.length}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Maximum Listings</span>
                  <span className="font-semibold">{getMaxListings()}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Member Since</span>
                  <span className="font-semibold">
                    {new Date().toLocaleDateString('en-US', { 
                      month: 'long', 
                      year: 'numeric' 
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Listings */}
          <div className="lg:col-span-2 space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-gray-900">Your Listings</h2>
                {canAddListing() ? (
                  <button
                    onClick={handleAddListing}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center"
                  >
                    <Plus className="h-4 w-4 mr-1" />
                    Add Listing
                  </button>
                ) : userData.membershipPlan === 'basic' ? (
                  <div className="text-sm text-gray-500">
                    Upgrade to add listings
                  </div>
                ) : (
                  <div className="text-sm text-gray-500">
                    Maximum listings reached
                  </div>
                )}
              </div>

              {listings.length > 0 ? (
                <div className="space-y-4">
                  {listings.map((listing) => (
                    <div key={listing.id} className="border border-gray-200 rounded-lg overflow-hidden">
                      <div className="flex flex-col md:flex-row">
                        <div className="md:w-1/3 h-48 md:h-auto">
                          <img 
                            src={listing.image} 
                            alt={listing.title} 
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="md:w-2/3 p-4 flex flex-col">
                          <div className="flex-grow">
                            <div className="flex justify-between items-start mb-2">
                              <h3 className="text-lg font-bold text-gray-900">{listing.title}</h3>
                              <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
                                Active
                              </span>
                            </div>
                            <div className="flex items-center text-gray-600 text-sm mb-2">
                              {listing.location}
                            </div>
                            <div className="text-lg font-bold text-blue-600 mb-4">
                              {listing.price}
                            </div>
                            <div className="text-xs text-gray-500">
                              Listed on {new Date(listing.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                          <div className="flex justify-end space-x-2 mt-4">
                            <button
                              onClick={() => handleDeleteListing(listing.id)}
                              className="bg-red-50 text-red-600 px-3 py-1 rounded-lg text-sm font-medium hover:bg-red-100 transition-colors flex items-center"
                            >
                              <Trash2 className="h-4 w-4 mr-1" />
                              Delete
                            </button>
                            <button
                              onClick={() => handleEditListing(listing.id)}
                              className="bg-blue-50 text-blue-600 px-3 py-1 rounded-lg text-sm font-medium hover:bg-blue-100 transition-colors flex items-center"
                            >
                              <Edit className="h-4 w-4 mr-1" />
                              Edit
                            </button>
                            <button
                              className="bg-gray-50 text-gray-600 px-3 py-1 rounded-lg text-sm font-medium hover:bg-gray-100 transition-colors flex items-center"
                            >
                              <ArrowUpRight className="h-4 w-4 mr-1" />
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <Package className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                  {userData.membershipPlan === 'basic' ? (
                    <>
                      <h3 className="text-lg font-semibold text-gray-700 mb-2">No Listings Available</h3>
                      <p className="text-gray-500 mb-6">Basic members cannot add listings. Upgrade your plan to add listings.</p>
                      <button
                        onClick={() => handleUpgrade('standard')}
                        className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                      >
                        Upgrade Now
                      </button>
                    </>
                  ) : (
                    <>
                      <h3 className="text-lg font-semibold text-gray-700 mb-2">No Listings Yet</h3>
                      <p className="text-gray-500 mb-6">Add your first property listing to start promoting it.</p>
                      <button
                        onClick={handleAddListing}
                        className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center mx-auto"
                      >
                        <Plus className="h-4 w-4 mr-2" />
                        Add Your First Listing
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Checkout Popup */}
      <CheckoutPopup 
        isOpen={isCheckoutOpen}
        onClose={() => setIsCheckoutOpen(false)}
        onComplete={handleCheckoutComplete}
        currentPlan={userData?.membershipPlan || 'basic'}
        targetPlan={targetPlan}
      />

      {/* Success Popup */}
      <UpgradeSuccessPopup 
        isOpen={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}
        plan={targetPlan === 'premium' ? 'Premium' : 'Standard'}
      />
    </div>
  );
}