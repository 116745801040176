import React from 'react';
import { X, Instagram, MessageSquare } from 'lucide-react';

interface VillaCasablancaPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function VillaCasablancaPopup({ isOpen, onClose }: VillaCasablancaPopupProps) {
  if (!isOpen) return null;

  const handleWhatsApp = () => {
    window.open('https://wa.me/393450490693?text=Hello%20I%20found%20Villa%20Casablanca%20on%20thebaliexpert.com%20and%20am%20interested%20in%20booking.', '_blank');
  };

  const handleInstagram = () => {
    window.open('https://www.instagram.com/casablanca.bali/', '_blank');
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-900">Contact Villa Casablanca</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-6">
          <p className="text-gray-600 mb-6">
            Choose how you'd like to connect with Villa Casablanca:
          </p>
          <div className="space-y-4">
            <button 
              onClick={handleWhatsApp}
              className="w-full bg-green-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center"
            >
              <MessageSquare className="mr-2 h-5 w-5" />
              Chat on WhatsApp
            </button>
            <button 
              onClick={handleInstagram}
              className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-3 rounded-lg font-semibold hover:from-purple-600 hover:to-pink-600 transition-colors flex items-center justify-center"
            >
              <Instagram className="mr-2 h-5 w-5" />
              View on Instagram
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}