import React, { useState, useEffect } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

interface ImageLightboxProps {
  images: string[];
  isOpen: boolean;
  initialIndex: number;
  onClose: () => void;
}

export default function ImageLightbox({ images, isOpen, initialIndex, onClose }: ImageLightboxProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (isOpen) {
      // Prevent body scrolling when lightbox is open
      document.body.style.overflow = 'hidden';
      
      // Preload current and adjacent images
      const imagesToLoad = [
        images[currentIndex],
        images[(currentIndex + 1) % images.length],
        images[(currentIndex - 1 + images.length) % images.length]
      ];
      
      imagesToLoad.forEach(imgSrc => {
        if (!loadedImages.has(imgSrc)) {
          const img = new Image();
          img.src = imgSrc;
          img.onload = () => {
            setLoadedImages(prev => new Set([...prev, imgSrc]));
          };
        }
      });
    } else {
      // Restore body scrolling when lightbox is closed
      document.body.style.overflow = '';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen, currentIndex, images, loadedImages]);

  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  if (!isOpen) return null;

  const handlePrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    } else if (e.key === 'ArrowLeft') {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    } else if (e.key === 'ArrowRight') {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const currentImage = images[currentIndex];
  const thumbnailUrl = `${currentImage}?w=20&q=10`;
  const optimizedUrl = `${currentImage}?w=1920&q=90`;

  return (
    <div 
      className="fixed inset-0 z-50 bg-black bg-opacity-90 flex items-center justify-center"
      onClick={onClose}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {/* Navigation buttons */}
      <button 
        className="absolute left-2 md:left-4 top-1/2 -translate-y-1/2 p-4 rounded-full bg-black/50 backdrop-blur-sm text-white hover:bg-black/70 transition-colors z-50"
        onClick={handlePrevious}
        style={{ touchAction: 'manipulation' }}
        aria-label="Previous image"
      >
        <ChevronLeft className="h-8 w-8" />
      </button>
      
      <button 
        className="absolute right-2 md:right-4 top-1/2 -translate-y-1/2 p-4 rounded-full bg-black/50 backdrop-blur-sm text-white hover:bg-black/70 transition-colors z-50"
        onClick={handleNext}
        style={{ touchAction: 'manipulation' }}
        aria-label="Next image"
      >
        <ChevronRight className="h-8 w-8" />
      </button>
      
      {/* Main image container */}
      <div 
        className="relative w-full h-full max-w-7xl max-h-[90vh] flex items-center justify-center p-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative w-full h-full flex items-center justify-center">
          {/* Low quality placeholder */}
          <div 
            className="absolute inset-0 bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
              filter: !loadedImages.has(currentImage) ? 'blur(20px)' : 'none',
              transition: 'filter 0.3s ease-out'
            }}
          />
          
          {/* Main image */}
          <img
            src={optimizedUrl}
            alt={`Image ${currentIndex + 1}`}
            className={`max-w-full max-h-full object-contain transition-opacity duration-300 ${
              loadedImages.has(currentImage) ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
      </div>
      
      {/* Bottom controls container - fixed at bottom */}
      <div className="fixed bottom-0 left-0 right-0 p-4 bg-black/50 backdrop-blur-sm flex flex-col items-center space-y-4">
        {/* Image counter */}
        <div className="text-white text-lg">
          {currentIndex + 1} / {images.length}
        </div>
        
        {/* Close button - now at the bottom */}
        <button 
          onClick={onClose}
          className="text-white hover:text-gray-300 transition-colors p-4"
          style={{ touchAction: 'manipulation' }}
          aria-label="Close"
        >
          <X className="h-8 w-8" />
        </button>
      </div>
    </div>
  );
}