import React, { useState } from 'react';
import { MapPin, Star, Filter, Search, Coffee, DollarSign, Clock, Phone } from 'lucide-react';

const categories = [
  { name: 'All', count: 24 },
  { name: 'Fine Dining', count: 8 },
  { name: 'Cafes', count: 6 },
  { name: 'Local Cuisine', count: 5 },
  { name: 'International', count: 3 },
  { name: 'Vegan', count: 2 }
];

const featuredRestaurants = [
  {
    title: "Ours",
    location: "Uluwatu",
    image: "https://i.imghippo.com/files/VpA3203zXg.webp",
    description: "Ours Bali is one of the best restaurants in Uluwatu, serving gourmet breakfast, lunch, and dinner in a stylish, tropical-chic setting. Enjoy creative dishes made from high-quality local ingredients, paired with classic cocktails and wines.",
    category: "Fine Dining",
    rating: 4.9,
    reviews: 342,
    hours: "Daily: 8:00 AM - 10:00 PM"
  },
  {
    title: "Lemon & Salt Rooftop Bar",
    location: "Canggu",
    image: "https://i.imghippo.com/files/MGh7677EyE.jpg",
    description: "A hidden gem in the heart of Berawa with an infinity pool overlooking the beach, live music and awesome cocktails",
    category: "Rooftop Bar & Restaurant",
    rating: 4.8,
    reviews: 256,
    hours: "Daily: 11:00 AM - Late"
  },
  {
    title: "Huma Cafe",
    location: "Ubud",
    image: "https://i.imghippo.com/files/tHD9543HGY.jpg",
    description: "Charming cafe in the middle of a rice field, serving organic breakfast, artisanal coffee, and healthy lunch options with stunning views.",
    category: "Cafe",
    rating: 4.8,
    reviews: 478,
    hours: "Daily: 7:00 AM - 6:00 PM"
  }
];

const restaurants = [
  {
    title: "Milk & Madu",
    location: "Canggu",
    image: "https://images.unsplash.com/photo-1521017432531-fbd92d768814?auto=format&fit=crop&q=80",
    description: "Popular cafe known for its excellent breakfast, brunch options, and specialty coffee.",
    category: "Cafe",
    rating: 4.7,
    reviews: 892,
    hours: "Daily: 7:00 AM - 10:00 PM"
  },
  {
    title: "Warung Babi Guling Ibu Oka",
    location: "Ubud",
    image: "https://images.unsplash.com/photo-1516690561799-46d8f74f9abf?auto=format&fit=crop&q=80",
    description: "Famous local warung serving Bali's iconic suckling pig dish in a traditional setting.",
    category: "Local Cuisine",
    rating: 4.6,
    reviews: 1245,
    hours: "Daily: 11:00 AM - 6:00 PM"
  },
  {
    title: "Zest",
    location: "Ubud",
    image: "https://images.unsplash.com/photo-1540914124281-342587941389?auto=format&fit=crop&q=80",
    description: "Plant-based restaurant offering creative vegan dishes in a beautiful jungle setting.",
    category: "Vegan",
    rating: 4.8,
    reviews: 567,
    hours: "Daily: 7:00 AM - 10:00 PM"
  },
  {
    title: "Single Fin",
    location: "Uluwatu",
    image: "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?auto=format&fit=crop&q=80",
    description: "Clifftop bar and restaurant offering spectacular sunset views and international cuisine.",
    category: "International",
    rating: 4.7,
    reviews: 982,
    hours: "Daily: 8:00 AM - Late"
  }
];

export default function Dining() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const heroImageUrl = featuredRestaurants[0].image;
  const thumbnailUrl = `${heroImageUrl}?w=20&q=10`;

  const filteredRestaurants = selectedCategory === 'All'
    ? restaurants
    : restaurants.filter(restaurant => restaurant.category === selectedCategory);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Featured Restaurant */}
      <div className="relative h-[70vh]">
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: !heroImageLoaded ? 'blur(20px)' : 'none',
              transition: 'filter 0.3s ease-out'
            }}
          />
          <img
            src={heroImageUrl}
            alt={featuredRestaurants[0].title}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              heroImageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setHeroImageLoaded(true)}
            loading="eager"
            fetchpriority="high"
            decoding="async"
            sizes="100vw"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
          <div className="flex flex-col justify-end h-full pb-16">
            <div className="max-w-3xl">
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-600 text-white mb-4">
                Featured Restaurant
              </span>
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                {featuredRestaurants[0].title}
              </h1>
              <div className="flex items-center space-x-6 text-gray-300 mb-4">
                <div className="flex items-center">
                  <MapPin className="h-5 w-5 mr-2" />
                  <span>{featuredRestaurants[0].location}</span>
                </div>
                <div className="flex items-center">
                  <Star className="h-5 w-5 mr-2 text-yellow-400" />
                  <span>{featuredRestaurants[0].rating} ({featuredRestaurants[0].reviews} reviews)</span>
                </div>
              </div>
              <p className="text-xl text-gray-200 mb-8">
                {featuredRestaurants[0].description}
              </p>
              <div className="flex items-center text-gray-300">
                <Clock className="h-5 w-5 mr-2" />
                <span>{featuredRestaurants[0].hours}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Featured Restaurants Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Featured Restaurants & Cafés</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {featuredRestaurants.map((restaurant, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300">
                <div className="relative h-48">
                  <img
                    src={restaurant.image}
                    alt={restaurant.title}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                    {restaurant.category}
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <MapPin className="h-4 w-4 mr-1" />
                    <span>{restaurant.location}</span>
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{restaurant.title}</h3>
                  <p className="text-gray-600 mb-4">{restaurant.description}</p>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center text-gray-500">
                      <Clock className="h-4 w-4 mr-1" />
                      <span className="text-sm">{restaurant.hours}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-yellow-500">
                      <Star className="h-4 w-4 mr-1" />
                      <span>{restaurant.rating}</span>
                      <span className="text-gray-500 text-sm ml-1">({restaurant.reviews})</span>
                    </div>
                    <button className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
                      Reserve Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* All Restaurants Section */}
        <div>
          <h2 className="text-3xl font-bold text-gray-900 mb-8">All Restaurants & Cafés</h2>
          {/* Filters */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 space-y-4 md:space-y-0">
            <div className="flex items-center space-x-2">
              <Filter className="h-5 w-5 text-gray-500" />
              <h3 className="text-xl font-semibold text-gray-900">Filter by Category:</h3>
            </div>
            <div className="flex flex-wrap gap-2">
              {categories.map((category) => (
                <button
                  key={category.name}
                  onClick={() => setSelectedCategory(category.name)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    selectedCategory === category.name
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {category.name} ({category.count})
                </button>
              ))}
            </div>
          </div>

          {/* Restaurants Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredRestaurants.map((restaurant, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300">
                <div className="relative h-48">
                  <img
                    src={restaurant.image}
                    alt={restaurant.title}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                    {restaurant.category}
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <MapPin className="h-4 w-4 mr-1" />
                    <span>{restaurant.location}</span>
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{restaurant.title}</h3>
                  <p className="text-gray-600 mb-4">{restaurant.description}</p>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center text-gray-500">
                      <Clock className="h-4 w-4 mr-1" />
                      <span className="text-sm">{restaurant.hours}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-yellow-500">
                      <Star className="h-4 w-4 mr-1" />
                      <span>{restaurant.rating}</span>
                      <span className="text-gray-500 text-sm ml-1">({restaurant.reviews})</span>
                    </div>
                    <button className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
                      Reserve Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}