import React, { useState } from 'react';
import { MapPin, Clock, Users, DollarSign, Star, Filter, Search, ArrowRight, Heart, Building2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import GuidePopup from '../components/GuidePopup';
import TourBookingPopup from '../components/TourBookingPopup';
import TourBookingGeneralPopup from '../components/TourBookingGeneralPopup';

const categories = [
  { name: 'All Tours', count: 4 },
  { name: 'Adventure', count: 4 },
  { name: 'Nature', count: 4 }
];

const featuredTour = {
  title: "Mt. Agung Premium Volcano Hiking Experience",
  description: "Experience a premium guided trek to the summit of Bali's highest and most sacred volcano. This all-inclusive package ensures your comfort and safety with high-quality equipment and experienced guides.",
  image: "https://i.imghippo.com/files/RAZU8832.webp",
  duration: "2 Days",
  groupSize: "1-4 people",
  price: "IDR 3M",
  rating: 5.0,
  reviews: 89,
  company: "Turbo Trips",
  includes: [
    "Professional guide",
    "Complete hiking gear",
    "Camping equipment",
    "All meals included",
    "Hotel pickup & drop-off",
    "Emergency equipment"
  ],
  instagramLink: "https://www.instagram.com/turbo_trips/",
  whatsappLink: "https://wa.me/6281617804338?text=Hello%20I%20found%20your%20company%20on%20thebaliexpert.com.%20I%20am%20interested%20in%20booking%20the%20Mt%20Agung%20tour."
};

const tours = [
  {
    id: 'mt-agung-hiking',
    title: "Mt. Agung Premium Volcano Hiking Experience",
    description: "Experience a premium guided trek to the summit of Bali's highest and most sacred volcano with high-quality equipment and experienced guides.",
    image: "https://i.imghippo.com/files/RAZU8832.webp",
    duration: "2 Days",
    price: "IDR 3M",
    categories: ["Adventure", "Nature"],
    rating: 5.0,
    reviews: 89,
    company: "Turbo Trips",
    itinerary: [
      "1-hour prep session",
      "Premium hiking gear provided",
      "Professional guide",
      "Sunrise summit trek",
      "All meals included"
    ],
    instagramLink: "https://www.instagram.com/turbo_trips/",
    whatsappLink: "https://wa.me/6281617804338?text=Hello%20I%20found%20your%20company%20on%20thebaliexpert.com.%20I%20am%20interested%20in%20booking%20the%20Mt%20Agung%20tour."
  },
  {
    id: 'nusa-penida-snorkeling',
    title: "Nusa Penida Snorkeling Trip (1 Day)",
    description: "Experience the stunning underwater world of Nusa Penida with visits to Manta Bay, Crystal Bay, Gamat Bay, and Wall Point. Swim with majestic manta rays and discover vibrant coral reefs.",
    image: "https://i.imghippo.com/files/jjp6506gcc.jpg",
    duration: "8 Hours",
    price: "From $30",
    categories: ["Adventure", "Nature"],
    rating: 4.9,
    reviews: 245,
    company: "Bali Nusa Penida Tour",
    itinerary: [
      "06:30 AM - Meeting point Sanur Port",
      "07:00 AM - Boat Departure to Nusa Penida",
      "09:00 AM - Boat arrives in Nusa Penida",
      "09:30 AM - Start Snorkeling",
      "09:45 AM - Manta Bay",
      "10:30 AM - Crystal Bay",
      "11:20 AM - Gamat Bay",
      "12:00 PM - Wall Point",
      "12:30 PM - Finish Snorkeling",
      "01:30 PM - Boat Departure to Sanur",
      "02:10 PM - Boat arrives in Sanur"
    ],
    includes: [
      "Fast boat (round trip)",
      "Boat snorkeling",
      "Snorkeling equipment",
      "Mineral water",
      "Towel",
      "Toilet access",
      "Guide snorkeling",
      "Free photo documentation"
    ]
  },
  {
    id: 'waterfall-tour',
    title: "Waterfall Tour",
    description: "Visit Bali's most beautiful waterfalls including Tukad Cepung, Tibumana, Kanto Lampo, and Suwat Waterfall.",
    image: "https://i.imghippo.com/files/fOXl5804TGE.jpg",
    duration: "7 Hours",
    price: "From IDR 240k",
    categories: ["Adventure", "Nature"],
    rating: 4.8,
    reviews: 167,
    company: "Bali Nusa Penida Tour",
    itinerary: [
      "Hotel pickup",
      "Visit Tukad Cepung Waterfall",
      "Visit Tibumana Waterfall",
      "Visit Kanto Lampo Waterfall",
      "Visit Suwat Waterfall",
      "Return to hotel"
    ]
  },
  {
    id: 'mount-batur',
    title: "Mount Batur Sunrise Trek",
    description: "Experience a magical sunrise from the summit of an active volcano.",
    image: "https://i.imghippo.com/files/BWB7645mQs.jpg",
    duration: "10 Hours",
    price: "From $65",
    categories: ["Adventure", "Nature"],
    rating: 4.9,
    reviews: 156,
    company: "Bali Volcano Trekkers"
  }
];

export default function Tours() {
  const [selectedCategory, setSelectedCategory] = useState('All Tours');
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const [isGuidePopupOpen, setIsGuidePopupOpen] = useState(false);
  const [isBookingPopupOpen, setIsBookingPopupOpen] = useState(false);
  const [isGeneralBookingPopupOpen, setIsGeneralBookingPopupOpen] = useState(false);
  const [selectedTourName, setSelectedTourName] = useState('');
  const navigate = useNavigate();
  const heroImageUrl = tours[0].image;
  const thumbnailUrl = `${heroImageUrl}?w=20&q=10`;

  const handleTourClick = (tourId: string) => {
    window.scrollTo(0, 0);
    navigate(`/tours/${tourId}`);
  };

  const handleBookTour = (e: React.MouseEvent, tourId: string, tourName: string) => {
    e.stopPropagation();
    
    if (tourId === 'mt-agung-hiking') {
      setIsBookingPopupOpen(true);
    } else {
      setSelectedTourName(tourName);
      setIsGeneralBookingPopupOpen(true);
    }
  };

  const filteredTours = selectedCategory === 'All Tours'
    ? tours
    : tours.filter(tour => tour.categories.includes(selectedCategory));

  const renderStars = () => {
    return (
      <div className="flex">
        {[...Array(5)].map((_, index) => (
          <Star key={index} className="h-4 w-4 text-yellow-400 fill-yellow-400" />
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative w-full">
        <div className="relative w-full pb-[100%] md:pb-[56.25%]">
          <div className="absolute inset-0 z-0">
            <div 
              className="absolute inset-0"
              style={{
                backgroundImage: `url(${thumbnailUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: !heroImageLoaded ? 'blur(20px)' : 'none',
                transition: 'filter 0.3s ease-out'
              }}
            />
            <img
              src={heroImageUrl}
              alt={featuredTour.title}
              className={`w-full h-full object-cover transition-opacity duration-300 ${
                heroImageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setHeroImageLoaded(true)}
              loading="eager"
              fetchpriority="high"
              decoding="async"
              sizes="100vw"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent z-10"></div>
          </div>
          
          <div className="absolute inset-0 flex items-center md:items-end z-20">
            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-8 md:pb-16">
              <div className="max-w-3xl">
                <span className="inline-flex items-center px-2 py-1 md:px-3 md:py-1 rounded-full text-xs md:text-sm font-medium bg-blue-600 text-white mb-2 md:mb-4">
                  Featured Tour
                </span>
                <h1 className="text-2xl md:text-5xl font-bold text-white mb-1 md:mb-4">
                  {featuredTour.title}
                </h1>
                <div className="text-sm md:text-base text-blue-400 font-medium mb-2 md:mb-4">
                  by: <button 
                    onClick={() => setIsGuidePopupOpen(true)}
                    className="text-blue-400 hover:text-blue-300 hover:underline transition-colors"
                  >
                    {featuredTour.company}
                  </button>
                </div>
                <div className="flex flex-wrap items-center gap-2 md:gap-4 text-gray-300 mb-2 md:mb-6">
                  <div className="flex items-center">
                    <Clock className="h-3 w-3 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-xs md:text-base">{featuredTour.duration}</span>
                  </div>
                  <div className="flex items-center">
                    <Users className="h-3 w-3 md:h-5 md:w-5 mr-1 md:mr-2" />
                    <span className="text-xs md:text-base">{featuredTour.groupSize}</span>
                  </div>
                  <div className="flex items-center text-xs md:text-base">
                    {renderStars()}
                    <span className="ml-1 md:ml-2">{featuredTour.rating} ({featuredTour.reviews} reviews)</span>
                  </div>
                </div>
                <p className="text-sm md:text-xl text-gray-200 mb-3 md:mb-8 line-clamp-2 md:line-clamp-none">
                  {featuredTour.description}
                </p>
                <div className="mb-2 md:mb-6">
                  <span className="text-xl md:text-3xl font-bold text-white">{featuredTour.price}</span>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
                  {featuredTour.includes.slice(0, 4).map((item, index) => (
                    <div key={index} className="flex items-center text-gray-300">
                      <div className="h-1.5 w-1.5 md:h-2 md:w-2 bg-blue-600 rounded-full mr-1 md:mr-2"></div>
                      <span className="text-xs md:text-base truncate">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filters Section */}
      <div className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-wrap gap-4">
            {categories.map((category) => (
              <button
                key={category.name}
                onClick={() => setSelectedCategory(category.name)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedCategory === category.name
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {category.name} ({category.count})
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Tours Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredTours.map((tour) => (
            <div
              key={tour.id}
              onClick={() => handleTourClick(tour.id)}
              className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300 cursor-pointer h-full"
            >
              <div className="relative h-48">
                <img
                  src={tour.image}
                  alt={tour.title}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                  loading="lazy"
                  decoding="async"
                />
                <button className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
                  <Heart className="h-5 w-5 text-gray-600" />
                </button>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">{tour.title}</h3>
                    <div className="flex items-center text-gray-600">
                      <Clock className="h-4 w-4 mr-1" />
                      <span>{tour.duration}</span>
                    </div>
                  </div>
                  <span className="text-2xl font-bold text-blue-600">{tour.price}</span>
                </div>
                <p className="text-gray-600 mb-4">{tour.description}</p>
                {tour.itinerary && (
                  <div className="mb-4">
                    <h4 className="font-semibold text-gray-900 mb-2">Itinerary:</h4>
                    <div className="text-sm text-gray-600 space-y-1">
                      {tour.itinerary.slice(0, 3).map((item, idx) => (
                        <p key={idx}>{item}</p>
                      ))}
                      {tour.itinerary.length > 3 && (
                        <p className="text-blue-600">+ {tour.itinerary.length - 3} more stops</p>
                      )}
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center text-gray-500">
                    <Clock className="h-4 w-4 mr-1" />
                    <span>{tour.duration}</span>
                  </div>
                  <div className="flex items-center">
                    {renderStars()}
                    <span className="ml-2">{tour.rating} ({tour.reviews})</span>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTourClick(tour.id);
                    }}
                    className="bg-white border border-blue-600 text-blue-600 px-4 py-2 rounded-lg text-sm font-medium hover:bg-blue-50 transition-colors"
                  >
                    Details
                  </button>
                  <button 
                    onClick={(e) => handleBookTour(e, tour.id, tour.title)}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Guide Popup */}
      <GuidePopup 
        isOpen={isGuidePopupOpen}
        onClose={() => setIsGuidePopupOpen(false)}
      />

      {/* Mt. Agung Booking Popup */}
      <TourBookingPopup
        isOpen={isBookingPopupOpen}
        onClose={() => setIsBookingPopupOpen(false)}
        tourName="Mt. Agung Premium Volcano Hiking Experience"
        instagramLink={tours[0].instagramLink}
        whatsappLink={tours[0].whatsappLink}
      />

      {/* General Booking Popup */}
      <TourBookingGeneralPopup
        isOpen={isGeneralBookingPopupOpen}
        onClose={() => setIsGeneralBookingPopupOpen(false)}
        tourName={selectedTourName}
      />
    </div>
  );
}